import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { PeriodicityRequestDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '../../RootState'
import { IPeriodicityState } from './states'


export const getters: GetterTree<IPeriodicityState, RootState> = {
  periodicitiesList: (state): PeriodicityRequestDto[] => state.periodicitiesList,
  periodicitiesListArchived: (state): PeriodicityRequestDto[] => state.periodicitiesListArchived,
  periodicity     : (state): PeriodicityRequestDto => state.periodicity,

  // COMMON
  isLoadingPeriodicitiesList   : (state): boolean => state.isLoadingPeriodicitiesList,
  isLoadingPeriodicitiesListArchived   : (state): boolean => state.isLoadingPeriodicitiesListArchived,
  isLoadingPeriodicity   : (state): boolean => state.isLoadingPeriodicity,
  isCreatingPeriodicity  : (state): boolean => state.isCreatingPeriodicity,
  isUpdatingPeriodicity  : (state): boolean => state.isUpdatingPeriodicity,
  isDeletingPeriodicity  : (state): boolean => state.isDeletingPeriodicity,
  isArchivingPeriodicity  : (state): boolean => state.isArchivingPeriodicity,
  requestStatePeriodicity: (state): IRequestState => state.requestStatePeriodicity,
  isOpenSidebarPeriodicity : (state): boolean => state.isOpenSidebarPeriodicity,
}



