import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { LeaseRequestDto } from '@/../Api'
import { MutationTree } from "vuex";
import { ILeaseState } from "./states";

export enum LeaseMutations {
    SET_LEASES_LIST = "SET_LEASES_LIST",
    SET_LEASES_LIST_ARCHIVED = "SET_LEASES_LIST_ARCHIVED",
    SET_LEASE = "SET_LEASE",

    // COMMON
    SET_IS_LOADING_LEASE_LIST = "SET_IS_LOADING_LEASE_LIST",
    SET_IS_LOADING_LEASE_LIST_ARCHIVED = "SET_IS_LOADING_LEASE_LIST_ARCHIVED",
    SET_IS_LOADING_LEASE = "SET_IS_LOADING_LEASE",
    SET_IS_CREATING_LEASE = "SET_IS_CREATING_LEASE",
    SET_IS_UPDATING_LEASE = "SET_IS_UPDATING_LEASE",
    SET_IS_DELETING_LEASE = "SET_IS_DELETING_LEASE",
    SET_IS_ARCHIVING_LEASE = "SET_IS_DELETING_LEASE",
    SET_REQUEST_STATE_LEASE = "SET_REQUEST_STATE_LEASE",
    SET_IS_OPEN_SIDEBAR_LEASE = "SET_IS_OPEN_SIDEBAR_LEASE"
}

export const mutations: MutationTree<ILeaseState> = {
    [LeaseMutations.SET_LEASES_LIST_ARCHIVED]: (state, payload: LeaseRequestDto[]) => {
        state.leasesListArchived = payload;
    }, 
    [LeaseMutations.SET_LEASES_LIST]: (state, payload: LeaseRequestDto[]) => {
        state.leasesList = payload;
    },
    [LeaseMutations.SET_LEASE]: (state, payload: LeaseRequestDto) => {
        state.lease = payload;
    },


    //COMMON
    [LeaseMutations.SET_IS_LOADING_LEASE_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingLeasesListArchived = payload;
    },
    [LeaseMutations.SET_IS_LOADING_LEASE_LIST]: (state, payload: boolean) => {
        state.isLoadingLeasesList = payload;
    },
    [LeaseMutations.SET_IS_LOADING_LEASE]: (state, payload: boolean) => {
        state.isLoadingLease = payload;
    },
    [LeaseMutations.SET_IS_CREATING_LEASE]: (state, payload: boolean) => {
        state.isCreatingLease = payload;
    },
    [LeaseMutations.SET_IS_UPDATING_LEASE]: (state, payload: boolean) => {
        state.isUpdatingLease = payload;
    },
    [LeaseMutations.SET_IS_DELETING_LEASE]: (state, payload: boolean) => {
        state.isDeletingLease = payload;
    },
    [LeaseMutations.SET_IS_ARCHIVING_LEASE]: (state, payload: boolean) => {
        state.isArchivingLease = payload;
    },
    [LeaseMutations.SET_REQUEST_STATE_LEASE]: (state, payload: IRequestState) => {
        state.requestStateLease = payload;
    },
    [LeaseMutations.SET_IS_OPEN_SIDEBAR_LEASE]: (state, payload: boolean) => {
        state.isOpenSidebarLease = payload;
    },
}