import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { AffairRequestDto } from '@/../Api'
import { MutationTree } from "vuex";
import { IAffairState } from "./states";

export enum AffairMutations {
    SET_AFFAIRS_LIST = "SET_AFFAIRS_LIST",
    SET_AFFAIRS_LIST_ARCHIVED = "SET_AFFAIRS_LIST_ARCHIVED",
    SET_AFFAIR = "SET_AFFAIR",

    // COMMON
    SET_IS_LOADING_AFFAIR_LIST = "SET_IS_LOADING_AFFAIR_LIST",
    SET_IS_LOADING_AFFAIR_LIST_ARCHIVED = "SET_IS_LOADING_AFFAIR_LIST_ARCHIVED",
    SET_IS_LOADING_AFFAIR = "SET_IS_LOADING_AFFAIR",
    SET_IS_CREATING_AFFAIR = "SET_IS_CREATING_AFFAIR",
    SET_IS_UPDATING_AFFAIR = "SET_IS_UPDATING_AFFAIR",
    SET_IS_DELETING_AFFAIR = "SET_IS_DELETING_AFFAIR",
    SET_IS_ARCHIVING_AFFAIR = "SET_IS_DELETING_AFFAIR",
    SET_REQUEST_STATE_AFFAIR = "SET_REQUEST_STATE_AFFAIR",
    SET_IS_OPEN_SIDEBAR_AFFAIR = "SET_IS_OPEN_SIDEBAR_AFFAIR"
}

export const mutations: MutationTree<IAffairState> = {
    [AffairMutations.SET_AFFAIRS_LIST_ARCHIVED]: (state, payload: AffairRequestDto[]) => {
        state.affairsListArchived = payload;
    }, 
    [AffairMutations.SET_AFFAIRS_LIST]: (state, payload: AffairRequestDto[]) => {
        state.affairsList = payload;
    },
    [AffairMutations.SET_AFFAIR]: (state, payload: AffairRequestDto) => {
        state.affair = payload;
    },


    //COMMON
    [AffairMutations.SET_IS_LOADING_AFFAIR_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingAffairsListArchived = payload;
    },
    [AffairMutations.SET_IS_LOADING_AFFAIR_LIST]: (state, payload: boolean) => {
        state.isLoadingAffairsList = payload;
    },
    [AffairMutations.SET_IS_LOADING_AFFAIR]: (state, payload: boolean) => {
        state.isLoadingAffair = payload;
    },
    [AffairMutations.SET_IS_CREATING_AFFAIR]: (state, payload: boolean) => {
        state.isCreatingAffair = payload;
    },
    [AffairMutations.SET_IS_UPDATING_AFFAIR]: (state, payload: boolean) => {
        state.isUpdatingAffair = payload;
    },
    [AffairMutations.SET_IS_DELETING_AFFAIR]: (state, payload: boolean) => {
        state.isDeletingAffair = payload;
    },
    [AffairMutations.SET_IS_ARCHIVING_AFFAIR]: (state, payload: boolean) => {
        state.isArchivingAffair = payload;
    },
    [AffairMutations.SET_REQUEST_STATE_AFFAIR]: (state, payload: IRequestState) => {
        state.requestStateAffair = payload;
    },
    [AffairMutations.SET_IS_OPEN_SIDEBAR_AFFAIR]: (state, payload: boolean) => {
        state.isOpenSidebarAffair = payload;
    },
}