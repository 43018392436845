import axios from 'axios';

// initial state
const state = () => ({
  userInfo: null,
  userToken: null,
  userIsLoggedIn:false,
  planningViewSelected: null,
  isToastingNotification:false,
  isGlobalLoading:false
})

// getters
const getters = {
  userInfo: state => state.userInfo,
  userToken: state => state.userToken,
  planningViewSelected: (state) => state.planningViewSelected,
  isToastingNotification: (state) => state.isToastingNotification,
  isGlobalLoading: (state) => state.isGlobalLoading,
};

// actions
const actions = {
  async login({commit, dispatch, rootState}, {payload, that}) {
    var config = {
      method: "post",
      url: process.env.VUE_APP_API_ORISIS + "Account/Login",
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };
    await axios(config)
      .then(function (response) {
        commit('SET_USER_INFO', response.data)
        if(response.data?.token){
          // if(response.data.isFirstConnection){
          //   console.log("SET_IS FIRST CONNECTION >", response.data.isFirstConnection)
          //   commit('SET_USER_TOKEN', response.data.token)
          //   commit('SET_USER_IS_LOGGED_IN', true)
          //   that.$router.push("/change-password/firstLogin")
          // }else{
            commit('SET_USER_TOKEN', response.data.token)
            commit('SET_USER_IS_LOGGED_IN', true)
            dispatch('setWorkspaceSelected',{ workspaceSelectedId:response.data.workspaceIds[0], userRoles:response.data.roles});
            that.$router.push("/");
          // }
        }else{
          commit('SET_USER_TOKEN', "")
          commit('SET_USER_IS_LOGGED_IN', false)
        }
        
      })
  },
  async forgotPassword({commit, dispatch, rootState}, {payload, that}) {
    var config = {
      method: "post",
      url: process.env.VUE_APP_API_ORISIS + "Account/ForgotPassword",
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };
    await axios(config)
      .then(function (response) {
        that.$router.push({name:'login'});
        that.$nextTick(() => {
          that.$bvToast.toast("Veuillez vérifier votre boite e-mail pour continuer la procédure.", {
            title: `Ré-initialisation de mot de passe initiée`,
            variant: "success",
            solid: true,
          })
        })
      })
  },
  async resetPassword({commit, dispatch, rootState}, {payload, that}) {
    var config = {
      method: "post",
      url: process.env.VUE_APP_API_ORISIS + "Account/ResetPassword",
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };
    await axios(config)
      .then((response) => {
        if(!response.data){
          that.$bvToast.toast("Le délai de ré-initialisation a expiré, veuillez renouveler votre demande.", {
            title: `Réinitialisation impossible`,
            variant: "danger",
            solid: true,
          })
        }else{
          commit('SET_USER_INFO', response.data)
          if(response.data?.token){
            commit('SET_USER_TOKEN', response.data.token)
            commit('SET_USER_IS_LOGGED_IN', true)
            dispatch('setWorkspaceSelected',{ workspaceSelectedId:response.data.workspaceIds[0], userRoles:response.data.roles});
            that.$router.push("/");
            that.$nextTick(() => {
              that.$bvToast.toast("Votre nouveau mot de passe a été correctement enregistré.", {
                title: `Authentification réussie`,
                variant: "success",
                solid: true,
              })
            })
          }else{
            commit('SET_USER_TOKEN', "")
            commit('SET_USER_IS_LOGGED_IN', false)
          }
        }

      })
  },
  async changePassword({commit, dispatch, rootState}, {payload, that}) {
    var config = {
      method: "post",
      url: process.env.VUE_APP_API_ORISIS + "Account/ChangePassword",
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };
    await axios(config)
      .then((response) => {
        if(!response.data){
          that.$bvToast.toast("Veuillez ré-essayer avec un nouveau mot de passe.", {
            title: `Changement de mot passe impossible`,
            variant: "danger",
            solid: true,
          })
        }else{
          commit('SET_USER_INFO', response.data)
          if(response.data?.token){
            commit('SET_USER_TOKEN', response.data.token)
            commit('SET_USER_IS_LOGGED_IN', true)
            dispatch('setWorkspaceSelected',{ workspaceSelectedId:response.data.workspaceIds[0], userRoles:response.data.roles});
            that.$router.push("/");
            that.$nextTick(() => {
              that.$bvToast.toast("Votre nouveau mot de passe a été correctement enregistré.", {
                title: `Authentification réussie`,
                variant: "success",
                solid: true,
              })
            })
          }else{
            commit('SET_USER_TOKEN', "")
            commit('SET_USER_IS_LOGGED_IN', false)
          }
        }

      })
  },

  logOut (state, payload) {
    state.userInfo = null
    state.userToken = null
    state.userIsLoggedIn = false
    // localStorage.setItem('userInfo', null)
    // localStorage.setItem('userToken', null)
    // localStorage.setItem('userIsLoggedIn', false)
    localStorage.clear();
  },
  // logout() {
  //   localStorage.clear();
  // },

  getPlanningViewsByWorkspaceId({
    commit,
    dispatch,
    rootState
  }) {
    axios.get(process.env.VUE_APP_API_ORISIS + "Plannings/PlanningView/GetPlanningViewByWorkspace?workspaceId=", {
      headers: {
        Authorization: `Bearer ${rootState.login.userInfo.token}`
      },
      params: {
        workspaceId: rootState.workspace.workspaceSelected
      }
    }).then(response => {
      commit('SET_PLANNING_VIEW_SELECTED', response.data.find(elem => elem.isDefault === true).id ? response.data.find(elem => elem.isDefault === true).id : response.data[0])
      dispatch('getPlanning', response.data.find(elem => elem.isDefault === true).id);

    }).catch(error => {
      console.log('error => ', error)
    })
  },

  setPlanningViewSelected({commit, dispatch}, planningViewId) {
    commit('SET_PLANNING_VIEW_SELECTED', planningViewId)
    dispatch('getPlanning', planningViewId);
  },

  async initializeStore({commit, dispatch, rootState}){
    await dispatch('setIsGlobalLoading', true)
    console.log("Store Initialize")
    await dispatch('fetchReferencielTVAsList');
    await dispatch('fetchPropertiesList');
    await dispatch('fetchCodeTvaList');
    await dispatch('fetchLeaseTypeList');
    await dispatch('fetchLeaseList');
    await dispatch('fetchPeriodicitiesList');
    await dispatch('fetchPropertyTypeList');
    await dispatch('fetchPublicHolidayList');
    await dispatch('fetchAffairsList');
    await dispatch('fetchCompaniesList');
    await dispatch('fetchCompaniesListArchived');
    await dispatch('fetchCompaniesTypesList');
    await dispatch('fetchContactsList');
    await dispatch('fetchContactsListArchived');
    await dispatch('fetchCollaboratorsList');
    await dispatch('fetchCollaboratorsListArchived');
    await dispatch('fetchUsersList');
    await dispatch('fetchUsersListArchived');
    await dispatch('fetchPlanningLinesCollaboratorList')
    await dispatch('fetchPlanningLinesAffairList')
    await dispatch('fetchPlanningLinesFreeLineList')
    await dispatch('fetchJobsList')
    await dispatch('fetchContractsList')
    await dispatch('setIsToastingNotification', false)
    await dispatch('fetchSmsList')
    await dispatch('setIsGlobalLoading', false)
    console.log("Store Loaded")
  },
  
  async setIsToastingNotification({ commit},statut){
    await commit('SET_IS_TOASTING_NOTIFICATION', statut)
  },
  async setIsGlobalLoading({ commit},statut){
    await commit('SET_IS_GLOBAL_LOADING', statut)
  },
}

// mutations
const mutations = {
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo;
    localStorage.setItem('userInfo', JSON.stringify(userInfo))
  },
  SET_USER_TOKEN: (state, userToken) => {
    state.userToken = userToken;
    localStorage.setItem('userToken', userToken)
  },
  SET_USER_IS_LOGGED_IN: (state, userIsLoggedIn) => {
    state.userIsLoggedIn = userIsLoggedIn;
    localStorage.setItem('userIsLoggedIn', userIsLoggedIn)
  },
  SET_PLANNING_VIEW_SELECTED(state, planningViewSelected) {
    state.planningViewSelected = planningViewSelected
  },
  SET_IS_TOASTING_NOTIFICATION(state, payload){
    state.isToastingNotification = payload
  },
  SET_IS_GLOBAL_LOADING(state, payload){
    state.isGlobalLoading = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
 