import { ReferencielTVARequestDto } from 'Api'
import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '../../RootState'
import { ReferencielTVAMutations } from './mutations'
import { IReferencielTVAState } from './states'

export const actions: ActionTree<IReferencielTVAState, RootState> = {

 /**
  * It fetches the list of referenciel TVA from the API.
  * @param  - - **commit**: The commit function is used to send mutations to the Vuex store.
  */
  async fetchReferencielTVAsList ({ commit, rootGetters }): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(ReferencielTVAMutations.SET_IS_LOADING_REFERENCIEL_TVA, true)
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            'Billing/ReferencielTVA/GetReferencielTVAs',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
          }
        )
        .then(res => {
          commit(ReferencielTVAMutations.SET_REFERENCIEL_TVAS_LIST, res.data.sort((a, b) => a.value - b.value))
          commit(ReferencielTVAMutations.SET_REQUEST_STATE_REFERENCIEL_TVA, res)
          commit(ReferencielTVAMutations.SET_IS_LOADING_REFERENCIEL_TVA, false)
          resolve(res.data)
        })
        .catch(err => {
          console.error(err)
          commit(ReferencielTVAMutations.SET_REQUEST_STATE_REFERENCIEL_TVA, err)
          commit(ReferencielTVAMutations.SET_IS_LOADING_REFERENCIEL_TVA, false)
          reject(err)
        })
      })
  },

  /**
   * It fetches a list of archived ReferencielTVA objects from the server and commits the result to the
   * store.
   * @param  - Promise<void> =&gt; the return type of the function
   * @returns The data is being returned as an array of objects.
   */
  async fetchReferencielTVAsListArchived ({ commit, rootGetters }): Promise<void> {
    return await new Promise<void>((resolve, reject) => {
      commit(ReferencielTVAMutations.SET_IS_LOADING_REFERENCIEL_TVAS_LIST_ARCHIVED, true) 
      axios
        .get(
          process.env.VUE_APP_API_ORISIS + 
          '/Billing/ReferencielTVA/GetReferencielTVAsArchived',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
          }
        )
        .then(res => {
          commit(ReferencielTVAMutations.SET_REFERENCIEL_TVAS_LIST_ARCHIVED, res.data)
          commit(ReferencielTVAMutations.SET_REQUEST_STATE_REFERENCIEL_TVA, res)
          commit(ReferencielTVAMutations.SET_IS_LOADING_REFERENCIEL_TVAS_LIST_ARCHIVED, false)
          resolve(res.data)
        })
        .catch(err => {
          console.error(err)
          commit(ReferencielTVAMutations.SET_REQUEST_STATE_REFERENCIEL_TVA, err)
          commit(ReferencielTVAMutations.SET_IS_LOADING_REFERENCIEL_TVAS_LIST, false)
        })
    })
  },


 /**
  * It fetches the ReferencielTVA data from the API.
  * @param  - ReferencielTVAId: The ReferencielTVAId of the ReferencielTVA you want to fetch.
  * @param {string} referencielTVAId - string
  */
  async fetchReferencielTVA (
    { commit, rootGetters },
    referencielTVAId: string
  ): Promise<void> {
    commit(ReferencielTVAMutations.SET_IS_LOADING_REFERENCIEL_TVA, true)
    axios
      .get(
        process.env.VUE_APP_API_ORISIS +
          'Billing/ReferencielTVA/GetReferencielTVAById',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          params: {
            referencielTVAId: referencielTVAId
          }
        }
      )
      .then(res => {
        commit(ReferencielTVAMutations.SET_REFERENCIEL_TVA, res.data)
        commit(ReferencielTVAMutations.SET_REQUEST_STATE_REFERENCIEL_TVA, res)
        commit(ReferencielTVAMutations.SET_IS_LOADING_REFERENCIEL_TVA, false)
      })
      .catch(err => {
        console.error(err)
        commit(ReferencielTVAMutations.SET_REQUEST_STATE_REFERENCIEL_TVA, err)
        commit(ReferencielTVAMutations.SET_IS_LOADING_REFERENCIEL_TVA, false)
      })
  },

  
  /**
   * Create a new ReferencielTVA
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {ReferencielTVARequestDto} referencielTVA - ReferencielTVARequestDto
   */
  async createReferencielTVA (
    { commit, rootGetters },
    referencielTVA: ReferencielTVARequestDto
  ): Promise<void> {
    commit(ReferencielTVAMutations.SET_IS_CREATING_REFERENCIEL_TVA, true)
    axios
      .post(
        process.env.VUE_APP_API_ORISIS +
          'Billing/ReferencielTVA/CreateReferencielTVA',
        referencielTVA,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
       rootGetters.referencielTVAsList.unshift(res.data.data);
        commit(
          ReferencielTVAMutations.SET_REFERENCIEL_TVAS_LIST,
          rootGetters.ReferencielTVAsList
        )
        commit(ReferencielTVAMutations.SET_REQUEST_STATE_REFERENCIEL_TVA, res)
        commit(ReferencielTVAMutations.SET_IS_CREATING_REFERENCIEL_TVA, false)
      })
      .catch(err => {
        console.error(err)
        commit(ReferencielTVAMutations.SET_REQUEST_STATE_REFERENCIEL_TVA, err)
        commit(ReferencielTVAMutations.SET_IS_CREATING_REFERENCIEL_TVA, false)
      })
  },

  /**
   * I'm trying to update a list of objects in my store, but I can't find a way to do it.
   * I'm using Vuex and Axios.
   * I'm trying to update a list of objects in my store, but I can't find a way to do it.
   * I'm using Vuex and Axios.
   * I'm trying to update a list of objects in my store, but I can't find a way to do it.
   * I'm using Vuex and Axios.
   * I'm trying to update a list of objects in my store, but I can't find a way to do it.
   * I'm using Vuex and Axios.
   * I'm trying to update a list of objects in my store, but I can't find a way to do it.
   * I'm using Vuex and Axios.
   * I'm trying to update a list of objects in my store, but I can't find
   * @param  - ReferencielTVARequestDto
   * @param {ReferencielTVARequestDto} referencielTVA - ReferencielTVARequestDto
   */
  async updateReferencielTVA (
    { commit, rootGetters },
    referencielTVA: ReferencielTVARequestDto
  ): Promise<void> {
    commit(ReferencielTVAMutations.SET_IS_UPDATING_REFERENCIEL_TVA, true)
    axios
      .put(
        process.env.VUE_APP_API_ORISIS +
          'Billing/ReferencielTVA/UpdateReferencielTVA',
        referencielTVA,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.ReferencielTVAsList.splice(
          rootGetters.ReferencielTVAsList.findIndex(
            elem => elem.id == referencielTVA.id
          ),
          1,
          referencielTVA
        )
        commit(
          ReferencielTVAMutations.SET_REFERENCIEL_TVAS_LIST,
          rootGetters.ReferencielTVAsList
        )
        commit(ReferencielTVAMutations.SET_REQUEST_STATE_REFERENCIEL_TVA, res)
        commit(ReferencielTVAMutations.SET_IS_UPDATING_REFERENCIEL_TVA, false)
      })
      .catch(err => {
        console.error(err)
        commit(ReferencielTVAMutations.SET_REQUEST_STATE_REFERENCIEL_TVA, err)
        commit(ReferencielTVAMutations.SET_IS_UPDATING_REFERENCIEL_TVA, false)
      })
  },

  /**
   * Delete a ReferencielTVA from the database
   * @param  - - `commit`: the commit function of the Vuex store.
   * @param {string} referencielTVAId - The id of the ReferencielTVA to delete.
   */
  async deleteReferencielTVA (
    { commit, rootGetters },
    referencielTVAId: string
  ): Promise<void> {
    commit(ReferencielTVAMutations.SET_IS_DELETING_REFERENCIEL_TVA, true)
    axios
      .delete(
        process.env.VUE_APP_API_ORISIS + 'Invoice/ReferencielTVA/DeleteReferencielTVA',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          params: {
            referencielTVAId: referencielTVAId
          }
        }
      )
      .then(res => {
        rootGetters.ReferencielTVAsList.splice(
          rootGetters.ReferencielTVAsList.findIndex(
            elem => elem.id == referencielTVAId
          ),
          1
        )
        commit(
          ReferencielTVAMutations.SET_REFERENCIEL_TVAS_LIST,
          rootGetters.ReferencielTVAsList
        )
        commit(ReferencielTVAMutations.SET_REQUEST_STATE_REFERENCIEL_TVA, res)
        commit(ReferencielTVAMutations.SET_IS_DELETING_REFERENCIEL_TVA, false)
      })
      .catch(err => {
        console.error(err)
        commit(ReferencielTVAMutations.SET_REQUEST_STATE_REFERENCIEL_TVA, err)
        commit(ReferencielTVAMutations.SET_IS_DELETING_REFERENCIEL_TVA, false)
      })
  },

  /**
   * Archive a referenciel TVA from the database
   * @param  - - `commit`: the commit function of the Vuex store.
   * @param {string} referencielTVAId - The id of the referenciel TVA to archive.
   */
     async archiveReferencielTVA (
      { commit, rootGetters },
      referencielTVAId: string
    ): Promise<void> {
      commit(ReferencielTVAMutations.SET_IS_ARCHIVING_REFERENCIEL_TVA, true)
      axios
        .delete(
          process.env.VUE_APP_API_ORISIS + 'Billing/ReferencielTVA/Archive',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
            data:[referencielTVAId]
          }
        )
        .then(res => {
          rootGetters.referencielTVAsListArchived.unshift(rootGetters.referencielTVAsList.find(elem => elem.id == referencielTVAId ))
          rootGetters.referencielTVAsList.splice(
            rootGetters.referencielTVAsList.findIndex(
              elem => elem.id == referencielTVAId
            ),
            1
          )
          commit(
            ReferencielTVAMutations.SET_REFERENCIEL_TVAS_LIST,
            rootGetters.referencielTVAsList
          )
          commit(ReferencielTVAMutations.SET_REQUEST_STATE_REFERENCIEL_TVA, res)
          commit(ReferencielTVAMutations.SET_IS_ARCHIVING_REFERENCIEL_TVA, false)
        })
        .catch(err => {
          console.error(err)
          commit(ReferencielTVAMutations.SET_REQUEST_STATE_REFERENCIEL_TVA, err)
          commit(ReferencielTVAMutations.SET_IS_ARCHIVING_REFERENCIEL_TVA, false)
        })
    },
  
    /**
     * I'm using the
     * @param  - { commit, rootGetters },
     * @param {string} referencielTVAId - string
     */
    async restoreReferencielTVA (
      { commit, rootGetters },
      referencielTVAId: string
    ): Promise<void> {
      commit(ReferencielTVAMutations.SET_IS_UPDATING_REFERENCIEL_TVA, true)
      axios
        .put(
          process.env.VUE_APP_API_ORISIS + 'Billing/ReferencielTVA/Restore',
          [referencielTVAId],
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
            data:[referencielTVAId]
          }
        )
        .then(res => {
          rootGetters.referencielTVAsList.unshift(rootGetters.referencielTVAsListArchived.find(elem => elem.id == referencielTVAId ))
          rootGetters.referencielTVAsListArchived.splice(
            rootGetters.referencielTVAsListArchived.findIndex(
              elem => elem.id == referencielTVAId
            ),
            1
          )
          commit(ReferencielTVAMutations.SET_REQUEST_STATE_REFERENCIEL_TVA, res)
          commit(ReferencielTVAMutations.SET_IS_UPDATING_REFERENCIEL_TVA, false)
        })
        .catch(err => {
          console.error(err)
          commit(ReferencielTVAMutations.SET_REQUEST_STATE_REFERENCIEL_TVA, err)
          commit(ReferencielTVAMutations.SET_IS_UPDATING_REFERENCIEL_TVA, false)
        })
    },
}
