import { ICodeTvaState } from "./states"
import { Module } from "vuex"
import { CodeTvaRequestDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/RootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:ICodeTvaState = {
    CodeTvaList         : Array<CodeTvaRequestDto>(),
    CodeTvaListArchived : Array<CodeTvaRequestDto>(),
    codeTva              : <CodeTvaRequestDto>{},
    isLoadingCodeTvaList     : false,
    isLoadingCodeTvaListArchived     : false,
    isLoadingCodeTva     : false,
    isCreatingCodeTva    : false,
    isUpdatingCodeTva    : false,
    isDeletingCodeTva    : false,
    isArchivingCodeTva    : false,
    requestStateCodeTva: <IRequestState>{},
    isOpenSidebarCodeTva : false,
}

export const CodeTvaModule: Module<ICodeTvaState, RootState> = {
    state, 
    getters,
    mutations,
    actions
} 