import { ICommonState } from "./states"
import { Module } from "vuex"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/RootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:ICommonState = {
    publicHolidayList         :{},
    isLoadingPublicHolidayList     : false,
    requestStatePublicHolidayList: <IRequestState>{},
}

export const CommonModule: Module<ICommonState, RootState> = {
    state, 
    getters,
    mutations,
    actions
}