import { ILeaseState } from "./states"
import { Module } from "vuex"
import { LeaseRequestDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/RootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:ILeaseState = {
    leasesList         : Array<LeaseRequestDto>(),
    leasesListArchived : Array<LeaseRequestDto>(),
    lease              : <LeaseRequestDto>{},
    isLoadingLeasesList     : false,
    isLoadingLeasesListArchived     : false,
    isLoadingLease     : false,
    isCreatingLease    : false,
    isUpdatingLease    : false,
    isDeletingLease    : false,
    isArchivingLease    : false,
    requestStateLease: <IRequestState>{},
    isOpenSidebarLease : false,
}

export const LeaseModule: Module<ILeaseState, RootState> = {
    state, 
    getters,
    mutations,
    actions
} 