import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { LeaseTypeRequestDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '../../RootState'
import { ILeaseTypeState } from './states'


export const getters: GetterTree<ILeaseTypeState, RootState> = {
  leaseTypeList: (state): LeaseTypeRequestDto[] => state.leaseTypeList,
  leaseTypeListArchived: (state): LeaseTypeRequestDto[] => state.leaseTypeListArchived,
  leaseType     : (state): LeaseTypeRequestDto => state.leaseType,

  // COMMON
  isLoadingLeaseTypeList   : (state): boolean => state.isLoadingLeaseTypeList,
  isLoadingLeaseTypeListArchived   : (state): boolean => state.isLoadingLeaseTypeListArchived,
  isLoadingLeaseType   : (state): boolean => state.isLoadingLeaseType,
  isCreatingLeaseType  : (state): boolean => state.isCreatingLeaseType,
  isUpdatingLeaseType  : (state): boolean => state.isUpdatingLeaseType,
  isDeletingLeaseType  : (state): boolean => state.isDeletingLeaseType,
  isArchivingLeaseType  : (state): boolean => state.isArchivingLeaseType,
  requestStateLeaseType: (state): IRequestState => state.requestStateLeaseType,
  isOpenSidebarLeaseType : (state): boolean => state.isOpenSidebarLeaseType,
}



