import { CodeTvaRequestDto } from '@/../Api'
import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '../../RootState'
import { CodeTvaMutations } from './mutations'
import { ICodeTvaState } from './states'

export const actions: ActionTree<ICodeTvaState, RootState> = {
  /**
   * It fetches the list of CodeTvas from the API.
   * @param  - **commit**: The commit function is used to send mutations to the store.
   */
  async fetchCodeTvaList ({ commit, rootGetters }): Promise<void> {
   return new Promise<void>(async (resolve, reject) => {
    commit(CodeTvaMutations.SET_IS_LOADING_CODE_TVA_LIST, true)
    let url = `${process.env.VUE_APP_API_ORISIS}Billing/CodeTva/GetCodeTvasByWorkspaceId`
    await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          workspaceId: rootGetters.workspaceSelected.id
        }
      })
      .then(res => {
        commit(CodeTvaMutations.SET_IS_LOADING_CODE_TVA_LIST, false)
        commit(CodeTvaMutations.SET_CODE_TVAS_LIST, res.data)
        commit(CodeTvaMutations.SET_REQUEST_STATE_CODE_TVA, res)
        resolve(res.data)
      })
      .catch(err => {
        console.error(err)
        commit(CodeTvaMutations.SET_IS_LOADING_CODE_TVA_LIST, false)
        commit(CodeTvaMutations.SET_REQUEST_STATE_CODE_TVA, err)
      })
   })
  },
  // async fetchCodeTvaListArchived ({ commit, rootGetters }): Promise<void> {
  //  return new Promise<void>(async (resolve, reject) => {
  //   commit(CodeTvaMutations.SET_IS_LOADING_CODE_TVA_LIST_ARCHIVED, true)
  //   let url = `${process.env.VUE_APP_API_ORISIS}Billing/CodeTva/GetCodeTvaArchivedByWorkspaceId`
  //   await axios
  //     .get(url, {
  //       headers: {
  //         Authorization: `Bearer ${rootGetters.userToken}`
  //       },
  //       params: {
  //         workspaceId: rootGetters.workspaceSelected.id
  //       }
  //     })
  //     .then(res => {
  //       commit(CodeTvaMutations.SET_IS_LOADING_CODE_TVA_LIST_ARCHIVED, false)
  //       commit(CodeTvaMutations.SET_CODE_TVAS_LIST_ARCHIVED, res.data)
  //       commit(CodeTvaMutations.SET_REQUEST_STATE_CODE_TVA, res)
  //       resolve(res.data)
  //     })
  //     .catch(err => {
  //       console.error(err)
  //       commit(CodeTvaMutations.SET_IS_LOADING_CODE_TVA_LIST_ARCHIVED, false)
  //       commit(CodeTvaMutations.SET_REQUEST_STATE_CODE_TVA, err)
  //     })
  //  })
  // },

  /* Fetching the codeTva data from the API. */
  async fetchCodeTva ({ commit, rootGetters }, codeTvaId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(CodeTvaMutations.SET_IS_LOADING_CODE_TVA, true)
    axios
      .get(`${process.env.VUE_APP_API_ORISIS}Billing/CodeTva/GetCodeTvaById`, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          codeTvaId: codeTvaId
        }
      })
      .then(res => {
        commit(CodeTvaMutations.SET_CODE_TVA, res.data)
        commit(CodeTvaMutations.SET_REQUEST_STATE_CODE_TVA, res)
        commit(CodeTvaMutations.SET_IS_LOADING_CODE_TVA, false)
        resolve(res.data)
      })
      .catch(err => {
        commit(CodeTvaMutations.SET_REQUEST_STATE_CODE_TVA, err)
        commit(CodeTvaMutations.SET_IS_LOADING_CODE_TVA, false)
      })
    })
  },

  // async fetchCodeTvaArchived (
  //   { commit, rootGetters },
  //   codeTvaId: string
  // ): Promise<void> {
  //   return new Promise<void>((resolve, reject) => {
  //     commit(CodeTvaMutations.SET_IS_LOADING_CODE_TVA, true)
  //   axios
  //     .get(`${process.env.VUE_APP_API_ORISIS}Billing/CodeTva/GetCodeTvaArchivedById`, {
  //       headers: {
  //         Authorization: `Bearer ${rootGetters.userToken}`
  //       },
  //       params: {
  //         codeTvaId: codeTvaId
  //       }
  //     })
  //     .then(res => {
  //       commit(CodeTvaMutations.SET_CODE_TVA, res.data)
  //       commit(CodeTvaMutations.SET_REQUEST_STATE_CODE_TVA, res)
  //       commit(CodeTvaMutations.SET_IS_LOADING_CODE_TVA, false)
  //       resolve(res.data)
  //     })
  //     .catch(err => {
  //       commit(CodeTvaMutations.SET_REQUEST_STATE_CODE_TVA, err)
  //       commit(CodeTvaMutations.SET_IS_LOADING_CODE_TVA, false)
  //     })
  //   })
  // },

  /**
   * It creates a new codeTva.
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {CodeTvaRequestDto} codeTva - CodeTvaRequestDto
   */
  async createCodeTva ({ commit, rootGetters }, codeTva: CodeTvaRequestDto): Promise<void> {
    commit(CodeTvaMutations.SET_IS_CREATING_CODE_TVA, true)
    await axios
      .post(
        `${process.env.VUE_APP_API_ORISIS}Billing/CodeTva/CreateCodeTva`,
        {...codeTva, workspaceId: rootGetters.workspaceSelected.id},
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {

        rootGetters.CodeTvaList.push(res.data.data)
        commit(
          CodeTvaMutations.SET_CODE_TVAS_LIST,
          rootGetters.CodeTvaList
        )
        commit(CodeTvaMutations.SET_REQUEST_STATE_CODE_TVA, res)
        commit(CodeTvaMutations.SET_IS_CREATING_CODE_TVA, false)
      })
      .catch(err => {
        commit(CodeTvaMutations.SET_REQUEST_STATE_CODE_TVA, err)
        commit(CodeTvaMutations.SET_IS_CREATING_CODE_TVA, false)
      })
  },

  /**
   * Update an codeTva
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {CodeTvaRequestDto} codeTva - CodeTvaRequestDto
   */
  async updateCodeTva ({ commit, rootGetters }, codeTva: CodeTvaRequestDto) {
    commit(CodeTvaMutations.SET_IS_UPDATING_CODE_TVA, true)
    await axios
      .put(
        `${process.env.VUE_APP_API_ORISIS}Billing/CodeTva/UpdateCodeTva`,
        {...codeTva, workspaceId: rootGetters.workspaceSelected.id},
        
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.CodeTvaList.splice(
          rootGetters.CodeTvaList.findIndex(elem => elem.id == codeTva.id),
          1,
          codeTva
        )
        commit(
          CodeTvaMutations.SET_CODE_TVAS_LIST,
          rootGetters.CodeTvaList
        )
        commit(CodeTvaMutations.SET_REQUEST_STATE_CODE_TVA, res)
        commit(CodeTvaMutations.SET_IS_UPDATING_CODE_TVA, false)
      })
      .catch(err => {
        commit(CodeTvaMutations.SET_REQUEST_STATE_CODE_TVA, err)
        commit(CodeTvaMutations.SET_IS_UPDATING_CODE_TVA, false)
      })
  },

  /**
   * It deletes an codeTva from the database.
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {string} codeTvaId - string
   */
  async deleteCodeTva ({ commit, rootGetters }, codeTvaId: string) {
    commit(CodeTvaMutations.SET_IS_DELETING_CODE_TVA, true)
    await axios
      .delete(`${process.env.VUE_APP_API_ORISIS}Billing/CodeTva/Delete`, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          codeTvaId: codeTvaId
        }
      })
      .then(res => {
        rootGetters.CodeTvaList.splice(
          rootGetters.CodeTvaList.findIndex(elem => elem.id == codeTvaId),
          1
        )
        commit(
          CodeTvaMutations.SET_CODE_TVAS_LIST,
          rootGetters.CodeTvaList
        )
        commit(CodeTvaMutations.SET_REQUEST_STATE_CODE_TVA, res)
        commit(CodeTvaMutations.SET_IS_DELETING_CODE_TVA, false)
      })
      .catch(err => {
        commit(CodeTvaMutations.SET_REQUEST_STATE_CODE_TVA, err)
        commit(CodeTvaMutations.SET_IS_DELETING_CODE_TVA, false)
      })
  },

  /**
   * Delete a codeTva from the database
   * @param  - - `commit`: the commit function of the Vuex store.
   * @param {string} codeTvaId - The id of the codeTva to delete.
   */
  async archiveCodeTva (
    { commit, rootGetters },
    codeTvaId: string
  ): Promise<void> {
    commit(CodeTvaMutations.SET_IS_ARCHIVING_CODE_TVA, true)
    axios
      .delete(
        process.env.VUE_APP_API_ORISIS + 'Billing/CodeTva/Archive',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          data:[codeTvaId]
        }
      )
      .then(res => {
        rootGetters.CodeTvaListArchived.unshift(rootGetters.CodeTvaList.find(elem => elem.id == codeTvaId ))
        rootGetters.CodeTvaList.splice(
          rootGetters.CodeTvaList.findIndex(
            elem => elem.id == codeTvaId
          ),
          1
        )
        commit(
          CodeTvaMutations.SET_CODE_TVAS_LIST,
          rootGetters.CodeTvaList
        )
        commit(CodeTvaMutations.SET_REQUEST_STATE_CODE_TVA, res)
        commit(CodeTvaMutations.SET_IS_ARCHIVING_CODE_TVA, false)
      })
      .catch(err => {
        console.error(err)
        commit(CodeTvaMutations.SET_REQUEST_STATE_CODE_TVA, err)
        commit(CodeTvaMutations.SET_IS_ARCHIVING_CODE_TVA, false)
      })
   },

  // async restoreCodeTva (
  //   { commit, rootGetters },
  //   codeTvaId: string
  // ): Promise<void> {
  //   commit(CodeTvaMutations.SET_IS_UPDATING_CODE_TVA, true)
  //   axios
  //     .put(
  //       process.env.VUE_APP_API_ORISIS + 'Billing/CodeTva/Restore',
  //       [codeTvaId],
  //       {
  //         headers: {
  //           Authorization: `Bearer ${rootGetters.userToken}`
  //         }
  //       }
  //     )
  //     .then(res => {
  //       rootGetters.CodeTvaList.unshift(rootGetters.CodeTvaListArchived.find(elem => elem.id == codeTvaId ))
  //       rootGetters.CodeTvaListArchived.splice(
  //         rootGetters.CodeTvaListArchived.findIndex(
  //           elem => elem.id == codeTvaId
  //         ),
  //         1
  //       )
  //       commit(CodeTvaMutations.SET_REQUEST_STATE_CODE_TVA, res)
  //       commit(CodeTvaMutations.SET_IS_UPDATING_CODE_TVA, false)
  //     })
  //     .catch(err => {
  //       console.error(err)
  //       commit(CodeTvaMutations.SET_REQUEST_STATE_CODE_TVA, err)
  //       commit(CodeTvaMutations.SET_IS_UPDATING_CODE_TVA, false)
  //     })
  //  }
}
