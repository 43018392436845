import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { IPlanningView } from "@/types/api-orisis/interfaces/Planning/IPlanningViews";
import { IPlanningLine } from "@/types/api-orisis/interfaces/Planning/ILinePosition";
import { GetterTree } from "vuex";
import RootState from "../../RootState";
import { IPlanningViewState } from "./states";

export const getters: GetterTree<IPlanningViewState, RootState> = {
    planningViewsList: (state): IPlanningView[] => state.planningViewsList,
    planningViewsListArchived: (state): IPlanningView[] => state.planningViewsList,
    planningView     : (state): IPlanningView => state.planningView,

    // COMMON
    isLoadingPlanningViewsList   : (state): boolean => state.isLoadingPlanningViewsList,
    isLoadingPlanningView   : (state): boolean => state.isLoadingPlanningView,
    isCreatingPlanningView  : (state): boolean => state.isCreatingPlanningView,
    isUpdatingPlanningView  : (state): boolean => state.isUpdatingPlanningView,
    isDeletingPlanningView  : (state): boolean => state.isDeletingPlanningView,
    isArchivingPlanningView  : (state): boolean => state.isArchivingPlanningView,
    requestStatePlanningView: (state): IRequestState => state.requestStatePlanningView,
}