import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { LeaseTypeRequestDto } from '@/../Api'
import { MutationTree } from "vuex";
import { ILeaseTypeState } from "./states";

export enum LeaseTypeMutations {
    SET_LEASE_TYPE_LIST = "SET_LEASE_TYPE_LIST",
    SET_LEASE_TYPE_LIST_ARCHIVED = "SET_LEASE_TYPE_LIST_ARCHIVED",
    SET_LEASE_TYPE = "SET_LEASE_TYPE",

    // COMMON
    SET_IS_LOADING_LEASE_TYPE_LIST = "SET_IS_LOADING_LEASE_TYPE_LIST",
    SET_IS_LOADING_LEASE_TYPE_LIST_ARCHIVED = "SET_IS_LOADING_LEASE_TYPE_LIST_ARCHIVED",
    SET_IS_LOADING_LEASE_TYPE = "SET_IS_LOADING_LEASE_TYPE",
    SET_IS_CREATING_LEASE_TYPE = "SET_IS_CREATING_LEASE_TYPE",
    SET_IS_UPDATING_LEASE_TYPE = "SET_IS_UPDATING_LEASE_TYPE",
    SET_IS_DELETING_LEASE_TYPE = "SET_IS_DELETING_LEASE_TYPE",
    SET_IS_ARCHIVING_LEASE_TYPE = "SET_IS_DELETING_LEASE_TYPE",
    SET_REQUEST_STATE_LEASE_TYPE = "SET_REQUEST_STATE_LEASE_TYPE",
    SET_IS_OPEN_SIDEBAR_LEASE_TYPE = "SET_IS_OPEN_SIDEBAR_LEASE_TYPE"
}

export const mutations: MutationTree<ILeaseTypeState> = {
    [LeaseTypeMutations.SET_LEASE_TYPE_LIST_ARCHIVED]: (state, payload: LeaseTypeRequestDto[]) => {
        state.leaseTypeListArchived = payload;
    }, 
    [LeaseTypeMutations.SET_LEASE_TYPE_LIST]: (state, payload: LeaseTypeRequestDto[]) => {
        state.leaseTypeList = payload;
    },
    [LeaseTypeMutations.SET_LEASE_TYPE]: (state, payload: LeaseTypeRequestDto) => {
        state.leaseType = payload;
    },


    //COMMON
    [LeaseTypeMutations.SET_IS_LOADING_LEASE_TYPE_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingLeaseTypeListArchived = payload;
    },
    [LeaseTypeMutations.SET_IS_LOADING_LEASE_TYPE_LIST]: (state, payload: boolean) => {
        state.isLoadingLeaseTypeList = payload;
    },
    [LeaseTypeMutations.SET_IS_LOADING_LEASE_TYPE]: (state, payload: boolean) => {
        state.isLoadingLeaseType = payload;
    },
    [LeaseTypeMutations.SET_IS_CREATING_LEASE_TYPE]: (state, payload: boolean) => {
        state.isCreatingLeaseType = payload;
    },
    [LeaseTypeMutations.SET_IS_UPDATING_LEASE_TYPE]: (state, payload: boolean) => {
        state.isUpdatingLeaseType = payload;
    },
    [LeaseTypeMutations.SET_IS_DELETING_LEASE_TYPE]: (state, payload: boolean) => {
        state.isDeletingLeaseType = payload;
    },
    [LeaseTypeMutations.SET_IS_ARCHIVING_LEASE_TYPE]: (state, payload: boolean) => {
        state.isArchivingLeaseType = payload;
    },
    [LeaseTypeMutations.SET_REQUEST_STATE_LEASE_TYPE]: (state, payload: IRequestState) => {
        state.requestStateLeaseType = payload;
    },
    [LeaseTypeMutations.SET_IS_OPEN_SIDEBAR_LEASE_TYPE]: (state, payload: boolean) => {
        state.isOpenSidebarLeaseType = payload;
    },
}