import { IContactState } from "./states"
import { Module } from "vuex"
import { ContactRequestDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/RootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:IContactState = {
    contactsList : Array<ContactRequestDto>(),
    contactsListArchived : Array<ContactRequestDto>(),
    contact      : <ContactRequestDto>{},
    isLoadingContactsList    : false,
    isLoadingContactsListArchived    : false,
    isLoadingContact    : false,
    isCreatingContact   : false,
    isUpdatingContact   : false,
    isDeletingContact   : false,
    requestStateContact : <IRequestState>{},
    isOpenSidebarContact : false,
    isArchivingContact : false,
}

export const ContactModule: Module<IContactState, RootState> = {
    state, 
    getters,
    mutations,
    actions
}