import { ContractRequestDto } from '@/../Api'
import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '../../RootState'
import { ContractMutations } from './mutations'
import { IContractState } from './states'

export const actions: ActionTree<IContractState, RootState> = {
  /**
   * It fetches the list of contracts from the API.
   * @param  - **commit**: The commit function is used to send mutations to the store.
   */
  async fetchContractsList ({ commit, rootGetters }): Promise<void> {
   return new Promise<void>(async (resolve, reject) => {
    commit(ContractMutations.SET_IS_LOADING_CONTRACT_LIST, true)
    let url = `${process.env.VUE_APP_API_ORISIS}Parameters/Contract/GetContract`
    await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          workspaceId: rootGetters.workspaceSelected.id
        }
      })
      .then(res => {
        commit(ContractMutations.SET_IS_LOADING_CONTRACT_LIST, false)
        commit(ContractMutations.SET_CONTRACTS_LIST, res.data)
        commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, res)
        resolve(res.data)
      })
      .catch(err => {
        console.error(err)
        commit(ContractMutations.SET_IS_LOADING_CONTRACT_LIST, false)
        commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, err)
      })
   })
  },
  async fetchContractsListArchived ({ commit, rootGetters }): Promise<void> {
   return new Promise<void>(async (resolve, reject) => {
    commit(ContractMutations.SET_IS_LOADING_CONTRACT_LIST_ARCHIVED, true)
    let url = `${process.env.VUE_APP_API_ORISIS}Parameters/Contract/GetContractArchivedByWorkspaceId`
    await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          workspaceId: rootGetters.workspaceSelected.id
        }
      })
      .then(res => {
        commit(ContractMutations.SET_IS_LOADING_CONTRACT_LIST_ARCHIVED, false)
        commit(ContractMutations.SET_CONTRACTS_LIST_ARCHIVED, res.data)
        commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, res)
        resolve(res.data)
      })
      .catch(err => {
        console.error(err)
        commit(ContractMutations.SET_IS_LOADING_CONTRACT_LIST_ARCHIVED, false)
        commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, err)
      })
   })
  },

  /* Fetching the contract data from the API. */
  async fetchContract ({ commit, rootGetters }, contractId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(ContractMutations.SET_IS_LOADING_CONTRACT, true)
    axios
      .get(`${process.env.VUE_APP_API_ORISIS}Parameters/Contract/GetContractById`, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          contractId: contractId
        }
      })
      .then(res => {
        commit(ContractMutations.SET_CONTRACT, res.data)
        commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, res)
        commit(ContractMutations.SET_IS_LOADING_CONTRACT, false)
        resolve(res.data)
      })
      .catch(err => {
        commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, err)
        commit(ContractMutations.SET_IS_LOADING_CONTRACT, false)
      })
    })
  },

  async fetchContractArchived (
    { commit, rootGetters },
    contractId: string
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(ContractMutations.SET_IS_LOADING_CONTRACT, true)
    axios
      .get(`${process.env.VUE_APP_API_ORISIS}Parameters/Contract/GetContractArchivedById`, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          contractId: contractId
        }
      })
      .then(res => {
        commit(ContractMutations.SET_CONTRACT, res.data)
        commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, res)
        commit(ContractMutations.SET_IS_LOADING_CONTRACT, false)
        resolve(res.data)
      })
      .catch(err => {
        commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, err)
        commit(ContractMutations.SET_IS_LOADING_CONTRACT, false)
      })
    })
  },

  /**
   * It creates a new contract.
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {ContractRequestDto} contract - ContractRequestDto
   */
  async createContract ({ commit, rootGetters }, contract: ContractRequestDto): Promise<void> {
    commit(ContractMutations.SET_IS_CREATING_CONTRACT, true)
    await axios
      .post(
        `${process.env.VUE_APP_API_ORISIS}Parameters/Contract/CreateContract`,
        {...contract, workspaceId: rootGetters.workspaceSelected.id},
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {

        rootGetters.contractsList.push(res.data.data)
        commit(
          ContractMutations.SET_CONTRACTS_LIST,
          rootGetters.contractsList
        )
        commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, res)
        commit(ContractMutations.SET_IS_CREATING_CONTRACT, false)
      })
      .catch(err => {
        commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, err)
        commit(ContractMutations.SET_IS_CREATING_CONTRACT, false)
      })
  },

  /**
   * Update an contract
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {ContractRequestDto} contract - ContractRequestDto
   */
  async updateContract ({ commit, rootGetters }, contract: ContractRequestDto) {
    commit(ContractMutations.SET_IS_UPDATING_CONTRACT, true)
    await axios
      .put(
        `${process.env.VUE_APP_API_ORISIS}Parameters/Contract/UpdateContract`,
        {...contract, workspaceId: rootGetters.workspaceSelected.id},
        
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.contractsList.splice(
          rootGetters.contractsList.findIndex(elem => elem.id == contract.id),
          1,
          contract
        )
        commit(
          ContractMutations.SET_CONTRACTS_LIST,
          rootGetters.contractsList
        )
        commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, res)
        commit(ContractMutations.SET_IS_UPDATING_CONTRACT, false)
      })
      .catch(err => {
        commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, err)
        commit(ContractMutations.SET_IS_UPDATING_CONTRACT, false)
      })
  },

  /**
   * It deletes an contract from the database.
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {string} contractId - string
   */
  async deleteContract ({ commit, rootGetters }, contractId: string) {
    commit(ContractMutations.SET_IS_DELETING_CONTRACT, true)
    await axios
      .delete(`${process.env.VUE_APP_API_ORISIS}Parameters/Contract/Delete`, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          contractId: contractId
        }
      })
      .then(res => {
        rootGetters.contractsList.splice(
          rootGetters.contractsList.findIndex(elem => elem.id == contractId),
          1
        )
        commit(
          ContractMutations.SET_CONTRACTS_LIST,
          rootGetters.contractsList
        )
        commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, res)
        commit(ContractMutations.SET_IS_DELETING_CONTRACT, false)
      })
      .catch(err => {
        commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, err)
        commit(ContractMutations.SET_IS_DELETING_CONTRACT, false)
      })
  },

  /**
   * Delete a contract from the database
   * @param  - - `commit`: the commit function of the Vuex store.
   * @param {string} contractId - The id of the contract to delete.
   */
  async archiveContract (
    { commit, rootGetters },
    contractId: string
  ): Promise<void> {
    commit(ContractMutations.SET_IS_ARCHIVING_CONTRACT, true)
    axios
      .delete(
        process.env.VUE_APP_API_ORISIS + 'Parameters/Contract/Archive',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          data:[contractId]
        }
      )
      .then(res => {
        rootGetters.contractsListArchived.unshift(rootGetters.contractsList.find(elem => elem.id == contractId ))
        rootGetters.contractsList.splice(
          rootGetters.contractsList.findIndex(
            elem => elem.id == contractId
          ),
          1
        )
        commit(
          ContractMutations.SET_CONTRACTS_LIST,
          rootGetters.contractsList
        )
        commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, res)
        commit(ContractMutations.SET_IS_ARCHIVING_CONTRACT, false)
      })
      .catch(err => {
        console.error(err)
        commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, err)
        commit(ContractMutations.SET_IS_ARCHIVING_CONTRACT, false)
      })
   },

  async restoreContract (
    { commit, rootGetters },
    contractId: string
  ): Promise<void> {
    commit(ContractMutations.SET_IS_UPDATING_CONTRACT, true)
    axios
      .put(
        process.env.VUE_APP_API_ORISIS + 'Parameters/Contract/Restore',
        [contractId],
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.contractsList.unshift(rootGetters.contractsListArchived.find(elem => elem.id == contractId ))
        rootGetters.contractsListArchived.splice(
          rootGetters.contractsListArchived.findIndex(
            elem => elem.id == contractId
          ),
          1
        )
        commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, res)
        commit(ContractMutations.SET_IS_UPDATING_CONTRACT, false)
      })
      .catch(err => {
        console.error(err)
        commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, err)
        commit(ContractMutations.SET_IS_UPDATING_CONTRACT, false)
      })
   }
}
