import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { CompanyTypeRequestDto } from '@/../Api'
import RootState from "@/store/modules/RootState"
import { ICompanyTypeState } from "./states";
import { Module } from "vuex"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"

export const state: ICompanyTypeState = {
    companiesTypesList      : Array<CompanyTypeRequestDto>(),
    companyType            : <CompanyTypeRequestDto>{},
    isLoadingCompanyType   : false,
    isCreatingCompanyType  : false,
    isUpdatingCompanyType  : false,
    isDeletingCompanyType  : false,
    requestStateCompanyType: <IRequestState>{}
}

export const CompaniesTypesModule: Module<ICompanyTypeState, RootState> = {
  state, 
  getters,
  mutations,
  actions
}