import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { CodeTvaRequestDto } from '@/../Api'
import { MutationTree } from "vuex";
import { ICodeTvaState } from "./states";

export enum CodeTvaMutations {
    SET_CODE_TVAS_LIST = "SET_CODE_TVAS_LIST",
    SET_CODE_TVAS_LIST_ARCHIVED = "SET_CODE_TVAS_LIST_ARCHIVED",
    SET_CODE_TVA = "SET_CODE_TVA",

    // COMMON
    SET_IS_LOADING_CODE_TVA_LIST = "SET_IS_LOADING_CODE_TVA_LIST",
    SET_IS_LOADING_CODE_TVA_LIST_ARCHIVED = "SET_IS_LOADING_CODE_TVA_LIST_ARCHIVED",
    SET_IS_LOADING_CODE_TVA = "SET_IS_LOADING_CODE_TVA",
    SET_IS_CREATING_CODE_TVA = "SET_IS_CREATING_CODE_TVA",
    SET_IS_UPDATING_CODE_TVA = "SET_IS_UPDATING_CODE_TVA",
    SET_IS_DELETING_CODE_TVA = "SET_IS_DELETING_CODE_TVA",
    SET_IS_ARCHIVING_CODE_TVA = "SET_IS_DELETING_CODE_TVA",
    SET_REQUEST_STATE_CODE_TVA = "SET_REQUEST_STATE_CODE_TVA",
    SET_IS_OPEN_SIDEBAR_CODE_TVA = "SET_IS_OPEN_SIDEBAR_CODE_TVA"
}

export const mutations: MutationTree<ICodeTvaState> = {
    [CodeTvaMutations.SET_CODE_TVAS_LIST_ARCHIVED]: (state, payload: CodeTvaRequestDto[]) => {
        state.CodeTvaListArchived = payload;
    }, 
    [CodeTvaMutations.SET_CODE_TVAS_LIST]: (state, payload: CodeTvaRequestDto[]) => {
        state.CodeTvaList = payload;
    },
    [CodeTvaMutations.SET_CODE_TVA]: (state, payload: CodeTvaRequestDto) => {
        state.codeTva = payload;
    },


    //COMMON
    [CodeTvaMutations.SET_IS_LOADING_CODE_TVA_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingCodeTvaListArchived = payload;
    },
    [CodeTvaMutations.SET_IS_LOADING_CODE_TVA_LIST]: (state, payload: boolean) => {
        state.isLoadingCodeTvaList = payload;
    },
    [CodeTvaMutations.SET_IS_LOADING_CODE_TVA]: (state, payload: boolean) => {
        state.isLoadingCodeTva = payload;
    },
    [CodeTvaMutations.SET_IS_CREATING_CODE_TVA]: (state, payload: boolean) => {
        state.isCreatingCodeTva = payload;
    },
    [CodeTvaMutations.SET_IS_UPDATING_CODE_TVA]: (state, payload: boolean) => {
        state.isUpdatingCodeTva = payload;
    },
    [CodeTvaMutations.SET_IS_DELETING_CODE_TVA]: (state, payload: boolean) => {
        state.isDeletingCodeTva = payload;
    },
    [CodeTvaMutations.SET_IS_ARCHIVING_CODE_TVA]: (state, payload: boolean) => {
        state.isArchivingCodeTva = payload;
    },
    [CodeTvaMutations.SET_REQUEST_STATE_CODE_TVA]: (state, payload: IRequestState) => {
        state.requestStateCodeTva = payload;
    },
    [CodeTvaMutations.SET_IS_OPEN_SIDEBAR_CODE_TVA]: (state, payload: boolean) => {
        state.isOpenSidebarCodeTva = payload;
    },
}