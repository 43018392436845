import { LeaseRequestDto } from '@/../Api'
import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '../../RootState'
import { LeaseMutations } from './mutations'
import { ILeaseState } from './states'

export const actions: ActionTree<ILeaseState, RootState> = {
  /**
   * It fetches the list of Leases from the API.
   * @param  - **commit**: The commit function is used to send mutations to the store.
   */
  async fetchLeaseList ({ commit, rootGetters }): Promise<void> {
   return new Promise<void>(async (resolve, reject) => {
    commit(LeaseMutations.SET_IS_LOADING_LEASE_LIST, true)
    let url = `${process.env.VUE_APP_API_ORISIS}Billing/Lease/GetLeasesByWorkspaceId`
    await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          workspaceId: rootGetters.workspaceSelected.id
        }
      })
      .then(res => {
        commit(LeaseMutations.SET_IS_LOADING_LEASE_LIST, false)
        commit(LeaseMutations.SET_LEASES_LIST, res.data)
        commit(LeaseMutations.SET_REQUEST_STATE_LEASE, res)
        resolve(res.data)
      })
      .catch(err => {
        console.error(err)
        commit(LeaseMutations.SET_IS_LOADING_LEASE_LIST, false)
        commit(LeaseMutations.SET_REQUEST_STATE_LEASE, err)
      })
   })
  },
  async fetchLeaseListArchived ({ commit, rootGetters }): Promise<void> {
   return new Promise<void>(async (resolve, reject) => {
    commit(LeaseMutations.SET_IS_LOADING_LEASE_LIST_ARCHIVED, true)
    let url = `${process.env.VUE_APP_API_ORISIS}Billing/Lease/GetLeaseArchivedByWorkspaceId`
    await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          workspaceId: rootGetters.workspaceSelected.id
        }
      })
      .then(res => {
        commit(LeaseMutations.SET_IS_LOADING_LEASE_LIST_ARCHIVED, false)
        commit(LeaseMutations.SET_LEASES_LIST_ARCHIVED, res.data)
        commit(LeaseMutations.SET_REQUEST_STATE_LEASE, res)
        resolve(res.data)
      })
      .catch(err => {
        console.error(err)
        commit(LeaseMutations.SET_IS_LOADING_LEASE_LIST_ARCHIVED, false)
        commit(LeaseMutations.SET_REQUEST_STATE_LEASE, err)
      })
   })
  },

  /* Fetching the lease data from the API. */
  async fetchLease ({ commit, rootGetters }, leaseId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(LeaseMutations.SET_IS_LOADING_LEASE, true)
    axios
      .get(`${process.env.VUE_APP_API_ORISIS}Billing/Lease/GetLeaseById`, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          leaseId: leaseId
        }
      })
      .then(res => {
        commit(LeaseMutations.SET_LEASE, res.data)
        commit(LeaseMutations.SET_REQUEST_STATE_LEASE, res)
        commit(LeaseMutations.SET_IS_LOADING_LEASE, false)
        resolve(res.data)
      })
      .catch(err => {
        commit(LeaseMutations.SET_REQUEST_STATE_LEASE, err)
        commit(LeaseMutations.SET_IS_LOADING_LEASE, false)
      })
    })
  },

  async fetchLeaseArchived (
    { commit, rootGetters },
    leaseId: string
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(LeaseMutations.SET_IS_LOADING_LEASE, true)
    axios
      .get(`${process.env.VUE_APP_API_ORISIS}Billing/Lease/GetLeaseArchivedById`, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          leaseId: leaseId
        }
      })
      .then(res => {
        commit(LeaseMutations.SET_LEASE, res.data)
        commit(LeaseMutations.SET_REQUEST_STATE_LEASE, res)
        commit(LeaseMutations.SET_IS_LOADING_LEASE, false)
        resolve(res.data)
      })
      .catch(err => {
        commit(LeaseMutations.SET_REQUEST_STATE_LEASE, err)
        commit(LeaseMutations.SET_IS_LOADING_LEASE, false)
      })
    })
  },

  /**
   * It creates a new lease.
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {LeaseRequestDto} lease - LeaseRequestDto
   */
  async createLease ({ commit, rootGetters }, lease: LeaseRequestDto): Promise<void> {
    commit(LeaseMutations.SET_IS_CREATING_LEASE, true)
    await axios
      .post(
        `${process.env.VUE_APP_API_ORISIS}Billing/Lease/CreateLease`,
        {...lease, workspaceId: rootGetters.workspaceSelected.id},
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {

        rootGetters.leasesList.push(res.data.data)
        commit(
          LeaseMutations.SET_LEASES_LIST,
          rootGetters.leasesList
        )
        commit(LeaseMutations.SET_REQUEST_STATE_LEASE, res)
        commit(LeaseMutations.SET_IS_CREATING_LEASE, false)
      })
      .catch(err => {
        commit(LeaseMutations.SET_REQUEST_STATE_LEASE, err)
        commit(LeaseMutations.SET_IS_CREATING_LEASE, false)
      })
  },

  /**
   * Update an lease
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {LeaseRequestDto} lease - LeaseRequestDto
   */
  async updateLease ({ commit, rootGetters }, lease: LeaseRequestDto) {
    commit(LeaseMutations.SET_IS_UPDATING_LEASE, true)
    await axios
      .put(
        `${process.env.VUE_APP_API_ORISIS}Billing/Lease/UpdateLease`,
        {...lease, workspaceId: rootGetters.workspaceSelected.id},
        
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.leasesList.splice(
          rootGetters.leasesList.findIndex(elem => elem.id == lease.id),
          1,
          lease
        )
        commit(
          LeaseMutations.SET_LEASES_LIST,
          rootGetters.leasesList
        )
        commit(LeaseMutations.SET_REQUEST_STATE_LEASE, res)
        commit(LeaseMutations.SET_IS_UPDATING_LEASE, false)
      })
      .catch(err => {
        commit(LeaseMutations.SET_REQUEST_STATE_LEASE, err)
        commit(LeaseMutations.SET_IS_UPDATING_LEASE, false)
      })
  },

  /**
   * It deletes an lease from the database.
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {string} leaseId - string
   */
  async deleteLease ({ commit, rootGetters }, leaseId: string) {
    commit(LeaseMutations.SET_IS_DELETING_LEASE, true)
    await axios
      .delete(`${process.env.VUE_APP_API_ORISIS}Billing/Lease/Delete`, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          leaseId: leaseId
        }
      })
      .then(res => {
        rootGetters.leasesList.splice(
          rootGetters.leasesList.findIndex(elem => elem.id == leaseId),
          1
        )
        commit(
          LeaseMutations.SET_LEASES_LIST,
          rootGetters.leasesList
        )
        commit(LeaseMutations.SET_REQUEST_STATE_LEASE, res)
        commit(LeaseMutations.SET_IS_DELETING_LEASE, false)
      })
      .catch(err => {
        commit(LeaseMutations.SET_REQUEST_STATE_LEASE, err)
        commit(LeaseMutations.SET_IS_DELETING_LEASE, false)
      })
  },

  /**
   * Delete a lease from the database
   * @param  - - `commit`: the commit function of the Vuex store.
   * @param {string} leaseId - The id of the lease to delete.
   */
  async archiveLease (
    { commit, rootGetters },
    leaseId: string
  ): Promise<void> {
    commit(LeaseMutations.SET_IS_ARCHIVING_LEASE, true)
    axios
      .delete(
        process.env.VUE_APP_API_ORISIS + 'Billing/Lease/Archive',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          data:[leaseId]
        }
      )
      .then(res => {
        rootGetters.leasesListArchived.unshift(rootGetters.leasesList.find(elem => elem.id == leaseId ))
        rootGetters.leasesList.splice(
          rootGetters.leasesList.findIndex(
            elem => elem.id == leaseId
          ),
          1
        )
        commit(
          LeaseMutations.SET_LEASES_LIST,
          rootGetters.leasesList
        )
        commit(LeaseMutations.SET_REQUEST_STATE_LEASE, res)
        commit(LeaseMutations.SET_IS_ARCHIVING_LEASE, false)
      })
      .catch(err => {
        console.error(err)
        commit(LeaseMutations.SET_REQUEST_STATE_LEASE, err)
        commit(LeaseMutations.SET_IS_ARCHIVING_LEASE, false)
      })
   },

  async restoreLease (
    { commit, rootGetters },
    leaseId: string
  ): Promise<void> {
    commit(LeaseMutations.SET_IS_UPDATING_LEASE, true)
    axios
      .put(
        process.env.VUE_APP_API_ORISIS + 'Billing/Lease/Restore',
        [leaseId],
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.leasesList.unshift(rootGetters.leasesListArchived.find(elem => elem.id == leaseId ))
        rootGetters.leasesListArchived.splice(
          rootGetters.leasesListArchived.findIndex(
            elem => elem.id == leaseId
          ),
          1
        )
        commit(LeaseMutations.SET_REQUEST_STATE_LEASE, res)
        commit(LeaseMutations.SET_IS_UPDATING_LEASE, false)
      })
      .catch(err => {
        console.error(err)
        commit(LeaseMutations.SET_REQUEST_STATE_LEASE, err)
        commit(LeaseMutations.SET_IS_UPDATING_LEASE, false)
      })
   }
}
