import { ProgrammedSmsRequestDto } from '@/../Api'
import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '../../RootState'
import { SmsMutations } from './mutations'
import { ISmsState } from './states'

export const actions: ActionTree<ISmsState, RootState> = {
  /**
   * It fetches the list of sms from the API and stores it in the state.
  */

   async fetchSmsList ({ commit, rootGetters }): Promise<void> {
    return new Promise<void>((resolve, reject) => { 
      commit(SmsMutations.SET_IS_LOADING_SMS_LIST, true)
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            'Plannings/Sms/GetHistorySms',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
            params: {
              workspaceId: rootGetters.workspaceSelected.id
            }
          }
        )
        .then(res => {
          commit(SmsMutations.SET_SMS_LIST, res.data)
          commit(SmsMutations.SET_REQUEST_STATE_SMS, res)
          commit(SmsMutations.SET_IS_LOADING_SMS_LIST, false)
          resolve(res.data)
          return res.data
        })
        .catch(err => {
          console.error(err)
          commit(SmsMutations.SET_REQUEST_STATE_SMS, err)
          commit(SmsMutations.SET_IS_LOADING_SMS_LIST, false)
        })
    })
  },

  async sendSms ({ commit, rootGetters, dispatch },  sms: ProgrammedSmsRequestDto): Promise<void> {
    return new Promise<void>((resolve, reject) => { 
      commit(SmsMutations.SET_IS_LOADING_SMS, true)
      axios
        .post(
          process.env.VUE_APP_API_ORISIS + 'Plannings/Sms/ProgrammedSms',
          { ...sms, workspaceId: rootGetters.workspaceSelected.id },
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            }
          }
        )
        .then(res => {

          dispatch('fetchSmsList')
          commit(SmsMutations.SET_IS_LOADING_SMS, false)
          commit(SmsMutations.SET_REQUEST_STATE_SMS, res)
          // that.$nextTick(() => {
          //   that.$bvToast.toast("Consultez l'historique de vos SMS depuis l'onglet 'Paramètres > Gestion des SMS'.", {
          //     title: `Votre SMS a été correctement programmé.`,
          //     variant: "success",
          //     solid: true,
          //   })
          // })
          resolve(res.data)
          return res.data
        })
        .catch(err => {
          console.error(err)
          commit(SmsMutations.SET_REQUEST_STATE_SMS, err)
          commit(SmsMutations.SET_IS_LOADING_SMS, false)
        })
    })
  },

}
