import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '../../RootState'
import { PlanningLineMutations } from './mutations'
import { IPlanningLinesState } from './states'
import { IFreeLine } from '@/types/api-orisis/interfaces/Planning/IFreeLine'

export const actions: ActionTree<IPlanningLinesState, RootState> = {
  /**
   * It fetches the list of planningLines from the API and stores it in the state.
   */

  async fetchPlanningLinesCollaboratorList ({ commit, rootGetters }): Promise<void> {
    return new Promise<void>((resolve, reject) => { 
      commit(PlanningLineMutations.SET_IS_LOADING_PLANNING_LINE_COLLABORATOR_LIST, true)
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            'Plannings/PlanningLine/GetPlanningLineCollaborator',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
            params: {
              workspaceId: rootGetters.workspaceSelected.id
            }
          }
        )
        .then(res => {
          commit(PlanningLineMutations.SET_PLANNING_LINES_COLLABORATOR_LIST, res.data)
          commit(PlanningLineMutations.SET_REQUEST_STATE_PLANNING_LINE, res)
          commit(PlanningLineMutations.SET_IS_LOADING_PLANNING_LINE_COLLABORATOR_LIST, false)
          resolve(res.data)
          return res.data
        })
        .catch(err => {
          console.error(err)
          commit(PlanningLineMutations.SET_REQUEST_STATE_PLANNING_LINE, err)
          commit(PlanningLineMutations.SET_IS_LOADING_PLANNING_LINE_COLLABORATOR_LIST, false)
        })
    })
  },
  async fetchPlanningLinesAffairList ({ commit, rootGetters }): Promise<void> {
    return new Promise<void>((resolve, reject) => { 
      commit(PlanningLineMutations.SET_IS_LOADING_PLANNING_LINE_AFFAIR_LIST, true)
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            'Plannings/PlanningLine/GetPlanningLineAffair',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
            params: {
              workspaceId: rootGetters.workspaceSelected.id
            }
          }
        )
        .then(res => {
          commit(PlanningLineMutations.SET_PLANNING_LINES_AFFAIR_LIST, res.data)
          commit(PlanningLineMutations.SET_REQUEST_STATE_PLANNING_LINE, res)
          commit(PlanningLineMutations.SET_IS_LOADING_PLANNING_LINE_AFFAIR_LIST, false)
          resolve(res.data)
          return res.data
        })
        .catch(err => {
          console.error(err)
          commit(PlanningLineMutations.SET_REQUEST_STATE_PLANNING_LINE, err)
          commit(PlanningLineMutations.SET_IS_LOADING_PLANNING_LINE_AFFAIR_LIST, false)
        })
    })
  },
  async fetchPlanningLinesFreeLineList ({ commit, rootGetters }): Promise<void> {
    return new Promise<void>((resolve, reject) => { 
      commit(PlanningLineMutations.SET_IS_LOADING_PLANNING_LINE_FREE_LINE_LIST, true)
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            'Plannings/PlanningLine/GetPlanningLineFreeLine',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
            params: {
              workspaceId: rootGetters.workspaceSelected.id
            }
          }
        )
        .then(res => {
          commit(PlanningLineMutations.SET_PLANNING_LINES_FREE_LINE_LIST, res.data)
          commit(PlanningLineMutations.SET_REQUEST_STATE_PLANNING_LINE, res)
          commit(PlanningLineMutations.SET_IS_LOADING_PLANNING_LINE_FREE_LINE_LIST, false)
          resolve(res.data)
          return res.data
        })
        .catch(err => {
          console.error(err)
          commit(PlanningLineMutations.SET_REQUEST_STATE_PLANNING_LINE, err)
          commit(PlanningLineMutations.SET_IS_LOADING_PLANNING_LINE_FREE_LINE_LIST, false)
        })
    })
  },


  /**
   * Fetch a planningLine from the API
   * @param  - planningLineId: The id of the planningLine you want to fetch.
   * @param {string} planningLineId - The id of the planningLine you want to fetch.
   */
  async fetchPlanningLine (
    { commit, rootGetters },
    planningLineId: string
  ): Promise<void> {
    return new Promise<void>((resolve, reject) =>{
      commit(PlanningLineMutations.SET_IS_LOADING_PLANNING_LINE, true)
    axios
      .get(
        process.env.VUE_APP_API_ORISIS +
          'Plannings/PlanningLine/GetPlanningLineById',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          params: {
            planningLineId: planningLineId
          }
        }
      )
      .then(res => {
        commit(PlanningLineMutations.SET_PLANNING_LINE, res.data)
        commit(PlanningLineMutations.SET_REQUEST_STATE_PLANNING_LINE, res)
        commit(PlanningLineMutations.SET_IS_LOADING_PLANNING_LINE, false)
        resolve(res.data)
      })
      .catch(err => {
        console.error(err)
        commit(PlanningLineMutations.SET_REQUEST_STATE_PLANNING_LINE, err)
        commit(PlanningLineMutations.SET_IS_LOADING_PLANNING_LINE, false)
      })
    })
  },

  /**
   * Create a planningLine and add it to the planningLines list
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {IPlanningLine} planningLine - The planningLine object that will be sent to the API.
   */
  async createPlanningLine (
    { commit, rootGetters },
    planningLine
  ): Promise<void> {
    return new Promise<void>((resolve, reject) =>{
    commit(PlanningLineMutations.SET_IS_CREATING_PLANNING_LINE, true)
    axios
      .post(
        process.env.VUE_APP_API_ORISIS +
          'Plannings/PlanningLine/CreateFreeLine',
        { ...planningLine, workspaceId: rootGetters.workspaceSelected.id },
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.planningLinesFreeLineList.unshift(res.data.data)
        commit(
          PlanningLineMutations.SET_PLANNING_LINES_FREE_LINE_LIST,
          rootGetters.planningLinesFreeLineList
        )
        commit(PlanningLineMutations.SET_REQUEST_STATE_PLANNING_LINE, res)
        commit(PlanningLineMutations.SET_IS_CREATING_PLANNING_LINE, false)
        resolve(res.data.data)
      })
      .catch(err => {
        console.error(err)
        commit(PlanningLineMutations.SET_REQUEST_STATE_PLANNING_LINE, err)
        commit(PlanningLineMutations.SET_IS_CREATING_PLANNING_LINE, false)
      })
    })
  },

  /**
   * Update a planningLine
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {IPlanningLine} planningLine - The planningLine object that will be updated.
   */
  
 

  async updatePlanningLine (
    { commit, rootGetters },
    planningLine:IFreeLine
  ): Promise<void> {
    commit(PlanningLineMutations.SET_IS_UPDATING_PLANNING_LINE, true)
    axios
      .put(
        process.env.VUE_APP_API_ORISIS +
          'Plannings/PlanningLine/UpdateFreeLine',
        {...planningLine, workspaceId: rootGetters.workspaceSelected.id},
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.planningLinesFreeLineList.splice(
          rootGetters.planningLinesFreeLineList.findIndex(
            elem => elem.id == planningLine.id
          ),
          1,
          planningLine
        )
        commit(
          PlanningLineMutations.SET_PLANNING_LINES_FREE_LINE_LIST,
          rootGetters.planningLinesFreeLineList
        )
        commit(PlanningLineMutations.SET_REQUEST_STATE_PLANNING_LINE, res)
        commit(PlanningLineMutations.SET_IS_UPDATING_PLANNING_LINE, false)
      })
      .catch(err => {
        console.error(err)
        commit(PlanningLineMutations.SET_REQUEST_STATE_PLANNING_LINE, err)
        commit(PlanningLineMutations.SET_IS_UPDATING_PLANNING_LINE, false)
      })
  }

}
