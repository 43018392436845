import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

// RouterTabRoutes
import { RouterTabRoutes } from 'vue-router-tab'
// layout component
import Frame from '@/layouts/Frame.vue'

import "material-icons/iconfont/material-icons.css"; //Material Icons

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      // parent component must contain <router-tab>
      component: Frame,
      // routes that serve as tab contents
      children: [
        // integrate RouterTabRoutes to support iframe tabs
        ...RouterTabRoutes,
        {
          path: '/',
          name: 'home',
          component: () => import('@/views/DashboardGestion.vue'),
          meta: {
            pageTitle: 'Accueil',
            breadcrumb: [
              {
                text: 'Accueil',
                active: false,
              },
            ],
            title: 'Accueil', 
            icon: 'icon-home', 
            tabClass: 'custom-tab', // custom class, optional
            tips: 'Tableau de bord principal',
            key: 'path', 
            closable: true,
          },
        },
        {
          path: '/dashboard-gestion',
          name: 'dashboard-gestion',
          component: () => import('@/views/DashboardGestion.vue'),
          meta: {
            pageTitle: 'Tableau de bord Gestion',
            breadcrumb: [
              {
                text: 'Tableau de bord Gestion',
                active: true,
              },
            ],
            title: 'Tableau de bord Gestion', 
            tips: 'Tableau de bord de gestion',
            key: 'path', 
            closable: true 
          },
        },
        {
          path: '/dashboard-analytics',
          name: 'dashboard-analytics',
          component: () => import('@/views/DashboardAnalytics.vue'),
          meta: {
            pageTitle: 'Tableau de bord Analytique',
            breadcrumb: [
              {
                text: 'Tableau de bord Analytique',
                active: true,
              },
            ],
            title: 'Tableau de bord Analytique', 
            tips: 'Tableau de bord analytique',
            key: 'path', 
            closable: true 
          },
        },


        /* *** ROUTES D'ADMINISTRATION GLOBALE */
        {
          path: '/admin/admin-users',
          name: 'adminUsers',
          component: () => import('@/views/account/users/AdminUsers.vue'),
          meta: {
            pageTitle: 'Gestion des utilisateurs',
            breadcrumb: [
              {
                text: 'Gestion des utilisateurs',
                active: true,
              },
            ],
            title: 'Utilisateurs', 
            icon: 'icon-home', 
            tabClass: 'custom-tab', // custom class, optional
            tips: 'Gestion des utilisateurs ORISIS',
            key: 'path', 
            closable: true,
            role: 'admin',
            closeOnSwitchWorkspace:true
          },
        },
        {
          path: '/admin/admin-workspaces',
          name: 'adminWorkspaces',
          component: () => import('@/views/account/workspace/Workspaces.vue'),
          meta: {
            pageTitle: 'Espaces de travail',
            breadcrumb: [
              {
                text: 'Espaces de travail',
                active: true,
              },
            ],
            title: 'Workspaces', 
            icon: 'icon-home', 
            tabClass: 'custom-tab', // custom class, optional
            tips: 'Gestion des bases de données des clients',
            key: 'path', 
            closable: true,
            role: 'admin',
            closeOnSwitchWorkspace:true
          },
        },

        /* *** Gestion du compte *** */
        {
          path: '/account',
          name: 'account',
          component: () => import('@/views/account/users/Account.vue'),
          meta: {
            pageTitle: 'Mon compte',
            breadcrumb: [
              {
                text: 'Mon compte',
                active: true,
              },
            ],
            title: 'Mon compte', 
            icon: 'icon-user', 
            tips: 'Gestion de mon compte ORISIS',
            key: 'path', 
            closable: true 
          },
        },

        /* *** Gestion des Users *** */
        {
          path: '/users',
          name: 'users',
          component: () => import('@/views/directory/user/Users.vue'),
          meta: {
            pageTitle: 'Utilisateurs',
            breadcrumb: [
              {
                text: 'Utilisateurs',
                active: true,
              },
            ],
            title: 'Utilisateurs', 
            icon: 'icon-users', 
            tips: 'Liste des utilisateurs',
            key: 'path', 
            closable: true,
            role: 'admin'
          },
        },
        {
          path: '/new-user',
          name: 'new-user',
          component: () => import('@/views/directory/user/EditUser.vue'),
          props: true,
          meta: {
            pageTitle: 'Nouvel utilisateur',
            breadcrumb: [
              {
                text: 'Utilisateurs',
                active: true,
              },
              {
                text: 'Editer',
                active: true,
              },
            ],
            title: 'Nouvel utilisateur', 
            icon: 'icon-home', 
            tips: 'Créer un nouveau utilisateur',
            key: 'path', 
            closable: true,
            role: 'admin',
            closeOnSwitchWorkspace:true
          },
        },
        {
          path: '/edit-user/:id',
          name: 'edit-user',
          component: () => import('@/views/directory/user/EditUser.vue'),
          props: true,
          meta: {
            pageTitle: 'Fiche utilisateur',
            breadcrumb: [
              {
                text: 'Utilisateurs',
                active: true,
              },
              {
                text: 'Editer',
                active: true,
              },
            ],
            title: 'Editer un utilisateur', 
            icon: 'icon-home', 
            tips: 'Editer un utilisateur',
            key: 'path', 
            closable: true,
            role: 'admin',
            closeOnSwitchWorkspace:true
          },
        },

        /* *** Gestion des Collaborateurs *** */
        {
          path: '/collaborators',
          name: 'collaborators',
          component: () => import('@/views/directory/collaborator/Collaborators.vue'),
          meta: {
            pageTitle: 'Collaborateurs',
            breadcrumb: [
              {
                text: 'Collaborateurs',
                active: true,
              },
            ],
            title: 'Collaborateurs', 
            icon: 'icon-users', 
            tips: 'Liste des collaborateurs',
            key: 'path', 
            closable: true 
          },
        },
        {
          path: '/new-collaborator',
          name: 'new-collaborator',
          component: () => import('@/views/directory/collaborator/EditCollaborator.vue'),
          props: true,
          meta: {
            pageTitle: 'Nouveau collaborateur',
            breadcrumb: [
              {
                text: 'Collaborateurs',
                active: true,
              },
              {
                text: 'Editer',
                active: true,
              },
            ],
            title: 'Nouveau collaborateur', 
            icon: 'icon-home', 
            tips: 'Créer un nouveau collaborateur',
            key: 'path', 
            closable: true,
            closeOnSwitchWorkspace:true
          },
        },
        {
          path: '/edit-collaborator/:id',
          name: 'edit-collaborator',
          component: () => import('@/views/directory/collaborator/EditCollaborator.vue'),
          props: true,
          meta: {
            pageTitle: 'Fiche collaborateur',
            breadcrumb: [
              {
                text: 'Collaborateurs',
                active: true,
              },
              {
                text: 'Editer',
                active: true,
              },
            ],
            title: 'Editer un collaborateur', 
            icon: 'icon-home', 
            tips: 'Editer un collaborateur',
            key: 'path', 
            closable: true,
            closeOnSwitchWorkspace:true
          },
        },

        /* *** Gestion des Affaires *** */
         {
          path: '/affairs',
          name: 'affairs',
          component: () => import('@/views/directory/affair/Affairs.vue'),
          meta: {
            pageTitle: 'Affaires',
            breadcrumb: [
              {
                text: 'Affaires',
                active: true,
              },
            ],
            title: 'Affaires', 
            icon: 'icon-users', 
            tips: 'Liste des affaires',
            key: 'path', 
            closable: true 
          },
        },
        {
          path: '/new-affair',
          name: 'new-affair',
          component: () => import('@/views/directory/affair/EditAffair.vue'),
          props: true,
          meta: {
            pageTitle: 'Nouvelle affaire',
            breadcrumb: [
              {
                text: 'Affaires',
                active: true,
              },
              {
                text: 'Editer',
                active: true,
              },
            ],
            title: 'Nouvelle affaire', 
            icon: 'icon-home', 
            tips: 'Créer une nouvelle affaire',
            key: 'path', 
            closable: true,
            closeOnSwitchWorkspace:true
          },
        },
        {
          path: '/edit-affair/:id',
          name: 'edit-affair',
          component: () => import('@/views/directory/affair/EditAffair.vue'),
          props: true,
          meta: {
            pageTitle: 'Fiche affaire',
            breadcrumb: [
              {
                text: 'Affaires',
                active: true,
              },
              {
                text: 'Editer',
                active: true,
              },
            ],
            title: 'Editer une affaire', 
            icon: 'icon-home', 
            tips: 'Editer une affaire',
            key: 'path', 
            closable: true,
            closeOnSwitchWorkspace:true
          },
        },

        /* *** Gestion des Tiers *** */
        {
          path: '/directory/third/:name/:id',
          name: 'third',
          component: () => import('@/views/directory/third/Third.vue'),
          meta: {
            pageTitle: 'Tiers',
            breadcrumb: [
              {
                text: 'Tiers',
                active: true,
              },
            ],
            title: 'Tiers',
            icon: 'icon-home', 
            tips: 'Liste des tiers',
            key: 'path', 
            closable: true 
          },
        },
        {
          path: '/directory/new-third/:name/:companyTypeId',
          name: 'new-third',
          component: () => import('@/views/directory/third/EditThird.vue'),
          props: true,
          meta: {
            pageTitle: 'Nouveau tier',
            breadcrumb: [
              {
                text: 'Tiers',
                active: true,
              },
              {
                text: 'Editer',
                active: true,
              },
            ],
            title: 'Nouveau tier', 
            icon: 'icon-home', 
            tips: 'Créer un nouveau tier',
            key: 'path', 
            closable: true,
            closeOnSwitchWorkspace:true
          },
        },
        {
          path: '/directory/edit-third/:id',
          name: 'edit-company',
          component: () => import('@/views/directory/third/EditThird.vue'),
          props: true,
          meta: {
            pageTitle: 'Fiche',
            breadcrumb: [
              {
                text: 'Tiers',
                active: true,
              },
              {
                text: 'Editer',
                active: true,
              },
            ],
            title: 'Editer une fiche', 
            icon: 'icon-home', 
            tips: 'Editer une fiche',
            key: 'path', 
            closable: true,
            closeOnSwitchWorkspace:true
          },
        },

        /* *** Gestion des Contacts *** */
        {
          path: '/directory/contacts',
          name: 'contacts',
          component: () => import('@/views/directory/contact/Contacts.vue'),
          meta: {
            pageTitle: 'Contacts',
            breadcrumb: [
              {
                text: 'Contacts',
                active: true,
              },
            ],
            title: 'Contacts', 
            icon: 'icon-users', 
            tabClass: 'custom-tab', // custom class, optional
            tips: 'Liste des contacts',
            key: 'path', 
            closable: true
          },
        },
        {
          path: '/directory/new-contact',
          name: 'new-contact',
          component: () => import('@/views/directory/contact/EditContact.vue'),
          props: true,
          meta: {
            pageTitle: 'Nouveau contact',
            breadcrumb: [
              {
                text: 'Contacts',
                active: true,
              },
              {
                text: 'Editer',
                active: true,
              },
            ],
            title: 'Nouveau contact', 
            icon: 'icon-home', 
            tips: 'Créer un nouveau contact',
            key: 'path', 
            closable: true,
            closeOnSwitchWorkspace:true
          },
        },
        {
          path: '/directory/edit-contact/:id',
          name: 'edit-contact',
          component: () => import('@/views/directory/contact/EditContact.vue'),
          props: true,
          meta: {
            pageTitle: 'Fiche contact',
            breadcrumb: [
              {
                text: 'Contacts',
                active: true,
              },
              {
                text: 'Editer',
                active: true,
              },
            ],
            title: 'Editer un contact', 
            icon: 'icon-home', 
            tips: 'Editer un contact',
            key: 'path', 
            closable: true,
            closeOnSwitchWorkspace:true
          },
        },


        /* *** Gestion des Plannings *** */
        {
          path: '/planning/:id',
          name: 'planning',
          props: true,
          component: () => import('@/views/Bryntum.vue'),
          meta: {
            pageTitle: 'Planning',
            breadcrumb: [
              {
                text: 'Planning',
                active: true,
              },
            ],

            title: 'Planning', 
            icon: 'icon-home', 
            tips: 'Gestionnaire de planning',
            key: 'path', 
            closable: true,
            closeOnSwitchWorkspace:true
          },
        },
        {
          path: '/planning/settings/event-types',
          name: 'eventTypes',
          component: () => import('@/views/planning/settings/EventTypes.vue'),
          meta: {
            pageTitle: 'Types d\'événement',
            breadcrumb: [
              {
                text: 'Types d\'événement',
                active: true,
              },
            ],
            title: 'Types d\'événement de planning', 
            icon: 'icon-home', 
            tabClass: 'custom-tab', // custom class, optional
            tips: 'Liste des types d\'événements',
            key: 'path', 
            closable: true 
          },
        },
        {
          path: '/planning/settings/planning-views',
          name: 'planningViews',
          component: () => import('@/views/planning/settings/PlanningViews.vue'), 
          meta: {
            pageTitle: 'Gestion des plannings',
            breadcrumb: [
              {
                text: 'Vues de planning',
                active: true,
              },
            ],
            title: 'Vues de planning', 
            icon: 'icon-home', 
            tabClass: 'custom-tab', // custom class, optional
            tips: 'Liste des vues de planning',
            key: 'path', 
            closable: true 
          },
        },
        {
          path: '/planning/settings/new-planning-view',
          name: 'newPlanningView',
          component: () => import('@/views/planning/settings/EditPlanningView.vue'),
          props: true,
          meta: {
            pageTitle: 'Nouveau planning',
            breadcrumb: [
              {
                text: 'Nouveau planning',
                active: true,
              },
              {
                text: 'Editer',
                active: true,
              },
            ],
            title: 'Nouveau planning', 
            icon: 'icon-home', 
            tabClass: 'custom-tab', // custom class, optional
            tips: 'Créer un nouveau planning',
            key: 'path', 
            closable: true,
            closeOnSwitchWorkspace:true 
          },
        },
        {
          path: '/planning/settings/edit-planning-view/:id',
          name: 'edit-planning-view',
          component: () => import('@/views/planning/settings/EditPlanningView.vue'),
          props: true,
          meta: {
            pageTitle: 'Planning',
            breadcrumb: [
              {
                text: 'Planning',
                active: true,
              },
              {
                text: 'Editer',
                active: true,
              },
            ],
            title: 'Editer un planning', 
            icon: 'icon-home', 
            tabClass: 'custom-tab', // custom class, optional
            tips: 'Editer un planning',
            key: 'path', 
            closable: true,
            closeOnSwitchWorkspace:true
          },
        },

        {
          path: '/planning/settings/sms',
          name: 'sms',
          component: () => import('@/views/planning/settings/Sms.vue'),
          meta: {
            pageTitle: 'Historique des SMS',
            breadcrumb: [
              {
                text: 'SMS',
                active: true,
              },
            ],
            title: 'SMS', 
            icon: 'icon-home', 
            tabClass: 'custom-tab', // custom class, optional
            tips: 'Historique des SMS',
            key: 'path', 
            closable: true 
          },
        },


        /* *** Gestion des Paramètres Divers *** */
        {
          path: '/parameters/contracts',
          name: 'contracts',
          component: () => import('@/views/parameters/contracts/Contracts.vue'),
          meta: {
            pageTitle: 'Contrats',
            breadcrumb: [
              {
                text: 'Contrats',
                active: true,
              },
            ],
            title: 'Contrats', 
            icon: 'icon-users', 
            tabClass: 'custom-tab', // custom class, optional
            tips: 'Liste des contrats',
            key: 'path', 
            closable: true
          },
        },
        {
          path: '/parameters/jobs',
          name: 'jobs',
          component: () => import('@/views/parameters/jobs/Jobs.vue'),
          meta: {
            pageTitle: 'Métiers',
            breadcrumb: [
              {
                text: 'Métiers',
                active: true,
              },
            ],
            title: 'Métiers', 
            icon: 'icon-users', 
            tabClass: 'custom-tab', // custom class, optional
            tips: 'Liste des métiers',
            key: 'path', 
            closable: true
          },
        },

        {
          path: '/billing/periodicities',
          name: 'periodicities',
          component: () => import('@/views/billing/periodicity/Periodicities.vue'),
          meta: {
            pageTitle: 'Périodicités',
            breadcrumb: [
              {
                text: 'Périodicités',
                active: true,
              },
            ],
            title: 'Périodicités', 
            icon: 'icon-users', 
            tabClass: 'custom-tab', // custom class, optional
            tips: 'Liste des périodicités',
            key: 'path', 
            closable: true
          },
        },
        {
          path: '/billing/lease-types',
          name: 'lease-types',
          component: () => import('@/views/billing/lease-type/LeaseTypes.vue'),
          meta: {
            pageTitle: 'Types de baux',
            breadcrumb: [
              {
                text: 'Types de baux',
                active: true,
              },
            ],
            title: 'Types de baux', 
            icon: 'icon-users', 
            tabClass: 'custom-tab', // custom class, optional
            tips: 'Liste des types de baux',
            key: 'path', 
            closable: true
          },
        },
        {
          path: '/billing/property-types',
          name: 'property-types',
          component: () => import('@/views/billing/property-type/PropertyTypes.vue'),
          meta: {
            pageTitle: 'Types de biens',
            breadcrumb: [
              {
                text: 'Types de biens',
                active: true,
              },
            ],
            title: 'Types de biens', 
            icon: 'icon-users', 
            tabClass: 'custom-tab', // custom class, optional
            tips: 'Liste des types de biens',
            key: 'path', 
            closable: true
          },
        },




        /* *** ENVIRONNEMENET *** 
        ******** LHOTELLIE ******
        Gestion des Affaires ****/
        {
          path: '/leases',
          name: 'leases',
          component: () => import('@/views/billing/lease/Leases.vue'),
          meta: {
            pageTitle: 'Baux',
            breadcrumb: [
              {
                text: 'Baux',
                active: true,
              },
            ],
            title: 'Baux', 
            icon: 'icon-users', 
            tips: 'Liste des baux',
            key: 'path', 
            closable: true 
          },
        },
        {
          path: '/new-lease',
          name: 'new-lease',
          component: () => import('@/views/billing/lease/EditLease.vue'),
          props: true,
          meta: {
            pageTitle: 'Nouveau bail',
            breadcrumb: [
              {
                text: 'Baux',
                active: true,
              },
              {
                text: 'Editer',
                active: true,
              },
            ],
            title: 'Nouveau bail', 
            icon: 'icon-home', 
            tips: 'Créer un nouveau bail',
            key: 'path', 
            closable: true,
            closeOnSwitchWorkspace:true
          },
        },
        {
          path: '/edit-lease/:id',
          name: 'edit-lease',
          component: () => import('@/views/billing/lease/EditLease.vue'),
          props: true,
          meta: {
            pageTitle: 'Fiche bail',
            breadcrumb: [
              {
                text: 'Baux',
                active: true,
              },
              {
                text: 'Editer',
                active: true,
              },
            ],
            title: 'Editer un bail', 
            icon: 'icon-home', 
            tips: 'Editer un bail',
            key: 'path', 
            closable: true,
            closeOnSwitchWorkspace:true
          },
        },
        /* *** ENVIRONNEMENET *** 
        ******** LHOTELLIE ******
        Gestion des biens ****/
        {
          path: '/properties',
          name: 'properties',
          component: () => import('@/views/billing/property/Properties.vue'),
          meta: {
            pageTitle: 'Biens',
            breadcrumb: [
              {
                text: 'Biens',
                active: true,
              },
            ],
            title: 'Biens', 
            icon: 'icon-users', 
            tips: 'Liste des biens',
            key: 'path', 
            closable: true 
          },
        },
        {
          path: '/new-property',
          name: 'new-property',
          component: () => import('@/views/billing/property/EditProperty.vue'),
          props: true,
          meta: {
            pageTitle: 'Nouveau bien',
            breadcrumb: [
              {
                text: 'Baux',
                active: true,
              },
              {
                text: 'Editer',
                active: true,
              },
            ],
            title: 'Nouveau bien', 
            icon: 'icon-home', 
            tips: 'Créer un nouveau bien',
            key: 'path', 
            closable: true,
            closeOnSwitchWorkspace:true
          },
        },
        {
          path: '/edit-property/:id',
          name: 'edit-property',
          component: () => import('@/views/billing/property/EditProperty.vue'),
          props: true,
          meta: {
            pageTitle: 'Fiche bien',
            breadcrumb: [
              {
                text: 'Biens',
                active: true,
              },
              {
                text: 'Editer',
                active: true,
              },
            ],
            title: 'Editer un bien', 
            icon: 'icon-home', 
            tips: 'Editer un bien',
            key: 'path', 
            closable: true,
            closeOnSwitchWorkspace:true
          },
        },



      ],
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/authentification/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgotPassword',
      component: () => import('@/views/authentification/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/change-password/:origine',
      name: 'changePassword',
      component: () => import('@/views/authentification/ChangePassword.vue'),
      props: true,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reset-password/:userEmail/:userCode*',
      name: 'resetPassword',
      component: () => import('@/views/authentification/ResetPassword.vue'),
      props: true,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/first-login/:userCode*',
      name: 'firstLogin',
      component: () => import('@/views/authentification/FirstLogin.vue'),
      props: true,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-401',
      name: 'error401',
      component: () => import('@/views/error/Error401.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'login',
    },]
})
  

router.beforeEach(async (to, from, next) => {
  console.log(">> navigate to >>", to)
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'flex'
  }

  const checkToken = () => {
    if ((localStorage.getItem('userToken') == null) 
    || (localStorage.getItem('userToken') == "null") 
    || (localStorage.getItem('userToken') == undefined) 
    || (localStorage.getItem('userIsLoggedIn') == false) 
    || (localStorage.getItem('userIsLoggedIn') == "false") 
    || (localStorage.getItem('userIsLoggedIn') == undefined)) {
      return false
     } else {
       return true
     }
  }
  
  // Liste des URL disponibles sans authentification
  const publicPagesName = ['login', 'register','firstLogin','forgotPassword','resetPassword','firstLogin','error401','error404'];
  const authRequired =  !publicPagesName.includes(to.name); 
  if (authRequired){
    if (checkToken()) {
      //console.log("checkToken > userInfo", store.getters.userInfo)

      if(store.getters.userInfo){ 
        let workspaceSelectedId = 0
        if(localStorage.getItem('workspaceSelected') && localStorage.getItem('workspaceSelected')!==null){
          workspaceSelectedId = JSON.parse(localStorage.getItem('workspaceSelected')).id
          //console.log("localStorage.getItem('workspaceSelected')",workspaceSelectedId)
        }else{
          workspaceSelectedId = store.getters.userInfo.workspaceIds[0]
          //console.log("store.getters.userInfo.workspaceIds[0]", store.getters.userInfo.workspaceIds[0])
        }
        //console.log("workspaceSelectedId", workspaceSelectedId, from)
        if(!from.name || publicPagesName.includes(from.name) || from.name==null){
          //console.log("OH dispatch setWorkspaceSelected")
          await store.dispatch('setWorkspaceSelected', { workspaceSelectedId:workspaceSelectedId, userRoles:store.getters.userInfo.roles});
        }
        if(to.name=='planning'){ 
          await store.dispatch("fetchPlanningViewsList", { workspaceSelectedId:workspaceSelectedId}).then(res => {
            const dataPlanning = store.getters.planningViewsList.find(el => el.id == to.params.id)
            //console.log(workspaceSelectedId, res, dataPlanning,  to.params.id)
            to.params.tips = "Planning : "+dataPlanning.name
            to.params.title = "Planning : "+dataPlanning.name
          })
        }

        // Gestion des titre de tabs dynamiquement
        if(to.params && to.params.title){ 
          to.meta.title = to.params.title
          to.meta.tips = to.params.tips ? to.params.tips : to.params.title
        }else if(to.params && to.params.name){
          if(to.path.includes('new-')){
            to.meta.title = 'Nouveau '+to.params.name
            to.meta.pageTitle = 'Nouveau '+to.params.name
            to.meta.tips = 'Créer un nouveau ' + to.params.name
          }else{
            if(to.path.includes("directory/third")){
              await store.dispatch('fetchCompaniesTypesList')
              to.meta.title = to.params.name[0].toUpperCase() + to.params.name.slice(1)
              to.meta.pageTitle = to.params.name[0].toUpperCase() + to.params.name.slice(1)
              to.meta.tips = 'Liste des ' + to.params.name
            }else{

              to.meta.title = to.params.name[0].toUpperCase() + to.params.name.slice(1)
              to.meta.pageTitle = to.params.name[0].toUpperCase() + to.params.name.slice(1)
              to.meta.tips = 'Liste des ' + to.params.name + 's'
            }
          }
        }

        // Gestion de l'interdiction d'accès au pages administrateur
        if(to.meta?.role && to.meta?.role=="admin" && !JSON.parse(localStorage.getItem('userInfo')).roles.includes("Admin")){
          return next('/error-401');
        }else{
          next()
        }


      }else{
        return next('/login');
      }

      
    } else {
      return next('/login');
    }
  }else{
    next()
  }

})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
