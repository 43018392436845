import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { PeriodicityRequestDto } from '@/../Api'
import { MutationTree } from "vuex";
import { IPeriodicityState } from "./states";

export enum PeriodicityMutations {
    SET_PERIODICITIES_LIST = "SET_PERIODICITIES_LIST",
    SET_PERIODICITIES_LIST_ARCHIVED = "SET_PERIODICITIES_LIST_ARCHIVED",
    SET_PERIODICITY = "SET_PERIODICITY",

    // COMMON
    SET_IS_LOADING_PERIODICITY_LIST = "SET_IS_LOADING_PERIODICITY_LIST",
    SET_IS_LOADING_PERIODICITY_LIST_ARCHIVED = "SET_IS_LOADING_PERIODICITY_LIST_ARCHIVED",
    SET_IS_LOADING_PERIODICITY = "SET_IS_LOADING_PERIODICITY",
    SET_IS_CREATING_PERIODICITY = "SET_IS_CREATING_PERIODICITY",
    SET_IS_UPDATING_PERIODICITY = "SET_IS_UPDATING_PERIODICITY",
    SET_IS_DELETING_PERIODICITY = "SET_IS_DELETING_PERIODICITY",
    SET_IS_ARCHIVING_PERIODICITY = "SET_IS_DELETING_PERIODICITY",
    SET_REQUEST_STATE_PERIODICITY = "SET_REQUEST_STATE_PERIODICITY",
    SET_IS_OPEN_SIDEBAR_PERIODICITY = "SET_IS_OPEN_SIDEBAR_PERIODICITY"
}

export const mutations: MutationTree<IPeriodicityState> = {
    [PeriodicityMutations.SET_PERIODICITIES_LIST_ARCHIVED]: (state, payload: PeriodicityRequestDto[]) => {
        state.periodicitiesListArchived = payload;
    }, 
    [PeriodicityMutations.SET_PERIODICITIES_LIST]: (state, payload: PeriodicityRequestDto[]) => {
        state.periodicitiesList = payload;
    },
    [PeriodicityMutations.SET_PERIODICITY]: (state, payload: PeriodicityRequestDto) => {
        state.periodicity = payload;
    },


    //COMMON
    [PeriodicityMutations.SET_IS_LOADING_PERIODICITY_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingPeriodicitiesListArchived = payload;
    },
    [PeriodicityMutations.SET_IS_LOADING_PERIODICITY_LIST]: (state, payload: boolean) => {
        state.isLoadingPeriodicitiesList = payload;
    },
    [PeriodicityMutations.SET_IS_LOADING_PERIODICITY]: (state, payload: boolean) => {
        state.isLoadingPeriodicity = payload;
    },
    [PeriodicityMutations.SET_IS_CREATING_PERIODICITY]: (state, payload: boolean) => {
        state.isCreatingPeriodicity = payload;
    },
    [PeriodicityMutations.SET_IS_UPDATING_PERIODICITY]: (state, payload: boolean) => {
        state.isUpdatingPeriodicity = payload;
    },
    [PeriodicityMutations.SET_IS_DELETING_PERIODICITY]: (state, payload: boolean) => {
        state.isDeletingPeriodicity = payload;
    },
    [PeriodicityMutations.SET_IS_ARCHIVING_PERIODICITY]: (state, payload: boolean) => {
        state.isArchivingPeriodicity = payload;
    },
    [PeriodicityMutations.SET_REQUEST_STATE_PERIODICITY]: (state, payload: IRequestState) => {
        state.requestStatePeriodicity = payload;
    },
    [PeriodicityMutations.SET_IS_OPEN_SIDEBAR_PERIODICITY]: (state, payload: boolean) => {
        state.isOpenSidebarPeriodicity = payload;
    },
}