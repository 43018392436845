import { AffairRequestDto } from '@/../Api'
import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '../../RootState'
import { AffairMutations } from './mutations'
import { IAffairState } from './states'

export const actions: ActionTree<IAffairState, RootState> = {
  /**
   * It fetches the list of affairs from the API.
   * @param  - **commit**: The commit function is used to send mutations to the store.
   */
  async fetchAffairsList ({ commit, rootGetters }): Promise<void> {
   return new Promise<void>(async (resolve, reject) => {
    commit(AffairMutations.SET_IS_LOADING_AFFAIR_LIST, true)
    let url = `${process.env.VUE_APP_API_ORISIS}Directory/Affair/GetAffairByWorkspaceId`
    await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          workspaceId: rootGetters.workspaceSelected.id
        }
      })
      .then(res => {
        commit(AffairMutations.SET_IS_LOADING_AFFAIR_LIST, false)
        commit(AffairMutations.SET_AFFAIRS_LIST, res.data)
        commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, res)
        resolve(res.data)
      })
      .catch(err => {
        console.error(err)
        commit(AffairMutations.SET_IS_LOADING_AFFAIR_LIST, false)
        commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, err)
      })
   })
  },
  async fetchAffairsListArchived ({ commit, rootGetters }): Promise<void> {
   return new Promise<void>(async (resolve, reject) => {
    commit(AffairMutations.SET_IS_LOADING_AFFAIR_LIST_ARCHIVED, true)
    let url = `${process.env.VUE_APP_API_ORISIS}Directory/Affair/GetAffairArchivedByWorkspaceId`
    await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          workspaceId: rootGetters.workspaceSelected.id
        }
      })
      .then(res => {
        commit(AffairMutations.SET_IS_LOADING_AFFAIR_LIST_ARCHIVED, false)
        commit(AffairMutations.SET_AFFAIRS_LIST_ARCHIVED, res.data)
        commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, res)
        resolve(res.data)
      })
      .catch(err => {
        console.error(err)
        commit(AffairMutations.SET_IS_LOADING_AFFAIR_LIST_ARCHIVED, false)
        commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, err)
      })
   })
  },

  /* Fetching the affair data from the API. */
  async fetchAffair ({ commit, rootGetters }, affairId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(AffairMutations.SET_IS_LOADING_AFFAIR, true)
    axios
      .get(`${process.env.VUE_APP_API_ORISIS}Directory/Affair/GetAffairById`, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          affairId: affairId
        }
      })
      .then(res => {
        commit(AffairMutations.SET_AFFAIR, res.data)
        commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, res)
        commit(AffairMutations.SET_IS_LOADING_AFFAIR, false)
        resolve(res.data)
      })
      .catch(err => {
        commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, err)
        commit(AffairMutations.SET_IS_LOADING_AFFAIR, false)
      })
    })
  },

  async fetchAffairArchived (
    { commit, rootGetters },
    affairId: string
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(AffairMutations.SET_IS_LOADING_AFFAIR, true)
    axios
      .get(`${process.env.VUE_APP_API_ORISIS}Directory/Affair/GetAffairArchivedById`, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          affairId: affairId
        }
      })
      .then(res => {
        commit(AffairMutations.SET_AFFAIR, res.data)
        commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, res)
        commit(AffairMutations.SET_IS_LOADING_AFFAIR, false)
        resolve(res.data)
      })
      .catch(err => {
        commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, err)
        commit(AffairMutations.SET_IS_LOADING_AFFAIR, false)
      })
    })
  },

  /**
   * It creates a new affair.
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {AffairRequestDto} affair - AffairRequestDto
   */
  async createAffair ({ commit, rootGetters }, affair: AffairRequestDto): Promise<void> {
    commit(AffairMutations.SET_IS_CREATING_AFFAIR, true)
    await axios
      .post(
        `${process.env.VUE_APP_API_ORISIS}Directory/Affair/CreateAffair`,
        {...affair, workspaceId: rootGetters.workspaceSelected.id},
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {

        rootGetters.affairsList.push(res.data.data)
        commit(
          AffairMutations.SET_AFFAIRS_LIST,
          rootGetters.affairsList
        )
        commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, res)
        commit(AffairMutations.SET_IS_CREATING_AFFAIR, false)
      })
      .catch(err => {
        commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, err)
        commit(AffairMutations.SET_IS_CREATING_AFFAIR, false)
      })
  },

  /**
   * Update an affair
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {AffairRequestDto} affair - AffairRequestDto
   */
  async updateAffair ({ commit, rootGetters }, affair: AffairRequestDto) {
    commit(AffairMutations.SET_IS_UPDATING_AFFAIR, true)
    await axios
      .put(
        `${process.env.VUE_APP_API_ORISIS}Directory/Affair/UpdateAffair`,
        {...affair, workspaceId: rootGetters.workspaceSelected.id},
        
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.affairsList.splice(
          rootGetters.affairsList.findIndex(elem => elem.id == affair.id),
          1,
          affair
        )
        commit(
          AffairMutations.SET_AFFAIRS_LIST,
          rootGetters.affairsList
        )
        commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, res)
        commit(AffairMutations.SET_IS_UPDATING_AFFAIR, false)
      })
      .catch(err => {
        commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, err)
        commit(AffairMutations.SET_IS_UPDATING_AFFAIR, false)
      })
  },

  /**
   * It deletes an affair from the database.
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {string} affairId - string
   */
  async deleteAffair ({ commit, rootGetters }, affairId: string) {
    commit(AffairMutations.SET_IS_DELETING_AFFAIR, true)
    await axios
      .delete(`${process.env.VUE_APP_API_ORISIS}Directory/Affair/Delete`, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          affairId: affairId
        }
      })
      .then(res => {
        rootGetters.affairsList.splice(
          rootGetters.affairsList.findIndex(elem => elem.id == affairId),
          1
        )
        commit(
          AffairMutations.SET_AFFAIRS_LIST,
          rootGetters.affairsList
        )
        commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, res)
        commit(AffairMutations.SET_IS_DELETING_AFFAIR, false)
      })
      .catch(err => {
        commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, err)
        commit(AffairMutations.SET_IS_DELETING_AFFAIR, false)
      })
  },

  /**
   * Delete a affair from the database
   * @param  - - `commit`: the commit function of the Vuex store.
   * @param {string} affairId - The id of the affair to delete.
   */
  async archiveAffair (
    { commit, rootGetters },
    affairId: string
  ): Promise<void> {
    commit(AffairMutations.SET_IS_ARCHIVING_AFFAIR, true)
    axios
      .delete(
        process.env.VUE_APP_API_ORISIS + 'Directory/Affair/Archive',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          data:[affairId]
        }
      )
      .then(res => {
        rootGetters.affairsListArchived.unshift(rootGetters.affairsList.find(elem => elem.id == affairId ))
        rootGetters.affairsList.splice(
          rootGetters.affairsList.findIndex(
            elem => elem.id == affairId
          ),
          1
        )
        commit(
          AffairMutations.SET_AFFAIRS_LIST,
          rootGetters.affairsList
        )
        commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, res)
        commit(AffairMutations.SET_IS_ARCHIVING_AFFAIR, false)
      })
      .catch(err => {
        console.error(err)
        commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, err)
        commit(AffairMutations.SET_IS_ARCHIVING_AFFAIR, false)
      })
   },

  async restoreAffair (
    { commit, rootGetters },
    affairId: string
  ): Promise<void> {
    commit(AffairMutations.SET_IS_UPDATING_AFFAIR, true)
    axios
      .put(
        process.env.VUE_APP_API_ORISIS + 'Directory/Affair/Restore',
        [affairId],
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.affairsList.unshift(rootGetters.affairsListArchived.find(elem => elem.id == affairId ))
        rootGetters.affairsListArchived.splice(
          rootGetters.affairsListArchived.findIndex(
            elem => elem.id == affairId
          ),
          1
        )
        commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, res)
        commit(AffairMutations.SET_IS_UPDATING_AFFAIR, false)
      })
      .catch(err => {
        console.error(err)
        commit(AffairMutations.SET_REQUEST_STATE_AFFAIR, err)
        commit(AffairMutations.SET_IS_UPDATING_AFFAIR, false)
      })
   }
}
