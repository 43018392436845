import { PropertyRequestDto } from '@/../Api'

import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '../../RootState'
import { PropertyMutations } from './mutations'
import { IPropertyState } from './states'

export const actions: ActionTree<IPropertyState, RootState> = {
  /**
   * It fetches the list of properties from the API and stores it in the state.
   */
  async fetchPropertiesList ({ commit, rootGetters }): Promise<void> {
    commit(PropertyMutations.SET_IS_LOADING_PROPERTIES_LIST, true)
    axios
      .get(
        process.env.VUE_APP_API_ORISIS +
          'Billing/Property/GetPropertyByWorkspaceId',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          params: {
            workspaceId: rootGetters.workspaceSelected.id
          }
        }
      )
      .then(res => {
        commit(PropertyMutations.SET_PROPERTIES_LIST, res.data)
        commit(PropertyMutations.SET_REQUEST_STATE_PROPERTY, res)
        commit(PropertyMutations.SET_IS_LOADING_PROPERTIES_LIST, false)
      })
      .catch(err => {
        console.error(err)
        commit(PropertyMutations.SET_REQUEST_STATE_PROPERTY, err)
        commit(PropertyMutations.SET_IS_LOADING_PROPERTIES_LIST, false)
      })
  },
  async fetchPropertiesListArchived ({ commit, rootGetters }): Promise<void> {
    commit(PropertyMutations.SET_IS_LOADING_PROPERTIES_LIST_ARCHIVED, true)
    axios
      .get(
        process.env.VUE_APP_API_ORISIS +
          'Billing/Property/GetPropertyArchivedByWorkspaceId',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          params: {
            workspaceId: rootGetters.workspaceSelected.id
          }
        }
      )
      .then(res => {
        commit(PropertyMutations.SET_PROPERTIES_LIST_ARCHIVED, res.data)
        commit(PropertyMutations.SET_REQUEST_STATE_PROPERTY, res)
        commit(PropertyMutations.SET_IS_LOADING_PROPERTIES_LIST_ARCHIVED, false)
      })
      .catch(err => {
        console.error(err)
        commit(PropertyMutations.SET_REQUEST_STATE_PROPERTY, err)
        commit(PropertyMutations.SET_IS_LOADING_PROPERTIES_LIST_ARCHIVED, false)
      })
  },

  /**
   * Fetch a property from the API
   * @param  - propertyId: The id of the property you want to fetch.
   * @param {string} propertyId - The id of the property you want to fetch.
   */
  async fetchProperty (
    { commit, rootGetters },  
    propertyId: string
  ): Promise<PropertyRequestDto> {
    return new Promise((resolve, reject) => {
        commit(PropertyMutations.SET_IS_LOADING_PROPERTY, true)
        axios
          .get(
            process.env.VUE_APP_API_ORISIS +
              'Billing/Property/GetPropertyById',
            {
              headers: {
                Authorization: `Bearer ${rootGetters.userToken}`
              },
              params: {
                propertyId: propertyId
              }
            }
          )
          .then(res => {
            commit(PropertyMutations.SET_PROPERTY, res.data)
            commit(PropertyMutations.SET_REQUEST_STATE_PROPERTY, res)
            commit(PropertyMutations.SET_IS_LOADING_PROPERTY, false)
            resolve(res.data)
          })
          .catch(err => {
            console.error(err)
            commit(PropertyMutations.SET_REQUEST_STATE_PROPERTY, err)
            commit(PropertyMutations.SET_IS_LOADING_PROPERTY, false)
          })
    })
  },

  async fetchPropertyArchived (
    { commit, rootGetters },
    propertyId: string
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
        commit(PropertyMutations.SET_IS_LOADING_PROPERTY, true)
        axios
          .get(
            process.env.VUE_APP_API_ORISIS + 'Billing/Property/GetPropertyArchivedById',
            {
              headers: {
                Authorization: `Bearer ${rootGetters.userToken}`
              },
              params: {
                propertyId: propertyId
              }
            }
          )
          .then(res => {
            commit(PropertyMutations.SET_PROPERTY, res.data)
            commit(PropertyMutations.SET_REQUEST_STATE_PROPERTY, res)
            commit(PropertyMutations.SET_IS_LOADING_PROPERTY, false)

            resolve({ ...res.data, workspaceId: rootGetters.workspaceSelected.id })
          })
          .catch(err => {
            console.error(err)
            commit(PropertyMutations.SET_REQUEST_STATE_PROPERTY, err)
            commit(PropertyMutations.SET_IS_LOADING_PROPERTY, false)
          })
    })
  },


  /**
   * Create a property and add it to the properties list
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {PropertyRequestDto} property - The property object that will be sent to the API.
   */
  async createProperty (
    { commit, rootGetters },
    property: PropertyRequestDto
  ): Promise<void> {
    commit(PropertyMutations.SET_IS_CREATING_PROPERTY, true)
    axios
      .post(
        process.env.VUE_APP_API_ORISIS +
          'Billing/Property/CreateProperty',
        {...property, workspaceId : rootGetters.workspaceSelected.id},
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.propertiesList.unshift(res.data.data)
        commit(
          PropertyMutations.SET_PROPERTIES_LIST,
          rootGetters.propertiesList
        )
        commit(PropertyMutations.SET_REQUEST_STATE_PROPERTY, res)
        commit(PropertyMutations.SET_IS_CREATING_PROPERTY, false)
      })
      .catch(err => {
        console.error(err)
        commit(PropertyMutations.SET_REQUEST_STATE_PROPERTY, err)
        commit(PropertyMutations.SET_IS_CREATING_PROPERTY, false)
      })
  },

  /**
   * Update a property
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {PropertyRequestDto} property - The property object that will be updated.
   */
  async updateProperty (
    { commit, rootGetters },
    property: PropertyRequestDto
  ): Promise<void> {
    commit(PropertyMutations.SET_IS_UPDATING_PROPERTY, true)
    axios
      .put(
        process.env.VUE_APP_API_ORISIS +
          'Billing/Property/UpdateProperty',
          {...property, workspaceId : rootGetters.workspaceSelected.id},

        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.propertiesList.splice(
          rootGetters.propertiesList.findIndex(
            elem => elem.id == property.id
          ),
          1,
          property
        )
        commit(
          PropertyMutations.SET_PROPERTIES_LIST,
          rootGetters.propertiesList
        )

        commit(PropertyMutations.SET_REQUEST_STATE_PROPERTY, res)
        commit(PropertyMutations.SET_IS_UPDATING_PROPERTY, false)
      })
      .catch(err => {
        console.error(err)
        commit(PropertyMutations.SET_REQUEST_STATE_PROPERTY, err)
        commit(PropertyMutations.SET_IS_UPDATING_PROPERTY, false)
      })
  },

  /**
   * Delete a property from the database
   * @param  - - `commit`: the commit function of the Vuex store.
   * @param {string} propertyId - The id of the property to delete.
   */
  async deleteProperty (
    { commit, rootGetters },
    propertyId: string
  ): Promise<void> {
    commit(PropertyMutations.SET_IS_DELETING_PROPERTY, true)
    axios
      .delete(
        process.env.VUE_APP_API_ORISIS + 'Billing/Property/Delete',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          params: {
            propertyId: propertyId
          }
        }
      )
      .then(res => {
        rootGetters.propertiesListArchived.splice(
          rootGetters.propertiesListArchived.findIndex(elem => elem.id == propertyId),
          1
        )

        commit(
          PropertyMutations.SET_PROPERTIES_LIST,
          rootGetters.propertiesList
        )
        commit(PropertyMutations.SET_REQUEST_STATE_PROPERTY, res)
        commit(PropertyMutations.SET_IS_DELETING_PROPERTY, false)
      })
      .catch(err => {
        console.error(err)
        commit(PropertyMutations.SET_REQUEST_STATE_PROPERTY, err)
        commit(PropertyMutations.SET_IS_DELETING_PROPERTY, false)
      })
  },

  /**
   * Delete a property from the database
   * @param  - - `commit`: the commit function of the Vuex store.
   * @param {string} propertyId - The id of the property to delete.
   */
   async archiveProperty (
    { commit, rootGetters },
    propertyId: string
  ): Promise<void> {
    commit(PropertyMutations.SET_IS_ARCHIVING_PROPERTY, true)
    axios
      .delete(
        process.env.VUE_APP_API_ORISIS + 'Billing/Property/Archive',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          data:[propertyId]
        }
      )
      .then(res => {
        rootGetters.propertiesListArchived.unshift(rootGetters.propertiesList.find(elem => elem.id == propertyId ))
        rootGetters.propertiesList.splice(
          rootGetters.propertiesList.findIndex(
            elem => elem.id == propertyId
          ),
          1
        )
        commit(
          PropertyMutations.SET_PROPERTIES_LIST,
          rootGetters.propertiesList
        )
        commit(PropertyMutations.SET_REQUEST_STATE_PROPERTY, res)
        commit(PropertyMutations.SET_IS_ARCHIVING_PROPERTY, false)
      })
      .catch(err => {
        console.error(err)
        commit(PropertyMutations.SET_REQUEST_STATE_PROPERTY, err)
        commit(PropertyMutations.SET_IS_ARCHIVING_PROPERTY, false)
      })
   },

   async restoreProperty (
    { commit, rootGetters },
    propertyId: string
  ): Promise<void> {
    commit(PropertyMutations.SET_IS_UPDATING_PROPERTY, true)
    axios
      .put(
        process.env.VUE_APP_API_ORISIS + 'Billing/Property/Restore',
        [propertyId],
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.propertiesList.unshift(rootGetters.propertiesListArchived.find(elem => elem.id == propertyId ))
        rootGetters.propertiesListArchived.splice(
          rootGetters.propertiesListArchived.findIndex(
            elem => elem.id == propertyId
          ),
          1
        )
        commit(PropertyMutations.SET_REQUEST_STATE_PROPERTY, res)
        commit(PropertyMutations.SET_IS_UPDATING_PROPERTY, false)
      })
      .catch(err => {
        console.error(err)
        commit(PropertyMutations.SET_REQUEST_STATE_PROPERTY, err)
        commit(PropertyMutations.SET_IS_UPDATING_PROPERTY, false)
      })
   }
  
}
