import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { CodeTvaRequestDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '../../RootState'
import { ICodeTvaState } from './states'


export const getters: GetterTree<ICodeTvaState, RootState> = {
  CodeTvaList: (state): CodeTvaRequestDto[] => state.CodeTvaList,
  CodeTvaListArchived: (state): CodeTvaRequestDto[] => state.CodeTvaListArchived,
  codeTva     : (state): CodeTvaRequestDto => state.codeTva,

  // COMMON
  isLoadingCodeTvaList   : (state): boolean => state.isLoadingCodeTvaList,
  isLoadingCodeTvaListArchived   : (state): boolean => state.isLoadingCodeTvaListArchived,
  isLoadingCodeTva   : (state): boolean => state.isLoadingCodeTva,
  isCreatingCodeTva  : (state): boolean => state.isCreatingCodeTva,
  isUpdatingCodeTva  : (state): boolean => state.isUpdatingCodeTva,
  isDeletingCodeTva  : (state): boolean => state.isDeletingCodeTva,
  isArchivingCodeTva  : (state): boolean => state.isArchivingCodeTva,
  requestStateCodeTva: (state): IRequestState => state.requestStateCodeTva,
  isOpenSidebarCodeTva : (state): boolean => state.isOpenSidebarCodeTva,
}



