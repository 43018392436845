import { CompanyTypeRequestDto } from '@/../Api'
import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '../../RootState'
import { CompanyTypeMutations } from './mutations'
import { ICompanyTypeState } from './states'

export const actions: ActionTree<ICompanyTypeState, RootState> = {

 /**
  * It fetches the list of companies from the API.
  * @param  - - **commit**: The commit function is used to send mutations to the Vuex store.
  */
  async fetchCompaniesTypesList ({ commit, rootGetters }): Promise<void> {
    commit(CompanyTypeMutations.SET_IS_LOADING_COMPANY_TYPE, true)
    axios
      .get(
        process.env.VUE_APP_API_ORISIS +
          'Directory/CompanyType/GetCompanyType',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          params: {
            workspaceId: rootGetters.workspaceSelected.id
          }
        }
      )
      .then(res => {

        commit(CompanyTypeMutations.SET_COMPANIES_TYPES_LIST, res.data)
        commit(CompanyTypeMutations.SET_REQUEST_STATE_COMPANY_TYPE, res)
        commit(CompanyTypeMutations.SET_IS_LOADING_COMPANY_TYPE, false)
      })
      .catch(err => {
        console.error(err)
        commit(CompanyTypeMutations.SET_REQUEST_STATE_COMPANY_TYPE, err)
        commit(CompanyTypeMutations.SET_IS_LOADING_COMPANY_TYPE, false)
      })
  },


 /**
  * It fetches the companyTypeType data from the API.
  * @param  - companyTypeId: The companyTypeId of the companyType you want to fetch.
  * @param {string} companyTypeTypeId - string
  */
  async fetchCompanyType (
    { commit, rootGetters },
    companyTypeTypeId: string
  ): Promise<void> {
    commit(CompanyTypeMutations.SET_IS_LOADING_COMPANY_TYPE, true)
    axios
      .get(
        process.env.VUE_APP_API_ORISIS +
          'Directory/CompanyType/GetCompanyType',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          params: {
            compnayTypeId: companyTypeTypeId
          }
        }
      )
      .then(res => {
        commit(CompanyTypeMutations.SET_COMPANY_TYPE, res.data)
        commit(CompanyTypeMutations.SET_REQUEST_STATE_COMPANY_TYPE, res)
        commit(CompanyTypeMutations.SET_IS_LOADING_COMPANY_TYPE, false)
      })
      .catch(err => {
        console.error(err)
        commit(CompanyTypeMutations.SET_REQUEST_STATE_COMPANY_TYPE, err)
        commit(CompanyTypeMutations.SET_IS_LOADING_COMPANY_TYPE, false)
      })
  },

  
  /**
   * Create a new companyType
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {CompanyTypeRequestDto} companyType - CompanyTypeRequestDto
   */
  async createCompanyType (
    { commit, rootGetters },
    companyType: CompanyTypeRequestDto
  ): Promise<void> {
    companyType.workspaceId = rootGetters.workspaceSelected.id
    commit(CompanyTypeMutations.SET_IS_CREATING_COMPANY_TYPE, true)
    axios
      .post(
        process.env.VUE_APP_API_ORISIS +
          'Directory/CompanyType/CreateCompanyType',
        companyType,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.companiesTypesList.unshift(res.data.data);
        commit(
          CompanyTypeMutations.SET_COMPANY_TYPE_LIST,
          rootGetters.companiesTypesList
        )
        commit(CompanyTypeMutations.SET_REQUEST_STATE_COMPANY_TYPE, res)
        commit(CompanyTypeMutations.SET_IS_CREATING_COMPANY_TYPE, false)
      })
      .catch(err => {
        console.error(err)
        commit(CompanyTypeMutations.SET_REQUEST_STATE_COMPANY_TYPE, err)
        commit(CompanyTypeMutations.SET_IS_CREATING_COMPANY_TYPE, false)
      })
  },

  async updateCompanyType (
    { commit, rootGetters },
    companyType: CompanyTypeRequestDto
  ): Promise<void> {
    commit(CompanyTypeMutations.SET_IS_UPDATING_COMPANY_TYPE, true)
    axios
      .put(
        process.env.VUE_APP_API_ORISIS +
          'Directory/CompanyType/UpdateCompanyType',
        companyType,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.companiesTypesList.splice(
          rootGetters.companiesTypesList.findIndex(
            elem => elem.id == companyType.id
          ),
          1,
          companyType
        )
        commit(
          CompanyTypeMutations.SET_COMPANY_TYPE_LIST,
          rootGetters.companiesTypesList
        )
        commit(CompanyTypeMutations.SET_REQUEST_STATE_COMPANY_TYPE, res)
        commit(CompanyTypeMutations.SET_IS_UPDATING_COMPANY_TYPE, false)
      })
      .catch(err => {
        console.error(err)
        commit(CompanyTypeMutations.SET_REQUEST_STATE_COMPANY_TYPE, err)
        commit(CompanyTypeMutations.SET_IS_UPDATING_COMPANY_TYPE, false)
      })
  },

  /**
   * Delete a companyType from the database
   * @param  - - `commit`: the commit function of the Vuex store.
   * @param {string} companyTypeId - The id of the companyType to delete.
   */
  async deleteCompanyType (
    { commit, rootGetters },
    companyTypeId: string
  ): Promise<void> {
    commit(CompanyTypeMutations.SET_IS_DELETING_COMPANY_TYPE, true)
    axios
      .delete(
        process.env.VUE_APP_API_ORISIS + 'Directory/CompanyType/Delete',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          params: {
            companyTypeId: companyTypeId
          }
        }
      )
      .then(res => {
        rootGetters.companiesTypesList.splice(
          rootGetters.companiesTypesList.findIndex(
            elem => elem.id == companyTypeId
          ),
          1
        )
        commit(
          CompanyTypeMutations.SET_COMPANY_TYPE_LIST,
          rootGetters.companiesTypesList
        )
        commit(CompanyTypeMutations.SET_REQUEST_STATE_COMPANY_TYPE, res)
        commit(CompanyTypeMutations.SET_IS_DELETING_COMPANY_TYPE, false)
      })
      .catch(err => {
        console.error(err)
        commit(CompanyTypeMutations.SET_REQUEST_STATE_COMPANY_TYPE, err)
        commit(CompanyTypeMutations.SET_IS_DELETING_COMPANY_TYPE, false)
      })
  }
}
