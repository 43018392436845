import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { PropertyRequestDto } from '@/../Api'
import RootState from "@/store/modules/RootState"
import { IPropertyState } from "./states";
import { Module } from "vuex"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"

export const state: IPropertyState = {
    propertiesList       : Array<PropertyRequestDto>(),
    propertiesListArchived : Array<PropertyRequestDto>(),
    property            : <PropertyRequestDto>{},
    isLoadingPropertiesList   : false,
    isLoadingPropertiesListArchived   : false,
    isLoadingProperty   : false,
    isCreatingProperty  : false,
    isUpdatingProperty  : false,
    isDeletingProperty  : false,
    isArchivingProperty : false,
    requestStateProperty: <IRequestState>{},
    isOpenSidebarProperty : false,
}

export const PropertyModule: Module<IPropertyState, RootState> = {
  state, 
  getters,
  mutations,
  actions
}