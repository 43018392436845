import { IPropertyState } from "./states";
import { MutationTree } from "vuex";
import { PropertyRequestDto } from '@/../Api'
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";


export enum PropertyMutations{
    SET_PROPERTIES_LIST = "SET_PROPERTIES_LIST",
    SET_PROPERTIES_LIST_ARCHIVED = "SET_PROPERTIES_LIST_ARCHIVED",
    SET_PROPERTY = "SET_PROPERTY",
    
    // COMON 
    SET_IS_LOADING_PROPERTIES_LIST = "SET_IS_LOADING_PROPERTIES_LIST",
    SET_IS_LOADING_PROPERTIES_LIST_ARCHIVED = "SET_IS_LOADING_PROPERTIES_LIST_ARCHIVED",
    SET_IS_LOADING_PROPERTY = "SET_IS_LOADING_PROPERTY",
    SET_IS_CREATING_PROPERTY = "SET_IS_CREATING_PROPERTY",
    SET_IS_UPDATING_PROPERTY = "SET_IS_UPDATING_PROPERTY",
    SET_IS_DELETING_PROPERTY = "SET_IS_DELETING_PROPERTY",
    SET_IS_ARCHIVING_PROPERTY = "SET_IS_ARCHIVING_PROPERTY",
    SET_REQUEST_STATE_PROPERTY = "SET_REQUEST_STATE_PROPERTY",
    SET_IS_OPEN_SIDEBAR_PROPERTY = "SET_IS_OPEN_SIDEBAR_PROPERTY"
  }

  export const mutations: MutationTree<IPropertyState> = {
    [PropertyMutations.SET_PROPERTIES_LIST_ARCHIVED]: (state, payload: PropertyRequestDto[]) => {
        state.propertiesListArchived = payload;
    },
    [PropertyMutations.SET_PROPERTIES_LIST]: (state, payload: PropertyRequestDto[]) => {
        state.propertiesList = payload;
    },
    [PropertyMutations.SET_PROPERTY]: (state, payload: PropertyRequestDto) => {
        state.property = payload;
    },

    //COMMON
    [PropertyMutations.SET_IS_LOADING_PROPERTIES_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingPropertiesListArchived = payload;
    },
    [PropertyMutations.SET_IS_LOADING_PROPERTIES_LIST]: (state, payload: boolean) => {
        state.isLoadingPropertiesList = payload;
    },
    [PropertyMutations.SET_IS_LOADING_PROPERTY]: (state, payload: boolean) => {
        state.isLoadingProperty = payload;
    },
    [PropertyMutations.SET_IS_CREATING_PROPERTY]: (state, payload: boolean) => {
        state.isCreatingProperty = payload;
    },
    [PropertyMutations.SET_IS_UPDATING_PROPERTY]: (state, payload: boolean) => {
        state.isUpdatingProperty = payload;
    },
    [PropertyMutations.SET_IS_DELETING_PROPERTY]: (state, payload: boolean) => {
        state.isDeletingProperty = payload;
    },
    [PropertyMutations.SET_IS_ARCHIVING_PROPERTY]: (state, payload: boolean) => {
        state.isArchivingProperty = payload;
    },

    
    [PropertyMutations.SET_REQUEST_STATE_PROPERTY]: (state, payload: IRequestState) => {
        state.requestStateProperty = payload;
    },
    [PropertyMutations.SET_IS_OPEN_SIDEBAR_PROPERTY]: (state, payload: boolean) => {
        state.isOpenSidebarProperty = payload;
    },
}