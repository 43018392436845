import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { PropertyRequestDto } from '@/../Api'
import { GetterTree } from "vuex";
import RootState from "../../RootState";
import { IPropertyState } from "./states";

export const getters: GetterTree<IPropertyState, RootState> = {
    propertiesList: (state): PropertyRequestDto[] => state.propertiesList,
    propertiesListArchived: (state): PropertyRequestDto[] => state.propertiesListArchived,
    property     : (state): PropertyRequestDto => state.property,
    
    // COMMON
    isLoadingPropertiesList   : (state): boolean => state.isLoadingPropertiesList,
    isLoadingPropertiesListArchived   : (state): boolean => state.isLoadingPropertiesListArchived,
    isLoadingProperty   : (state): boolean => state.isLoadingProperty,
    isCreatingProperty  : (state): boolean => state.isCreatingProperty,
    isUpdatingProperty  : (state): boolean => state.isUpdatingProperty,
    isDeletingProperty  : (state): boolean => state.isDeletingProperty,
    isArchivingProperty : (state): boolean => state.isArchivingProperty,
    requestStateProperty: (state): IRequestState => state.requestStateProperty,
    isOpenSidebarProperty: (state): boolean => state.isOpenSidebarProperty
}