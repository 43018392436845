import { PropertyTypeRequestDto } from '@/../Api'
import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '../../RootState'
import { PropertyTypeMutations } from './mutations'
import { IPropertyTypeState } from './states'

export const actions: ActionTree<IPropertyTypeState, RootState> = {
  /**
   * It fetches the list of propertyType from the API.
   * @param  - **commit**: The commit function is used to send mutations to the store.
   */
  async fetchPropertyTypeList ({ commit, rootGetters }): Promise<void> {
   return new Promise<void>(async (resolve, reject) => {
    commit(PropertyTypeMutations.SET_IS_LOADING_PROPERTY_TYPE_LIST, true)
    let url = `${process.env.VUE_APP_API_ORISIS}Billing/PropertyType/GetPropertyTypes`
    await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          workspaceId: rootGetters.workspaceSelected.id
        }
      })
      .then(res => {
        commit(PropertyTypeMutations.SET_IS_LOADING_PROPERTY_TYPE_LIST, false)
        commit(PropertyTypeMutations.SET_PROPERTY_TYPE_LIST, res.data)
        commit(PropertyTypeMutations.SET_REQUEST_STATE_PROPERTY_TYPE, res)
        resolve(res.data)
      })
      .catch(err => {
        console.error(err)
        commit(PropertyTypeMutations.SET_IS_LOADING_PROPERTY_TYPE_LIST, false)
        commit(PropertyTypeMutations.SET_REQUEST_STATE_PROPERTY_TYPE, err)
      })
   })
  },
  // async fetchPropertyTypeListArchived ({ commit, rootGetters }): Promise<void> {
  //  return new Promise<void>(async (resolve, reject) => {
  //   commit(PropertyTypeMutations.SET_IS_LOADING_PROPERTY_TYPE_LIST_ARCHIVED, true)
  //   let url = `${process.env.VUE_APP_API_ORISIS}Billing/PropertyType/GetPropertyTypeArchivedByWorkspaceId`
  //   await axios
  //     .get(url, {
  //       headers: {
  //         Authorization: `Bearer ${rootGetters.userToken}`
  //       },
  //       params: {
  //         workspaceId: rootGetters.workspaceSelected.id
  //       }
  //     })
  //     .then(res => {
  //       commit(PropertyTypeMutations.SET_IS_LOADING_PROPERTY_TYPE_LIST_ARCHIVED, false)
  //       commit(PropertyTypeMutations.SET_PROPERTY_TYPE_LIST_ARCHIVED, res.data)
  //       commit(PropertyTypeMutations.SET_REQUEST_STATE_PROPERTY_TYPE, res)
  //       resolve(res.data)
  //     })
  //     .catch(err => {
  //       console.error(err)
  //       commit(PropertyTypeMutations.SET_IS_LOADING_PROPERTY_TYPE_LIST_ARCHIVED, false)
  //       commit(PropertyTypeMutations.SET_REQUEST_STATE_PROPERTY_TYPE, err)
  //     })
  //  })
  // },

  /* Fetching the propertyType data from the API. */
  async fetchPropertyType ({ commit, rootGetters }, propertyTypeId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(PropertyTypeMutations.SET_IS_LOADING_PROPERTY_TYPE, true)
    axios
      .get(`${process.env.VUE_APP_API_ORISIS}Billing/PropertyType/GetPropertyTypeById`, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          propertyTypeId: propertyTypeId
        }
      })
      .then(res => {
        commit(PropertyTypeMutations.SET_PROPERTY_TYPE, res.data)
        commit(PropertyTypeMutations.SET_REQUEST_STATE_PROPERTY_TYPE, res)
        commit(PropertyTypeMutations.SET_IS_LOADING_PROPERTY_TYPE, false)
        resolve(res.data)
      })
      .catch(err => {
        commit(PropertyTypeMutations.SET_REQUEST_STATE_PROPERTY_TYPE, err)
        commit(PropertyTypeMutations.SET_IS_LOADING_PROPERTY_TYPE, false)
      })
    })
  },

  // async fetchPropertyTypeArchived (
  //   { commit, rootGetters },
  //   propertyTypeId: string
  // ): Promise<void> {
  //   return new Promise<void>((resolve, reject) => {
  //     commit(PropertyTypeMutations.SET_IS_LOADING_PROPERTY_TYPE, true)
  //   axios
  //     .get(`${process.env.VUE_APP_API_ORISIS}Billing/PropertyType/GetPropertyTypeArchivedById`, {
  //       headers: {
  //         Authorization: `Bearer ${rootGetters.userToken}`
  //       },
  //       params: {
  //         propertyTypeId: propertyTypeId
  //       }
  //     })
  //     .then(res => {
  //       commit(PropertyTypeMutations.SET_PROPERTY_TYPE, res.data)
  //       commit(PropertyTypeMutations.SET_REQUEST_STATE_PROPERTY_TYPE, res)
  //       commit(PropertyTypeMutations.SET_IS_LOADING_PROPERTY_TYPE, false)
  //       resolve(res.data)
  //     })
  //     .catch(err => {
  //       commit(PropertyTypeMutations.SET_REQUEST_STATE_PROPERTY_TYPE, err)
  //       commit(PropertyTypeMutations.SET_IS_LOADING_PROPERTY_TYPE, false)
  //     })
  //   })
  // },

  /**
   * It creates a new propertyType.
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {PropertyTypeRequestDto} propertyType - PropertyTypeRequestDto
   */
  async createPropertyType ({ commit, rootGetters }, propertyType: PropertyTypeRequestDto): Promise<void> {
    commit(PropertyTypeMutations.SET_IS_CREATING_PROPERTY_TYPE, true)
    await axios
      .post(
        `${process.env.VUE_APP_API_ORISIS}Billing/PropertyType/CreatePropertyType`,
        {...propertyType, workspaceId: rootGetters.workspaceSelected.id},
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {

        rootGetters.propertyTypeList.push(res.data.data)
        commit(
          PropertyTypeMutations.SET_PROPERTY_TYPE_LIST,
          rootGetters.propertyTypeList
        )
        commit(PropertyTypeMutations.SET_REQUEST_STATE_PROPERTY_TYPE, res)
        commit(PropertyTypeMutations.SET_IS_CREATING_PROPERTY_TYPE, false)
      })
      .catch(err => {
        commit(PropertyTypeMutations.SET_REQUEST_STATE_PROPERTY_TYPE, err)
        commit(PropertyTypeMutations.SET_IS_CREATING_PROPERTY_TYPE, false)
      })
  },

  /**
   * Update an propertyType
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {PropertyTypeRequestDto} propertyType - PropertyTypeRequestDto
   */
  async updatePropertyType ({ commit, rootGetters }, propertyType: PropertyTypeRequestDto) {
    commit(PropertyTypeMutations.SET_IS_UPDATING_PROPERTY_TYPE, true)
    await axios
      .put(
        `${process.env.VUE_APP_API_ORISIS}Billing/PropertyType/UpdatePropertyType`,
        {...propertyType, workspaceId: rootGetters.workspaceSelected.id},
        
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.propertyTypeList.splice(
          rootGetters.propertyTypeList.findIndex(elem => elem.id == propertyType.id),
          1,
          propertyType
        )
        commit(
          PropertyTypeMutations.SET_PROPERTY_TYPE_LIST,
          rootGetters.propertyTypeList
        )
        commit(PropertyTypeMutations.SET_REQUEST_STATE_PROPERTY_TYPE, res)
        commit(PropertyTypeMutations.SET_IS_UPDATING_PROPERTY_TYPE, false)
      })
      .catch(err => {
        commit(PropertyTypeMutations.SET_REQUEST_STATE_PROPERTY_TYPE, err)
        commit(PropertyTypeMutations.SET_IS_UPDATING_PROPERTY_TYPE, false)
      })
  },

  /**
   * It deletes an propertyType from the database.
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {string} propertyTypeId - string
   */
  async deletePropertyType ({ commit, rootGetters }, propertyTypeId: string) {
    commit(PropertyTypeMutations.SET_IS_DELETING_PROPERTY_TYPE, true)
    await axios
      .delete(`${process.env.VUE_APP_API_ORISIS}Billing/PropertyType/Delete`, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          propertyTypeId: propertyTypeId
        }
      })
      .then(res => {
        rootGetters.propertyTypeList.splice(
          rootGetters.propertyTypeList.findIndex(elem => elem.id == propertyTypeId),
          1
        )
        commit(
          PropertyTypeMutations.SET_PROPERTY_TYPE_LIST,
          rootGetters.propertyTypeList
        )
        commit(PropertyTypeMutations.SET_REQUEST_STATE_PROPERTY_TYPE, res)
        commit(PropertyTypeMutations.SET_IS_DELETING_PROPERTY_TYPE, false)
      })
      .catch(err => {
        commit(PropertyTypeMutations.SET_REQUEST_STATE_PROPERTY_TYPE, err)
        commit(PropertyTypeMutations.SET_IS_DELETING_PROPERTY_TYPE, false)
      })
  },

  /**
   * Delete a propertyType from the database
   * @param  - - `commit`: the commit function of the Vuex store.
   * @param {string} propertyTypeId - The id of the propertyType to delete.
   */
  async archivePropertyType (
    { commit, rootGetters },
    propertyTypeId: string
  ): Promise<void> {
    commit(PropertyTypeMutations.SET_IS_ARCHIVING_PROPERTY_TYPE, true)
    axios
      .delete(
        process.env.VUE_APP_API_ORISIS + 'Billing/PropertyType/Archive',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          data:[propertyTypeId]
        }
      )
      .then(res => {
        rootGetters.propertyTypeListArchived.unshift(rootGetters.propertyTypeList.find(elem => elem.id == propertyTypeId ))
        rootGetters.propertyTypeList.splice(
          rootGetters.propertyTypeList.findIndex(
            elem => elem.id == propertyTypeId
          ),
          1
        )
        commit(
          PropertyTypeMutations.SET_PROPERTY_TYPE_LIST,
          rootGetters.propertyTypeList
        )
        commit(PropertyTypeMutations.SET_REQUEST_STATE_PROPERTY_TYPE, res)
        commit(PropertyTypeMutations.SET_IS_ARCHIVING_PROPERTY_TYPE, false)
      })
      .catch(err => {
        console.error(err)
        commit(PropertyTypeMutations.SET_REQUEST_STATE_PROPERTY_TYPE, err)
        commit(PropertyTypeMutations.SET_IS_ARCHIVING_PROPERTY_TYPE, false)
      })
   },

  // async restorePropertyType (
  //   { commit, rootGetters },
  //   propertyTypeId: string
  // ): Promise<void> {
  //   commit(PropertyTypeMutations.SET_IS_UPDATING_PROPERTY_TYPE, true)
  //   axios
  //     .put(
  //       process.env.VUE_APP_API_ORISIS + 'Billing/PropertyType/Restore',
  //       [propertyTypeId],
  //       {
  //         headers: {
  //           Authorization: `Bearer ${rootGetters.userToken}`
  //         }
  //       }
  //     )
  //     .then(res => {
  //       rootGetters.propertyTypeList.unshift(rootGetters.propertyTypeListArchived.find(elem => elem.id == propertyTypeId ))
  //       rootGetters.propertyTypeListArchived.splice(
  //         rootGetters.propertyTypeListArchived.findIndex(
  //           elem => elem.id == propertyTypeId
  //         ),
  //         1
  //       )
  //       commit(PropertyTypeMutations.SET_REQUEST_STATE_PROPERTY_TYPE, res)
  //       commit(PropertyTypeMutations.SET_IS_UPDATING_PROPERTY_TYPE, false)
  //     })
  //     .catch(err => {
  //       console.error(err)
  //       commit(PropertyTypeMutations.SET_REQUEST_STATE_PROPERTY_TYPE, err)
  //       commit(PropertyTypeMutations.SET_IS_UPDATING_PROPERTY_TYPE, false)
  //     })
  //  }
}
