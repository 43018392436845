import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { ContractRequestDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '../../RootState'
import { IContractState } from './states'


export const getters: GetterTree<IContractState, RootState> = {
  contractsList: (state): ContractRequestDto[] => state.contractsList,
  contractsListArchived: (state): ContractRequestDto[] => state.contractsListArchived,
  contract     : (state): ContractRequestDto => state.contract,

  // COMMON
  isLoadingContractsList   : (state): boolean => state.isLoadingContractsList,
  isLoadingContractsListArchived   : (state): boolean => state.isLoadingContractsListArchived,
  isLoadingContract   : (state): boolean => state.isLoadingContract,
  isCreatingContract  : (state): boolean => state.isCreatingContract,
  isUpdatingContract  : (state): boolean => state.isUpdatingContract,
  isDeletingContract  : (state): boolean => state.isDeletingContract,
  isArchivingContract  : (state): boolean => state.isArchivingContract,
  requestStateContract: (state): IRequestState => state.requestStateContract,
  isOpenSidebarContract : (state): boolean => state.isOpenSidebarContract,
}



