import { IPropertyTypeState } from "./states"
import { Module } from "vuex"
import { PropertyTypeRequestDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/RootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:IPropertyTypeState = {
    propertyTypeList         : Array<PropertyTypeRequestDto>(),
    propertyTypeListArchived : Array<PropertyTypeRequestDto>(),
    propertyType              : <PropertyTypeRequestDto>{},
    isLoadingPropertyTypeList     : false,
    isLoadingPropertyTypeListArchived     : false,
    isLoadingPropertyType     : false,
    isCreatingPropertyType    : false,
    isUpdatingPropertyType    : false,
    isDeletingPropertyType    : false,
    isArchivingPropertyType    : false,
    requestStatePropertyType: <IRequestState>{},
    isOpenSidebarPropertyType : false,
}

export const PropertyTypeModule: Module<IPropertyTypeState, RootState> = {
    state, 
    getters,
    mutations,
    actions
} 