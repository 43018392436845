import { IPlanningView } from '@/types/api-orisis/interfaces/Planning/IPlanningViews'
import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '../../RootState'
import { PlanningViewMutations } from './mutations'
import { IPlanningViewState } from './states'

export const actions: ActionTree<IPlanningViewState, RootState> = {
  /**
   * It fetches the list of planningViews from the API and stores it in the state.
   */
  async fetchPlanningViewsList ({ commit, rootGetters }, {workspaceSelectedId}): Promise<void> {
  return new Promise<void>((resolve, reject) => { 
    commit(PlanningViewMutations.SET_IS_LOADING_PLANNING_VIEW_LIST, true)
    axios
      .get(
        process.env.VUE_APP_API_ORISIS +
          'Plannings/PlanningView/GetPlanningViewByWorkspace',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          params: {
            workspaceId: (workspaceSelectedId? workspaceSelectedId : rootGetters.workspaceSelected.id)
          }
        }
      )
      .then(res => {
        commit(PlanningViewMutations.SET_PLANNING_VIEWS_LIST, res.data)
        commit(PlanningViewMutations.SET_REQUEST_STATE_PLANNING_VIEW, res)
        commit(PlanningViewMutations.SET_IS_LOADING_PLANNING_VIEW_LIST, false)
        resolve(res.data)
        return res.data
      })
      .catch(err => {
        console.error(err)
        commit(PlanningViewMutations.SET_REQUEST_STATE_PLANNING_VIEW, err)
        commit(PlanningViewMutations.SET_IS_LOADING_PLANNING_VIEW_LIST, false)
      })
  })
  },

  /**
   * Fetch a planningView from the API
   * @param  - planningViewId: The id of the planningView you want to fetch.
   * @param {string} planningViewId - The id of the planningView you want to fetch.
   */
  async fetchPlanningView (
    { commit, rootGetters },
    planningViewId: string
  ): Promise<void> {
    return new Promise<void>((resolve, reject) =>{
      commit(PlanningViewMutations.SET_IS_LOADING_PLANNING_VIEW, true)
    axios
      .get(
        process.env.VUE_APP_API_ORISIS +
          'Plannings/PlanningView/GetPlanningViewById',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          params: {
            planningViewId: planningViewId
          }
        }
      )
      .then(res => {
        commit(PlanningViewMutations.SET_PLANNING_VIEW, res.data)
        commit(PlanningViewMutations.SET_REQUEST_STATE_PLANNING_VIEW, res)
        commit(PlanningViewMutations.SET_IS_LOADING_PLANNING_VIEW, false)
        resolve(res.data)
      })
      .catch(err => {
        console.error(err)
        commit(PlanningViewMutations.SET_REQUEST_STATE_PLANNING_VIEW, err)
        commit(PlanningViewMutations.SET_IS_LOADING_PLANNING_VIEW, false)
      })
    })
  },

  /**
   * Create a planningView and add it to the planningViews list
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {IPlanningView} planningView - The planningView object that will be sent to the API.
   */
  async createPlanningView (
    { commit, rootGetters },
    planningView: IPlanningView
  ): Promise<void> {
    commit(PlanningViewMutations.SET_IS_CREATING_PLANNING_VIEW, true)
    axios
      .post(
        process.env.VUE_APP_API_ORISIS +
          'Plannings/PlanningView/CreatePlanningView',
        { ...planningView, workspaceId: rootGetters.workspaceSelected.id },
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.planningViewsList.unshift(res.data.data)
        commit(
          PlanningViewMutations.SET_PLANNING_VIEWS_LIST,
          rootGetters.planningViewsList
        )
        commit(PlanningViewMutations.SET_REQUEST_STATE_PLANNING_VIEW, res)
        commit(PlanningViewMutations.SET_IS_CREATING_PLANNING_VIEW, false)
      })
      .catch(err => {
        console.error(err)
        commit(PlanningViewMutations.SET_REQUEST_STATE_PLANNING_VIEW, err)
        commit(PlanningViewMutations.SET_IS_CREATING_PLANNING_VIEW, false)
      })
  },

  /**
   * Update a planningView
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {IPlanningView} planningView - The planningView object that will be updated.
   */
  
 
  async updatePlanningView (
    { commit, rootGetters },
    planningView: IPlanningView
  ): Promise<void> {
    commit(PlanningViewMutations.SET_IS_UPDATING_PLANNING_VIEW, true)
    axios
      .put(
        process.env.VUE_APP_API_ORISIS +
          'Plannings/PlanningView/UpdatePlanningView',
        {...planningView, workspaceId: rootGetters.workspaceSelected.id},
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.planningViewsList.splice(
          rootGetters.planningViewsList.findIndex(
            elem => elem.id == planningView.id
          ),
          1,
          planningView
        )
        commit(
          PlanningViewMutations.SET_PLANNING_VIEWS_LIST,
          rootGetters.planningViewsList
        )
        
        commit(PlanningViewMutations.SET_REQUEST_STATE_PLANNING_VIEW, res)
        commit(PlanningViewMutations.SET_IS_UPDATING_PLANNING_VIEW, false)
      })
      .catch(err => {
        console.error(err)
        commit(PlanningViewMutations.SET_REQUEST_STATE_PLANNING_VIEW, err)
        commit(PlanningViewMutations.SET_IS_UPDATING_PLANNING_VIEW, false)
      })
  },
 

  /**
   * Delete a planningView from the database
   * @param  - - `commit`: the commit function of the Vuex store.
   * @param {string} planningViewId - The id of the planningView to delete.
   */
  async deletePlanningView (
    { commit, rootGetters },
    planningViewId: string
  ): Promise<void> {
    commit(PlanningViewMutations.SET_IS_DELETING_PLANNING_VIEW, true)
    axios
      .delete(
        process.env.VUE_APP_API_ORISIS + 'Plannings/PlanningView/Delete',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          params: {
            planningViewId: planningViewId
          }
        }
      )
      .then(res => {
        rootGetters.planningViewsListArchived.splice(
          rootGetters.planningViewsListArchived.findIndex(
            elem => elem.id == planningViewId
          ),
          1
        )
        commit(
          PlanningViewMutations.SET_PLANNING_VIEWS_LIST,
          rootGetters.planningViewsList
        )
        commit(PlanningViewMutations.SET_REQUEST_STATE_PLANNING_VIEW, res)
        commit(PlanningViewMutations.SET_IS_DELETING_PLANNING_VIEW, false)
      })
      .catch(err => {
        console.error(err)
        commit(PlanningViewMutations.SET_REQUEST_STATE_PLANNING_VIEW, err)
        commit(PlanningViewMutations.SET_IS_DELETING_PLANNING_VIEW, false)
      })
  },
  

  /**
   * Archive a planningView from the database
   * @param  - - `commit`: the commit function of the Vuex store.
   * @param {string} planningViewId - The id of the planningView to delete.
   */
   async archivePlanningView (
    { commit, rootGetters },
    planningViewId: string
  ): Promise<void> {
    commit(PlanningViewMutations.SET_IS_ARCHIVING_PLANNING_VIEW, true)
    axios
      .delete(
        process.env.VUE_APP_API_ORISIS + 'Plannings/PlanningView/Archive',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          data:[planningViewId]
        }
      )
      .then(res => {
        rootGetters.planningViewsList.splice(rootGetters.planningViewsList.findIndex(elem => elem.id == planningViewId), 1 )
        commit(
          PlanningViewMutations.SET_PLANNING_VIEWS_LIST,
          rootGetters.planningViewsList
        )
        commit(PlanningViewMutations.SET_REQUEST_STATE_PLANNING_VIEW, res)
        commit(PlanningViewMutations.SET_IS_ARCHIVING_PLANNING_VIEW, false)
      })
      .catch(err => {
        console.error(err)
        commit(PlanningViewMutations.SET_REQUEST_STATE_PLANNING_VIEW, err)
        commit(PlanningViewMutations.SET_IS_ARCHIVING_PLANNING_VIEW, false)
      })
   }
  

}
