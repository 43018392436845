import RootState from "@/store/modules/RootState"
import { IPlanningStatisticsState } from "./states";
import { Module } from "vuex"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"

export const state: IPlanningStatisticsState = {
    statsEventTypesGlobal       : [],
    statsEventTypesByDay       : [],
    datePeriode            : [],
    isLoadingStatsEventTypesGlobal   : false,
    isLoadingStatsEventTypesByDay   : false,
    isLoadingDatePeriode   : false,
}

export const PlanningStatisticsModule: Module<IPlanningStatisticsState, RootState> = {
  state, 
  getters,
  mutations,
  actions
}
