import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { CompanyRequestDto } from '@/../Api'
import { GetterTree } from "vuex";
import RootState from "../../RootState";
import {  ICompanyState } from "./states";

export const getters: GetterTree<ICompanyState, RootState> = {
    companiesList: (state): CompanyRequestDto[] => state.companiesList,
    companiesListArchived: (state): CompanyRequestDto[] => state.companiesListArchived,
    company     : (state): CompanyRequestDto => state.company,
    
    // COMMON
    isLoadingCompaniesList   : (state): boolean => state.isLoadingCompaniesList,
    isLoadingCompaniesListArchived : (state): boolean => state.isLoadingCompaniesListArchived,
    isLoadingCompany   : (state): boolean => state.isLoadingCompany,
    isCreatingCompany  : (state): boolean => state.isCreatingCompany,
    isUpdatingCompany  : (state): boolean => state.isUpdatingCompany,
    isDeletingCompany  : (state): boolean => state.isDeletingCompany,
    requestStateCompany: (state): IRequestState => state.requestStateCompany,
    isOpenSidebarCompany : (state): boolean => state.isOpenSidebarCompany,
    isArchivingCompany : (state): boolean => state.isArchivingCompany,
}