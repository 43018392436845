import { PeriodicityRequestDto } from '@/../Api'
import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '../../RootState'
import { PeriodicityMutations } from './mutations'
import { IPeriodicityState } from './states'

export const actions: ActionTree<IPeriodicityState, RootState> = {
  /**
   * It fetches the list of periodicities from the API.
   * @param  - **commit**: The commit function is used to send mutations to the store.
   */
  async fetchPeriodicitiesList ({ commit, rootGetters }): Promise<void> {
   return new Promise<void>(async (resolve, reject) => {
    commit(PeriodicityMutations.SET_IS_LOADING_PERIODICITY_LIST, true)
    let url = `${process.env.VUE_APP_API_ORISIS}Billing/Periodicity/GetPeriodicities`
    await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          workspaceId: rootGetters.workspaceSelected.id
        }
      })
      .then(res => {
        commit(PeriodicityMutations.SET_IS_LOADING_PERIODICITY_LIST, false)
        commit(PeriodicityMutations.SET_PERIODICITIES_LIST, res.data)
        commit(PeriodicityMutations.SET_REQUEST_STATE_PERIODICITY, res)
        resolve(res.data)
      })
      .catch(err => {
        console.error(err)
        commit(PeriodicityMutations.SET_IS_LOADING_PERIODICITY_LIST, false)
        commit(PeriodicityMutations.SET_REQUEST_STATE_PERIODICITY, err)
      })
   })
  },
  // async fetchPeriodicitiesListArchived ({ commit, rootGetters }): Promise<void> {
  //  return new Promise<void>(async (resolve, reject) => {
  //   commit(PeriodicityMutations.SET_IS_LOADING_PERIODICITY_LIST_ARCHIVED, true)
  //   let url = `${process.env.VUE_APP_API_ORISIS}Billing/Periodicity/GetPeriodicityArchivedByWorkspaceId`
  //   await axios
  //     .get(url, {
  //       headers: {
  //         Authorization: `Bearer ${rootGetters.userToken}`
  //       },
  //       params: {
  //         workspaceId: rootGetters.workspaceSelected.id
  //       }
  //     })
  //     .then(res => {
  //       commit(PeriodicityMutations.SET_IS_LOADING_PERIODICITY_LIST_ARCHIVED, false)
  //       commit(PeriodicityMutations.SET_PERIODICITIES_LIST_ARCHIVED, res.data)
  //       commit(PeriodicityMutations.SET_REQUEST_STATE_PERIODICITY, res)
  //       resolve(res.data)
  //     })
  //     .catch(err => {
  //       console.error(err)
  //       commit(PeriodicityMutations.SET_IS_LOADING_PERIODICITY_LIST_ARCHIVED, false)
  //       commit(PeriodicityMutations.SET_REQUEST_STATE_PERIODICITY, err)
  //     })
  //  })
  // },

  /* Fetching the periodicity data from the API. */
  async fetchPeriodicity ({ commit, rootGetters }, periodicityId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(PeriodicityMutations.SET_IS_LOADING_PERIODICITY, true)
    axios
      .get(`${process.env.VUE_APP_API_ORISIS}Billing/Periodicity/GetPeriodicityById`, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          periodicityId: periodicityId
        }
      })
      .then(res => {
        commit(PeriodicityMutations.SET_PERIODICITY, res.data)
        commit(PeriodicityMutations.SET_REQUEST_STATE_PERIODICITY, res)
        commit(PeriodicityMutations.SET_IS_LOADING_PERIODICITY, false)
        resolve(res.data)
      })
      .catch(err => {
        commit(PeriodicityMutations.SET_REQUEST_STATE_PERIODICITY, err)
        commit(PeriodicityMutations.SET_IS_LOADING_PERIODICITY, false)
      })
    })
  },

  // async fetchPeriodicityArchived (
  //   { commit, rootGetters },
  //   periodicityId: string
  // ): Promise<void> {
  //   return new Promise<void>((resolve, reject) => {
  //     commit(PeriodicityMutations.SET_IS_LOADING_PERIODICITY, true)
  //   axios
  //     .get(`${process.env.VUE_APP_API_ORISIS}Billing/Periodicity/GetPeriodicityArchivedById`, {
  //       headers: {
  //         Authorization: `Bearer ${rootGetters.userToken}`
  //       },
  //       params: {
  //         periodicityId: periodicityId
  //       }
  //     })
  //     .then(res => {
  //       commit(PeriodicityMutations.SET_PERIODICITY, res.data)
  //       commit(PeriodicityMutations.SET_REQUEST_STATE_PERIODICITY, res)
  //       commit(PeriodicityMutations.SET_IS_LOADING_PERIODICITY, false)
  //       resolve(res.data)
  //     })
  //     .catch(err => {
  //       commit(PeriodicityMutations.SET_REQUEST_STATE_PERIODICITY, err)
  //       commit(PeriodicityMutations.SET_IS_LOADING_PERIODICITY, false)
  //     })
  //   })
  // },

  /**
   * It creates a new periodicity.
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {PeriodicityRequestDto} periodicity - PeriodicityRequestDto
   */
  async createPeriodicity ({ commit, rootGetters }, periodicity: PeriodicityRequestDto): Promise<void> {
    commit(PeriodicityMutations.SET_IS_CREATING_PERIODICITY, true)
    await axios
      .post(
        `${process.env.VUE_APP_API_ORISIS}Billing/Periodicity/CreatePeriodicity`,
        {...periodicity, workspaceId: rootGetters.workspaceSelected.id},
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {

        rootGetters.periodicitiesList.push(res.data.data)
        commit(
          PeriodicityMutations.SET_PERIODICITIES_LIST,
          rootGetters.periodicitiesList
        )
        commit(PeriodicityMutations.SET_REQUEST_STATE_PERIODICITY, res)
        commit(PeriodicityMutations.SET_IS_CREATING_PERIODICITY, false)
      })
      .catch(err => {
        commit(PeriodicityMutations.SET_REQUEST_STATE_PERIODICITY, err)
        commit(PeriodicityMutations.SET_IS_CREATING_PERIODICITY, false)
      })
  },

  /**
   * Update an periodicity
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {PeriodicityRequestDto} periodicity - PeriodicityRequestDto
   */
  async updatePeriodicity ({ commit, rootGetters }, periodicity: PeriodicityRequestDto) {
    commit(PeriodicityMutations.SET_IS_UPDATING_PERIODICITY, true)
    await axios
      .put(
        `${process.env.VUE_APP_API_ORISIS}Billing/Periodicity/UpdatePeriodicity`,
        {...periodicity, workspaceId: rootGetters.workspaceSelected.id},
        
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.periodicitiesList.splice(
          rootGetters.periodicitiesList.findIndex(elem => elem.id == periodicity.id),
          1,
          periodicity
        )
        commit(
          PeriodicityMutations.SET_PERIODICITIES_LIST,
          rootGetters.periodicitiesList
        )
        commit(PeriodicityMutations.SET_REQUEST_STATE_PERIODICITY, res)
        commit(PeriodicityMutations.SET_IS_UPDATING_PERIODICITY, false)
      })
      .catch(err => {
        commit(PeriodicityMutations.SET_REQUEST_STATE_PERIODICITY, err)
        commit(PeriodicityMutations.SET_IS_UPDATING_PERIODICITY, false)
      })
  },

  /**
   * It deletes an periodicity from the database.
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {string} periodicityId - string
   */
  async deletePeriodicity ({ commit, rootGetters }, periodicityId: string) {
    commit(PeriodicityMutations.SET_IS_DELETING_PERIODICITY, true)
    await axios
      .delete(`${process.env.VUE_APP_API_ORISIS}Billing/Periodicity/Delete`, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          periodicityId: periodicityId
        }
      })
      .then(res => {
        rootGetters.periodicitiesList.splice(
          rootGetters.periodicitiesList.findIndex(elem => elem.id == periodicityId),
          1
        )
        commit(
          PeriodicityMutations.SET_PERIODICITIES_LIST,
          rootGetters.periodicitiesList
        )
        commit(PeriodicityMutations.SET_REQUEST_STATE_PERIODICITY, res)
        commit(PeriodicityMutations.SET_IS_DELETING_PERIODICITY, false)
      })
      .catch(err => {
        commit(PeriodicityMutations.SET_REQUEST_STATE_PERIODICITY, err)
        commit(PeriodicityMutations.SET_IS_DELETING_PERIODICITY, false)
      })
  },

  /**
   * Delete a periodicity from the database
   * @param  - - `commit`: the commit function of the Vuex store.
   * @param {string} periodicityId - The id of the periodicity to delete.
   */
  async archivePeriodicity (
    { commit, rootGetters },
    periodicityId: string
  ): Promise<void> {
    commit(PeriodicityMutations.SET_IS_ARCHIVING_PERIODICITY, true)
    axios
      .delete(
        process.env.VUE_APP_API_ORISIS + 'Billing/Periodicity/Archive',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          data:[periodicityId]
        }
      )
      .then(res => {
        rootGetters.periodicitiesListArchived.unshift(rootGetters.periodicitiesList.find(elem => elem.id == periodicityId ))
        rootGetters.periodicitiesList.splice(
          rootGetters.periodicitiesList.findIndex(
            elem => elem.id == periodicityId
          ),
          1
        )
        commit(
          PeriodicityMutations.SET_PERIODICITIES_LIST,
          rootGetters.periodicitiesList
        )
        commit(PeriodicityMutations.SET_REQUEST_STATE_PERIODICITY, res)
        commit(PeriodicityMutations.SET_IS_ARCHIVING_PERIODICITY, false)
      })
      .catch(err => {
        console.error(err)
        commit(PeriodicityMutations.SET_REQUEST_STATE_PERIODICITY, err)
        commit(PeriodicityMutations.SET_IS_ARCHIVING_PERIODICITY, false)
      })
   },

  // async restorePeriodicity (
  //   { commit, rootGetters },
  //   periodicityId: string
  // ): Promise<void> {
  //   commit(PeriodicityMutations.SET_IS_UPDATING_PERIODICITY, true)
  //   axios
  //     .put(
  //       process.env.VUE_APP_API_ORISIS + 'Billing/Periodicity/Restore',
  //       [periodicityId],
  //       {
  //         headers: {
  //           Authorization: `Bearer ${rootGetters.userToken}`
  //         }
  //       }
  //     )
  //     .then(res => {
  //       rootGetters.periodicitiesList.unshift(rootGetters.periodicitiesListArchived.find(elem => elem.id == periodicityId ))
  //       rootGetters.periodicitiesListArchived.splice(
  //         rootGetters.periodicitiesListArchived.findIndex(
  //           elem => elem.id == periodicityId
  //         ),
  //         1
  //       )
  //       commit(PeriodicityMutations.SET_REQUEST_STATE_PERIODICITY, res)
  //       commit(PeriodicityMutations.SET_IS_UPDATING_PERIODICITY, false)
  //     })
  //     .catch(err => {
  //       console.error(err)
  //       commit(PeriodicityMutations.SET_REQUEST_STATE_PERIODICITY, err)
  //       commit(PeriodicityMutations.SET_IS_UPDATING_PERIODICITY, false)
  //     })
  //  }
}
