import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';
// import auth from "./modules/auth";
import createPersistedState from 'vuex-persistedstate';
import register from "./modules/account/Register";
import login from "./modules/account/Login.js";


import { EventTypeModule } from "./modules/planning/eventType/index"
import { SmsModule } from "./modules/planning/sms/index"

import { WorkspaceModule } from "./modules/account/workspace/index"; // fait mais il reste une fonction à placer au bon endroit
import { UserModule } from "./modules/directory/user/index";

import { AffairModule } from "./modules/directory/affair/index";
import { CollaboratorModule } from "./modules/directory/collaborator/index";
import { CompanyModule } from "./modules/directory/company/index";
import { CompaniesTypesModule } from "./modules/directory/companiesTypes/index";
import { ContactModule } from "./modules/directory/contact/index";
import { PlanningViewModule } from './modules/planning/planningView/index';
import { PlanningStatisticsModule } from './modules/planning/planningStatistics/index';
import { PlanningLineModule } from './modules/planning/planningLine/index';
import { ContractModule } from "./modules/parameters/contract/index";
import { JobModule } from "./modules/parameters/job/index";
import { CommonModule } from "./modules/directory/common";
import { LeaseModule } from "./modules/billing/lease/index";
import { PeriodicityModule } from "./modules/billing/periodicity/index";
import { LeaseTypeModule } from "./modules/billing/lease-type/index";
import { PropertyTypeModule } from "./modules/billing/property-type/index";
import { CodeTvaModule } from "./modules/billing/code-tva/index";
import { PropertyModule } from "./modules/billing/property/index";
import { ReferencielTVAModule } from "./modules/invoices/referencielTVA/index";


Vue.use(Vuex);
const authState = createPersistedState({ paths: ['login'], key: "login" });
// const workspaceState = createPersistedState({ paths: ['workspaceSelected'], key: "workspaceSelected" });
const planningViewState = createPersistedState({ paths: ['planningViewSelected'], key: "planningViewSelected" });

const store: StoreOptions<{}> = {
    modules: {
        //app de base 
        app,
        appConfig,
        verticalMenu,
        // auth,
        //-----------

        // a refaire 
        login,
        register,
        //-----------
        
        // OK
        WorkspaceModule, 
        CommonModule,
        EventTypeModule,
        UserModule,
        AffairModule,
        CollaboratorModule,
        CompanyModule,
        CompaniesTypesModule,
        ContactModule,
        PlanningLineModule,
        ContractModule,
        PlanningViewModule,
        PlanningStatisticsModule,
        SmsModule,
        JobModule,
        LeaseModule,
        PeriodicityModule,
        LeaseTypeModule,
        PropertyTypeModule,
        CodeTvaModule,
        PropertyModule,
        ReferencielTVAModule
        
        //-----------

    },
    plugins: [authState, planningViewState],

}

export default new Vuex.Store<{}>(store);
