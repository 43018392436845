import { ContactRequestDto } from '@/../Api'
import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '../../RootState'
import { ContactMutations } from './mutations'
import { IContactState } from './states'

export const actions: ActionTree<IContactState, RootState> = {
  /**
   * It fetches the list of contacts from the API.
   * @param  - **commit**: The commit function is used to send mutations to the store.
   */
  async fetchContactsList ({ commit, rootGetters }): Promise<void> {
    return await new Promise<void>((resolve, reject) => {
      commit(ContactMutations.SET_IS_LOADING_CONTACTS_LIST, true)
      commit(ContactMutations.SET_IS_LOADING_CONTACTS_LIST_ARCHIVED, false)
      axios
        .get(
          process.env.VUE_APP_API_ORISIS + 'Directory/Contact/GetContactByWorkspaceId',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
            params: {
              workspaceId: rootGetters.workspaceSelected.id
            }
          }
        )
        .then(res => {
          commit(ContactMutations.SET_CONTACTS_LIST, res.data)
          commit(ContactMutations.SET_REQUEST_STATE_CONTACT, res)
          commit(ContactMutations.SET_IS_LOADING_CONTACTS_LIST, false)
          resolve(res.data)

        })
        .catch(err => {
          console.error(err)
          commit(ContactMutations.SET_REQUEST_STATE_CONTACT, err)
          commit(ContactMutations.SET_IS_LOADING_CONTACTS_LIST, false)
        })
    })
  },
  async fetchContactsListArchived ({ commit, rootGetters }): Promise<void> {
    return await new Promise<void>((resolve, reject) => {
      commit(ContactMutations.SET_IS_LOADING_CONTACTS_LIST_ARCHIVED, true) 
      axios
        .get(
          process.env.VUE_APP_API_ORISIS + 
          '/Directory/Contact/GetContactArchivedByWorkspaceId',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
            params: {
              workspaceId: rootGetters.workspaceSelected.id
            }
          }
        )
        .then(res => {
          commit(ContactMutations.SET_CONTACTS_LIST_ARCHIVED, res.data)
          commit(ContactMutations.SET_REQUEST_STATE_CONTACT, res)
          commit(ContactMutations.SET_IS_LOADING_CONTACTS_LIST_ARCHIVED, false)
          resolve(res.data)

        })
        .catch(err => {
          console.error(err)
          commit(ContactMutations.SET_REQUEST_STATE_CONTACT, err)
          commit(ContactMutations.SET_IS_LOADING_CONTACTS_LIST, false)
        })
    })
  },

  async fetchContact (
    { commit, rootGetters },
    contactId: string
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
        commit(ContactMutations.SET_IS_LOADING_CONTACT, true)
        axios
          .get(
            process.env.VUE_APP_API_ORISIS + 'Directory/Contact/GetContactById',
            {
              headers: {
                Authorization: `Bearer ${rootGetters.userToken}`
              },
              params: {
                contactId: contactId
              }
            }
          )
          .then(res => {
            commit(ContactMutations.SET_CONTACT, res.data)
            commit(ContactMutations.SET_REQUEST_STATE_CONTACT, res)
            commit(ContactMutations.SET_IS_LOADING_CONTACT, false)

            resolve({ ...res.data, workspaceId: rootGetters.workspaceSelected.id })
          })
          .catch(err => {
            console.error(err)
            commit(ContactMutations.SET_REQUEST_STATE_CONTACT, err)
            commit(ContactMutations.SET_IS_LOADING_CONTACT, false)
          })
    })
  },
 
  /**
   * It fetches the contact data from the API.
   * @param  - contactId: The contactId of the contact you want to fetch.
   * @param {string} contactId - string
   */ 
  async fetchContactArchived (
    { commit, rootGetters },
    contactId: string
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
      commit(ContactMutations.SET_IS_LOADING_CONTACT, true)
      axios
        .get(
          process.env.VUE_APP_API_ORISIS + 'Directory/Contact/GetContactArchivedById',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
            params: {
              contactId: contactId
            }
          }
        )
        .then(res => {
          commit(ContactMutations.SET_CONTACT, res.data)
          commit(ContactMutations.SET_REQUEST_STATE_CONTACT, res)
          commit(ContactMutations.SET_IS_LOADING_CONTACT, false)

          resolve({ ...res.data, workspaceId: rootGetters.workspaceSelected.id })
        })
        .catch(err => {
          console.error(err)
          commit(ContactMutations.SET_REQUEST_STATE_CONTACT, err)
          commit(ContactMutations.SET_IS_LOADING_CONTACT, false)
        })
    })
  },


  /**
   * It creates a new contact.
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {ContactRequestDto} contact - ContactRequestDto
   */
   async createContact ( 
    { commit, rootGetters },
    contact: ContactRequestDto
  ): Promise<void> {
    contact.workspaceId = rootGetters.workspaceSelected.id
    commit(ContactMutations.SET_IS_CREATING_CONTACT, true)
    axios
      .post(
        process.env.VUE_APP_API_ORISIS + 'Directory/Contact/CreateContact',
        contact,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.contactsList.unshift(res.data.data)

        commit(ContactMutations.SET_CONTACTS_LIST, rootGetters.contactsList)
        commit(ContactMutations.SET_REQUEST_STATE_CONTACT, res)
        commit(ContactMutations.SET_IS_CREATING_CONTACT, false)
      })
      .catch(err => {
        commit(ContactMutations.SET_REQUEST_STATE_CONTACT, err)
        commit(ContactMutations.SET_IS_CREATING_CONTACT, false)
      })
  },
  /**
   * Update an contact
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {ContactRequestDto} contact - ContactRequestDto
   */
  async updateContact ({ commit, rootGetters }, contact: ContactRequestDto) {
    commit(ContactMutations.SET_IS_UPDATING_CONTACT, true)
    await axios
      .put(
        `${process.env.VUE_APP_API_ORISIS}Directory/Contact/UpdateContact`,
        {...contact,workspaceId: rootGetters.workspaceSelected.id},
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.contactsList.splice(
          rootGetters.contactsList.findIndex(elem => elem.id == contact.id),
          1,
          contact
        )
        commit(ContactMutations.SET_CONTACTS_LIST, rootGetters.contactsList)
        commit(ContactMutations.SET_REQUEST_STATE_CONTACT, res)
        commit(ContactMutations.SET_IS_UPDATING_CONTACT, false)
      })
      .catch(err => {
        commit(ContactMutations.SET_REQUEST_STATE_CONTACT, err)
        commit(ContactMutations.SET_IS_UPDATING_CONTACT, false)
      })
  },

  /**
   * It deletes an contact from the database.
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {string} contactId - string
   */
  async deleteContact ({ commit, rootGetters }, contactId: string) {
    commit(ContactMutations.SET_IS_DELETING_CONTACT, true)
    await axios
      .delete(`${process.env.VUE_APP_API_ORISIS}Directory/Contact/Delete`, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          contactId: contactId
        }
      })
      .then(res => {
        rootGetters.contactsListArchived.splice(
          rootGetters.contactsListArchived.findIndex(elem => elem.id == contactId),
          1
        )
        commit(ContactMutations.SET_CONTACTS_LIST, rootGetters.contactsList)
        commit(ContactMutations.SET_REQUEST_STATE_CONTACT, res)
        commit(ContactMutations.SET_IS_DELETING_CONTACT, false)
      })
      .catch(err => {
        commit(ContactMutations.SET_REQUEST_STATE_CONTACT, err)
        commit(ContactMutations.SET_IS_DELETING_CONTACT, false)
      })
  },

  async archiveContact (
    { commit, rootGetters },
    contactId: string
  ): Promise<void> {
    commit(ContactMutations.SET_IS_ARCHIVING_CONTACT, true)
    axios
      .delete(
        process.env.VUE_APP_API_ORISIS + 'Directory/Contact/Archive',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          data:[contactId]
        }
      )
      .then(res => {
        rootGetters.contactsListArchived.unshift(rootGetters.contactsList.find(elem => elem.id == contactId ))
        rootGetters.contactsList.splice(
          rootGetters.contactsList.findIndex(
            elem => elem.id == contactId
          ),
          1
        )
        commit(
          ContactMutations.SET_CONTACTS_LIST,
          rootGetters.contactsList
        )
        commit(ContactMutations.SET_REQUEST_STATE_CONTACT, res)
        commit(ContactMutations.SET_IS_ARCHIVING_CONTACT, false)
      })
      .catch(err => {
        console.error(err)
        commit(ContactMutations.SET_REQUEST_STATE_CONTACT, err)
        commit(ContactMutations.SET_IS_ARCHIVING_CONTACT, false)
      })
  },

  async restoreContact (
    { commit, rootGetters },
    contactId: string
  ): Promise<void> {
    commit(ContactMutations.SET_IS_UPDATING_CONTACT, true)
    axios
      .put(
        process.env.VUE_APP_API_ORISIS + 'Directory/Contact/Restore',
        [contactId],
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.contactsList.unshift(rootGetters.contactsListArchived.find(elem => elem.id == contactId ))
        rootGetters.contactsListArchived.splice(
          rootGetters.contactsListArchived.findIndex(
            elem => elem.id == contactId
          ),
          1
        )
        commit(ContactMutations.SET_REQUEST_STATE_CONTACT, res)
        commit(ContactMutations.SET_IS_UPDATING_CONTACT, false)
      })
      .catch(err => {
        console.error(err)
        commit(ContactMutations.SET_REQUEST_STATE_CONTACT, err)
        commit(ContactMutations.SET_IS_UPDATING_CONTACT, false)
      })
  },
}
