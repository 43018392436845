import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { IEventType } from "@/types/api-orisis/interfaces/Planning/IEventType";
import RootState from "@/store/modules/RootState"
import { IEventTypeState } from "./states";
import { Module } from "vuex"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"

export const state: IEventTypeState = {
    eventTypesList       : Array<IEventType>(),
    eventTypesListArchived       : Array<IEventType>(),
    eventType            : <IEventType>{},
    isLoadingEventTypesList   : false,
    isLoadingEventTypesListArchived   : false,
    isLoadingEventType   : false,
    isCreatingEventType  : false,
    isUpdatingEventType  : false,
    isDeletingEventType  : false,
    isArchivingEventType  : false,
    requestStateEventType: <IRequestState>{}
}

export const EventTypeModule: Module<IEventTypeState, RootState> = {
  state, 
  getters,
  mutations,
  actions
}
