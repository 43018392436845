import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { ReferencielTVARequestDto } from "Api";
import { GetterTree } from "vuex";
import RootState from "../../RootState";
import {  IReferencielTVAState } from "./states";

export const getters: GetterTree<IReferencielTVAState, RootState> = {
    referencielTVAsList: (state): ReferencielTVARequestDto[] => state.referencielTVAsList,
    referencielTVAsListArchived: (state): ReferencielTVARequestDto[] => state.referencielTVAsListArchived,
    referencielTVA     : (state): ReferencielTVARequestDto => state.referencielTVA,
    
    // COMMON
    isLoadingReferencielTVAsList   : (state): boolean => state.isLoadingReferencielTVAsList,
    isLoadingReferencielTVAsListArchived : (state): boolean => state.isLoadingReferencielTVAsListArchived,
    isLoadingReferencielTVA : (state): boolean => state.isLoadingReferencielTVA,
    isCreatingReferencielTVA  : (state): boolean => state.isCreatingReferencielTVA,
    isUpdatingReferencielTVA : (state): boolean => state.isUpdatingReferencielTVA,
    isDeletingReferencielTVA : (state): boolean => state.isDeletingReferencielTVA,
    requestStateReferencielTVA: (state): IRequestState => state.requestStateReferencielTVA,
    isArchivingReferencielTVA : (state): boolean => state.isArchivingReferencielTVA,
}