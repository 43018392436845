<template>
  <div id="app" class="h-100" :class="[skinClasses]" ref="parentApp">
    <component :is="layout">
      <router-tab :lang="customLanguage" page-transition="page-fade" tab-transition="tab-scale" :restore="true" restore-watch page-scroller=".b-grid-horizontal-scroller" />
    </component>
    <tooltip />
     <div class="loading-bg fixed primary" v-show="isGlobalLoading">
      <div class="loader">
        <div class="outer"></div>
        <div class="middle"></div>
        <div class="inner"></div>
      </div>
    </div>
    <!-- <vue-element-loading background-color="rgba(24, 54, 109, .9)" color="#f1f1f1" :active="true" :is-full-screen="true" :text="'Chargement en cours'"/> -->
    <!-- isGlobalLoading -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'
import navMenuItems from '@/navigation/horizontal'


import axios from 'axios'

import VueElementLoading from 'vue-element-loading'
const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () =>
  import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    VueElementLoading
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    ...mapGetters([
      "workspaceSelected",
      "isGlobalLoading"
    ]),
    layout () {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType () {
      return this.$store.state.appConfig.layout.type
    }
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('login'))?.login?.userInfo,
    }
  },
  beforeCreate () {
    // Set colors in theme
    const colors = [
      'primary',
      'secondary',
      'success',
      'info',
      'warning',
      'danger',
      'light',
      'dark'
    ]

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement
        ).value.slice(0, -2)
      )
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup () {
    const { skin, skinClasses } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: true,
      closeButton: true,
      icon: false,
      timeout: 4000,
      transition: 'Vue-Toastification__fade'
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses
    }
  },
  data () {
    return {
      customLanguage: {
        tab: {
          untitled: 'Page'
        },

        contextmenu: {
          refresh: "Actualiser l'onglet",
          refreshAll: 'Actualiser tout',
          close: 'Fermer',
          closeLefts: 'Fermer les onglets à gauche',
          closeRights: 'Fermer les onglets à droite',
          closeOthers: 'Fermer tout'
        },

        msg: {
          keepLastTab: 'Keep at least 1 tab'
        }
      }
    }
  }

}
</script>
<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "~ag-grid-community/dist/styles/ag-grid.css";
  @import "~ag-grid-community/dist/styles/ag-theme-material.css";

    

  .ag-theme-material {
     font-family: "Montserrat", Helvetica, Arial, serif !important;
     font-weight: 400 !important;
     
    .ag-header {
      // or write CSS selectors to make customisations beyond what the parameters support
      font-style: italic;
      background-color: #ffffff !important;
      border-bottom-width: 2px;
    }
    .ag-header-cell-center .ag-header-cell-label{
      justify-content: center !important;
    }
    .ag-row{
      background-color: #eef1f650;
    }
    .ag-row:nth-child(2n){
      background-color: #ffffff;
    }
    .ag-row:hover{
      background-color: #eef1f6;
    }
    .ag-cell,
    .ag-header-cell,
    .ag-header-group-cell  {
      padding-left: .75rem;
      padding-right: .75rem;
    }
    .ag-cell-focus, .ag-cell {    border: none !important;}
    .ag-paging-panel {
      border-top: 1px solid #e2e2e2;
      border-bottom: 1px solid #e2e2e2;
    }
    .ag-tool-panel-wrapper {
      -webkit-box-shadow: -6px 0px 13px -7px rgb(0 0 0 / 57%);
      box-shadow: -6px 0px 13px -7px rgb(0 0 0 / 57%);
    }
    .ag-checkbox-input-wrapper.ag-checked::after {
      color: #0c3571 !important;
    }
    &.ag-archived{
      .ag-row{
        background-color: #f6f6f6 !important;
        color:#82868b !important;
        font-style:italic !important
      }
      .ag-row:nth-child(2n){
        background-color: #f8f8f8 !important;
      }
      .ag-row:hover{
        background-color: #eef1f6 !important;
      }
    }
    .ag-root:not(.ag-context-menu-open) .ag-body-viewport:not(.ag-has-focus) .ag-cell-range-selected-1:not(.ag-cell-inline-editing) {
      background: transparent !important;
    }
  }

</style>
<style lang="scss" scoped>
  ::v-deep {
    // Page fade transition
    .page-fade {
      &-enter-active,
      &-leave-active {
        transition: opacity 0.5s;
      }

      &-enter,
      &-leave-to {
        opacity: 0;
      }
    }

    // Tab scale transition
    .tab-scale {
      &-enter-active,
      &-leave-active {
        transition: opacity 0.5s, transform 0.5s;
      }

      &-enter,
      &-leave-to {
        opacity: 0;
        transform: scale(1.5);
      }
    }
  }
</style>
