import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { LeaseRequestDto } from '@/../Api'
import { GetterTree } from 'vuex'
import RootState from '../../RootState'
import { ILeaseState } from './states'


export const getters: GetterTree<ILeaseState, RootState> = {
  leasesList: (state): LeaseRequestDto[] => state.leasesList,
  leasesListArchived: (state): LeaseRequestDto[] => state.leasesListArchived,
  lease     : (state): LeaseRequestDto => state.lease,

  // COMMON
  isLoadingLeasesList   : (state): boolean => state.isLoadingLeasesList,
  isLoadingLeasesListArchived   : (state): boolean => state.isLoadingLeasesListArchived,
  isLoadingLease   : (state): boolean => state.isLoadingLease,
  isCreatingLease  : (state): boolean => state.isCreatingLease,
  isUpdatingLease  : (state): boolean => state.isUpdatingLease,
  isDeletingLease  : (state): boolean => state.isDeletingLease,
  isArchivingLease  : (state): boolean => state.isArchivingLease,
  requestStateLease: (state): IRequestState => state.requestStateLease,
  isOpenSidebarLease : (state): boolean => state.isOpenSidebarLease,
}



