import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { GetterTree } from "vuex";
import RootState from "../../RootState";
import { IPlanningLinesState } from "./states";

export const getters: GetterTree<IPlanningLinesState, RootState> = {
    planningLinesList: (state): [] => state.planningLinesList,
    planningLinesCollaboratorList: (state): [] => state.planningLinesCollaboratorList,
    planningLinesAffairList: (state): [] => state.planningLinesAffairList,
    planningLinesFreeLineList: (state): [] => state.planningLinesFreeLineList,
    planningLine     : (state): {} => state.planningLine,

    // COMMON
    isLoadingPlanningLinesList   : (state): boolean => state.isLoadingPlanningLinesList,
    isLoadingPlanningLinesAffairList   : (state): boolean => state.isLoadingPlanningLinesList,
    isLoadingPlanningLinesCollaboratorList   : (state): boolean => state.isLoadingPlanningLinesList,
    isLoadingPlanningLinesFreeLineList   : (state): boolean => state.isLoadingPlanningLinesList,
    isLoadingPlanningLine   : (state): boolean => state.isLoadingPlanningLine,
    isCreatingPlanningLine  : (state): boolean => state.isCreatingPlanningLine,
    isUpdatingPlanningLine  : (state): boolean => state.isUpdatingPlanningLine,
    isDeletingPlanningLine  : (state): boolean => state.isDeletingPlanningLine,
    isArchivingPlanningLine  : (state): boolean => state.isArchivingPlanningLine,
    requestStatePlanningLine: (state): IRequestState => state.requestStatePlanningLine,
}