import { CompanyRequestDto } from '@/../Api'
import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '../../RootState'
import { CompanyMutations } from './mutations'
import { ICompanyState } from './states'

export const actions: ActionTree<ICompanyState, RootState> = {
  /**
   * It fetches the list of companies from the API.
   * @param  - - **commit**: The commit function is used to send mutations to the Vuex store.
   */ 
  async fetchCompaniesList ({ commit, rootGetters }): Promise<void> {
    return await new Promise<void>((resolve, reject) => {
      commit(CompanyMutations.SET_IS_LOADING_COMPANIES_LIST, true)
      commit(CompanyMutations.SET_IS_LOADING_COMPANIES_LIST_ARCHIVED, false)
      axios
        .get(
          process.env.VUE_APP_API_ORISIS + 'Directory/Company/GetCompanyByWorkspaceId',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
            params: {
              workspaceId: rootGetters.workspaceSelected.id
            }
          }
        )
        .then(res => {
          commit(CompanyMutations.SET_COMPANIES_LIST, res.data)
          commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, res)
          commit(CompanyMutations.SET_IS_LOADING_COMPANIES_LIST, false)
          resolve(res.data)

        })
        .catch(err => {
          console.error(err)
          commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, err)
          commit(CompanyMutations.SET_IS_LOADING_COMPANIES_LIST, false)
        })
    })
  },
  async fetchCompaniesListArchived ({ commit, rootGetters }): Promise<void> {
    return await new Promise<void>((resolve, reject) => {
      commit(CompanyMutations.SET_IS_LOADING_COMPANIES_LIST_ARCHIVED, true) 
      axios
        .get(
          process.env.VUE_APP_API_ORISIS + 
          '/Directory/Company/GetCompanyArchivedByWorkspaceId',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
            params: {
              workspaceId: rootGetters.workspaceSelected.id
            }
          }
        )
        .then(res => {
          commit(CompanyMutations.SET_COMPANIES_LIST_ARCHIVED, res.data)
          commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, res)
          commit(CompanyMutations.SET_IS_LOADING_COMPANIES_LIST_ARCHIVED, false)
          resolve(res.data)

        })
        .catch(err => {
          console.error(err)
          commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, err)
          commit(CompanyMutations.SET_IS_LOADING_COMPANIES_LIST, false)
        })
    })
  },

  /**
   * It fetches the company data from the API.
   * @param  - companyId: The companyId of the company you want to fetch.
   * @param {string} companyId - string
   */ 
  async fetchCompany (
    { commit, rootGetters },
    companyId: string
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
        commit(CompanyMutations.SET_IS_LOADING_COMPANY, true)
        axios
          .get(
            process.env.VUE_APP_API_ORISIS + 'Directory/Company/GetCompany',
            {
              headers: {
                Authorization: `Bearer ${rootGetters.userToken}`
              },
              params: {
                companyId: companyId
              }
            }
          )
          .then(res => {
            commit(CompanyMutations.SET_COMPANY, res.data)
            commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, res)
            commit(CompanyMutations.SET_IS_LOADING_COMPANY, false)

            resolve({ ...res.data, workspaceId: rootGetters.workspaceSelected.id })
          })
          .catch(err => {
            console.error(err)
            commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, err)
            commit(CompanyMutations.SET_IS_LOADING_COMPANY, false)
          })
    })
  },

  /**
   * It fetches the company data from the API.
   * @param  - companyId: The companyId of the company you want to fetch.
   * @param {string} companyId - string
   */ 
  async fetchCompanyArchived (
    { commit, rootGetters },
    companyId: string
  ): Promise<void> {
    return await new Promise((resolve, reject) => {
        commit(CompanyMutations.SET_IS_LOADING_COMPANY, true)
        axios
          .get(
            process.env.VUE_APP_API_ORISIS + 'Directory/Company/GetCompanyArchived',
            {
              headers: {
                Authorization: `Bearer ${rootGetters.userToken}`
              },
              params: {
                companyId: companyId
              }
            }
          )
          .then(res => {
            commit(CompanyMutations.SET_COMPANY, res.data)
            commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, res)
            commit(CompanyMutations.SET_IS_LOADING_COMPANY, false)

            resolve({ ...res.data, workspaceId: rootGetters.workspaceSelected.id })
          })
          .catch(err => {
            console.error(err)
            commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, err)
            commit(CompanyMutations.SET_IS_LOADING_COMPANY, false)
          })
    })
  },

  /**
   * Create a new company
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {CompanyRequestDto} company - CompanyRequestDto
   */
  async createCompany ( 
    { commit, rootGetters },
    company: CompanyRequestDto
  ): Promise<void> {
    company.workspaceId = rootGetters.workspaceSelected.id
    commit(CompanyMutations.SET_IS_CREATING_COMPANY, true)
    axios
      .post(
        process.env.VUE_APP_API_ORISIS + 'Directory/Company/CreateCompany',
        company,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.companiesList.unshift(res.data.data)

        commit(CompanyMutations.SET_COMPANIES_LIST, rootGetters.companiesList)
        commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, res)
        commit(CompanyMutations.SET_IS_CREATING_COMPANY, false)
      })
      .catch(err => {
        console.error(err)
        commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, err)
        commit(CompanyMutations.SET_IS_CREATING_COMPANY, false)
      })
  },

  async updateCompany (
    { commit, rootGetters },
    company: CompanyRequestDto
  ): Promise<void> {
    commit(CompanyMutations.SET_IS_UPDATING_COMPANY, true)
    axios
      .put(
        process.env.VUE_APP_API_ORISIS + 'Directory/Company/UpdateCompany',
        company,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.companiesList.splice(
          rootGetters.companiesList.findIndex(elem => elem.id == company.id),
          1,
          res.data.data
        )
        commit(CompanyMutations.SET_COMPANIES_LIST, rootGetters.companiesList)
        commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, res)
        commit(CompanyMutations.SET_IS_UPDATING_COMPANY, false)
      })
      .catch(err => {
        console.error(err)
        commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, err)
        commit(CompanyMutations.SET_IS_UPDATING_COMPANY, false)
      })
  },

  /**
   * Delete a company from the database
   * @param  - - `commit`: the commit function of the Vuex store.
   * @param {string} companyId - The id of the company to delete.
   */
  async deleteCompany (
    { commit, rootGetters },
    companyId: string
  ): Promise<void> {
    commit(CompanyMutations.SET_IS_DELETING_COMPANY, true)
    axios
      .delete(process.env.VUE_APP_API_ORISIS + 'Directory/Company/Delete', {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          companyId: companyId
        }
      })
      .then(res => {
        rootGetters.companiesListArchived.splice(
          rootGetters.companiesListArchived.findIndex(elem => elem.id == companyId),
          1
        )
        commit(CompanyMutations.SET_COMPANIES_LIST, rootGetters.companiesList)
        commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, res)
        commit(CompanyMutations.SET_IS_DELETING_COMPANY, false)
      })
      .catch(err => {
        console.error(err)
        commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, err)
        commit(CompanyMutations.SET_IS_DELETING_COMPANY, false)
      })
  },
  /**
   * Delete a company from the database
   * @param  - - `commit`: the commit function of the Vuex store.
   * @param {string} companyId - The id of the company to delete.
   */
  async archiveCompany (
    { commit, rootGetters },
    companyId: string
  ): Promise<void> {
    commit(CompanyMutations.SET_IS_ARCHIVING_COMPANY, true)
    axios
      .delete(
        process.env.VUE_APP_API_ORISIS + 'Directory/Company/Archive',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          data:[companyId]
        }
      )
      .then(res => {
        rootGetters.companiesListArchived.unshift(rootGetters.companiesList.find(elem => elem.id == companyId ))
        rootGetters.companiesList.splice(
          rootGetters.companiesList.findIndex(
            elem => elem.id == companyId
          ),
          1
        )
        commit(
          CompanyMutations.SET_COMPANIES_LIST,
          rootGetters.companiesList
        )
        commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, res)
        commit(CompanyMutations.SET_IS_ARCHIVING_COMPANY, false)
      })
      .catch(err => {
        console.error(err)
        commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, err)
        commit(CompanyMutations.SET_IS_ARCHIVING_COMPANY, false)
      })
  },

  async restoreCompany (
    { commit, rootGetters },
    companyId: string
  ): Promise<void> {
    commit(CompanyMutations.SET_IS_UPDATING_COMPANY, true)
    axios
      .put(
        process.env.VUE_APP_API_ORISIS + 'Directory/Company/Restore',
        [companyId],
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.companiesList.unshift(rootGetters.companiesListArchived.find(elem => elem.id == companyId ))
        rootGetters.companiesListArchived.splice(
          rootGetters.companiesListArchived.findIndex(
            elem => elem.id == companyId
          ),
          1
        )
        commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, res)
        commit(CompanyMutations.SET_IS_UPDATING_COMPANY, false)
        // context.fetchCompaniesList()
      })
      .catch(err => {
        console.error(err)
        commit(CompanyMutations.SET_REQUEST_STATE_COMPANY, err)
        commit(CompanyMutations.SET_IS_UPDATING_COMPANY, false)
      })
  },



  
}
