import { GetterTree } from "vuex";
import RootState from "../../RootState";
import { IPlanningStatisticsState } from "./states";

export const getters: GetterTree<IPlanningStatisticsState, RootState> = {
    statsEventTypesGlobal: (state): [] => state.statsEventTypesGlobal,
    statsEventTypesByDay: (state): [] => state.statsEventTypesByDay,
    datePeriode     : (state): [] => state.datePeriode,

    // COMMON
    isLoadingStatsEventTypesGlobal   : (state): boolean => state.isLoadingStatsEventTypesGlobal,
    isLoadingStatsEventTypesByDay   : (state): boolean => state.isLoadingStatsEventTypesByDay,
    isLoadingDatePeriode  : (state): boolean => state.isLoadingDatePeriode,
}