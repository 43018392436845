import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { MutationTree } from "vuex";
import { ICommonState } from "./states";

export enum CommonMutations {
    SET_PUBLIC_HOLIDAY_LIST = "SET_PUBLIC_HOLIDAY_LIST",

    // COMMON
    SET_IS_LOADING_PUBLIC_HOLIDAY_LIST = "SET_IS_LOADING_AFFAIR_LIST",
    
    SET_REQUEST_STATE_PUBLIC_HOLIDAY = "SET_REQUEST_STATE_PUBLIC_HOLIDAY"
}

export const mutations: MutationTree<ICommonState> = {
    [CommonMutations.SET_PUBLIC_HOLIDAY_LIST]: (state, payload: Object) => {
        state.publicHolidayList = payload;
    },



    //COMMON
    [CommonMutations.SET_IS_LOADING_PUBLIC_HOLIDAY_LIST]: (state, payload: boolean) => {
        state.isLoadingPublicHolidayList = payload;
    },
   
   
    [CommonMutations.SET_REQUEST_STATE_PUBLIC_HOLIDAY]: (state, payload: IRequestState) => {
        state.requestStatePublicHolidayList = payload;
    },

}