import { WorkspaceRequestDto } from '@/../Api'

import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '../../RootState'
import { WorkspaceMutations } from './mutations'
import { IWorkspaceState } from './states'

export const actions: ActionTree<IWorkspaceState, RootState> = {
  /**
   * It fetches the list of workspaces from the API.
   * @param  - **commit**: The commit function is used to send mutations to the store.
   */
  async fetchWorkspacesList (  { commit, rootGetters } ): Promise<void> {
    commit(WorkspaceMutations.SET_IS_LOADING_WORKSPACE, true)
    await axios
      .get(
        `${process.env.VUE_APP_API_ORISIS}Account/Workspace/GetWorkspaces`,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        commit(WorkspaceMutations.SET_WORKSPACES_LIST, res.data)
        commit(WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE, res)
        commit(WorkspaceMutations.SET_IS_LOADING_WORKSPACES_LIST, false)
      })
      .catch(err => {
        commit(WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE, err)
        commit(WorkspaceMutations.SET_IS_LOADING_WORKSPACES_LIST, false)
      })
  },

  async fetchWorkspacesListByUser ({ commit, rootGetters }, workspaceIds): Promise<void> {
    commit(WorkspaceMutations.SET_IS_LOADING_WORKSPACE, true)
    let url = `${process.env.VUE_APP_API_ORISIS}Account/Workspace/GetWorkspaceById`
    let workspaceList = []
    if(workspaceIds && workspaceIds.length>0){
      for (let i = 0; i < workspaceIds.length; i++) {
        await axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
            params: {
              id: workspaceIds[i]
            }
          })
          .then(res => {
            workspaceList.push(res.data)
            commit(WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE, res)
            commit(WorkspaceMutations.SET_IS_LOADING_WORKSPACES_LIST_BY_USER, false)
          })
          .catch(err => {
            console.error(err)
            commit(WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE, err)
            commit(WorkspaceMutations.SET_IS_LOADING_WORKSPACES_LIST_BY_USER, false)
          })
      }
    }else if(rootGetters.userInfo && rootGetters.userInfo.workspaceIds){
      for (let i = 0; i < rootGetters.userInfo.workspaceIds.length; i++) {
        await axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
            params: {
              id: rootGetters.userInfo.workspaceIds[i]
            }
          })
          .then(res => {
            workspaceList.push(res.data)
            commit(WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE, res)
            commit(WorkspaceMutations.SET_IS_LOADING_WORKSPACES_LIST_BY_USER, false)
          })
          .catch(err => {
            console.error(err)
            commit(WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE, err)
            commit(WorkspaceMutations.SET_IS_LOADING_WORKSPACES_LIST_BY_USER, false)
          })
      }
    }
    commit(WorkspaceMutations.SET_WORKSPACES_LIST_BY_USER, workspaceList)
    commit(WorkspaceMutations.SET_IS_LOADING_WORKSPACE, false)
  },

  /* Fetching the workspace data from the API. */ 
  async fetchWorkspace (
    { commit, rootGetters },
    workspaceId: string
  ): Promise<void> {
    return new Promise<void>((resolve, reject) =>{
      commit(WorkspaceMutations.SET_IS_LOADING_WORKSPACE, true)
    axios
      .get(
        process.env.VUE_APP_API_ORISIS +
          'Account/Workspace/GetWorkspaceById',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          params: {
            id: workspaceId
          }
        }
      ) 
      .then(res => {
        commit(WorkspaceMutations.SET_WORKSPACE, res)
        commit(WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE, res.data)
        commit(WorkspaceMutations.SET_IS_LOADING_WORKSPACE, false)
        resolve(res.data)
      })
      .catch(err => {
        console.error(err)
        commit(WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE, err)
        commit(WorkspaceMutations.SET_IS_LOADING_WORKSPACE, false)
      })
    })
  },

  /**
   * It creates a new workspace.
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {WorkspaceRequestDto} workspace - WorkspaceRequestDto
   */
  async createWorkspace (
    { commit, rootGetters },
    workspace: WorkspaceRequestDto
  ): Promise<void> {
    commit(WorkspaceMutations.SET_IS_CREATING_WORKSPACE, true)
    await axios
      .post(
        `${process.env.VUE_APP_API_ORISIS}Account/Workspace/CreateWorkspace`,
        workspace,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.workspacesList.unshift(res.data.data)
        commit(
          WorkspaceMutations.SET_WORKSPACES_LIST,
          rootGetters.workspacesList
        )
        commit(WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE, res)
        commit(WorkspaceMutations.SET_IS_CREATING_WORKSPACE, false)
      })
      .catch(err => {
        commit(WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE, err)
        commit(WorkspaceMutations.SET_IS_CREATING_WORKSPACE, false)
      })
  },

  /**
   * Update an workspace
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {WorkspaceRequestDto} workspace - WorkspaceRequestDto
   */ 
  async updateWorkspace ({ commit, rootGetters }, workspace: WorkspaceRequestDto) {
    commit(WorkspaceMutations.SET_IS_UPDATING_WORKSPACE, true)
    await axios
      .put(
        `${process.env.VUE_APP_API_ORISIS}Account/Workspace/UpdateWorkspace`,
        workspace,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => { 
        rootGetters.workspacesList.splice(
          rootGetters.workspacesList.findIndex(elem => elem.id == workspace.id),
          1,
          workspace
        )
        commit(
          WorkspaceMutations.SET_WORKSPACES_LIST,
          rootGetters.workspacesList
        )
        commit(WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE, res)
        commit(WorkspaceMutations.SET_IS_UPDATING_WORKSPACE, false)
      })
      .catch(err => {
        commit(WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE, err)
        commit(WorkspaceMutations.SET_IS_UPDATING_WORKSPACE, false)
      })
  },

  async setWorkspaceSelected({commit, dispatch, rootGetters}, {workspaceSelectedId, userRoles}) {
    await axios
      .get(
        process.env.VUE_APP_API_ORISIS +
          'Account/Workspace/GetWorkspaceById',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          params: {
            id: workspaceSelectedId
          }
        }
      ) 
      .then(res => {
        commit(WorkspaceMutations.SET_WORKSPACE_SELECTED,res.data)
        commit(WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE, res.data)
        commit(WorkspaceMutations.SET_IS_LOADING_WORKSPACE, false)
        dispatch('initializeStore')
        dispatch('fetchPlanningViewsList', workspaceSelectedId)
      })
      .catch(err => {
        console.error(err)
        commit(WorkspaceMutations.SET_REQUEST_STATE_WORKSPACE, err)
        commit(WorkspaceMutations.SET_IS_LOADING_WORKSPACE, false)
      })
  }
}
