import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '../../RootState'
import { PlanningStatisticsMutations } from './mutations'
import { IPlanningStatisticsState } from './states'

export const actions: ActionTree<IPlanningStatisticsState, RootState> = {
  /**
   * It fetches the list of planningViews from the API and stores it in the state.
   */
  async fetchStatsEventTypesGlobal ({ commit, rootGetters }, {startDate, endDate, workspaceSelectedId}): Promise<void> {
    return new Promise<void>((resolve, reject) => { 
      commit(PlanningStatisticsMutations.SET_IS_LOADING_STATS_EVENT_TYPES_GLOBAL, true)
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            'Plannings/PlanningStatistics/GetStatsEventTypesGlobal',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
            params: {
              startDate:startDate,
              endDate:endDate,
              workspaceId: (workspaceSelectedId? workspaceSelectedId : rootGetters.workspaceSelected.id)
            }
          }
        )
        .then(res => {
          commit(PlanningStatisticsMutations.SET_STATS_EVENT_TYPES_GLOBAL, res.data)
          commit(PlanningStatisticsMutations.SET_IS_LOADING_STATS_EVENT_TYPES_GLOBAL, false)
          resolve(res.data)
          return res.data
        })
        .catch(err => {
          console.error(err)
          commit(PlanningStatisticsMutations.SET_IS_LOADING_STATS_EVENT_TYPES_GLOBAL, false)
        })
    })
  },
  async fetchStatsEventTypesByDay ({ commit, rootGetters }, {startDate, endDate, workspaceSelectedId}): Promise<void> {
    return new Promise<void>((resolve, reject) => { 
      commit(PlanningStatisticsMutations.SET_IS_LOADING_STATS_EVENT_TYPES_BY_DAY, true)
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            'Plannings/PlanningStatistics/GetStatsEventTypesByDay',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
            params: {
              startDate:startDate,
              endDate:endDate,
              workspaceId: (workspaceSelectedId? workspaceSelectedId : rootGetters.workspaceSelected.id)
            }
          }
        )
        .then(res => {
          commit(PlanningStatisticsMutations.SET_STATS_EVENT_TYPES_BY_DAY, res.data)
          commit(PlanningStatisticsMutations.SET_IS_LOADING_STATS_EVENT_TYPES_BY_DAY, false)
          resolve(res.data)
          return res.data
        })
        .catch(err => {
          console.error(err)
          commit(PlanningStatisticsMutations.SET_IS_LOADING_STATS_EVENT_TYPES_BY_DAY, false)
        })
    })
  },
  async fetchDatePeriode ({ commit, rootGetters }, {startDate, endDate}): Promise<void> {
    return new Promise<void>((resolve, reject) => { 
      commit(PlanningStatisticsMutations.SET_IS_LOADING_DATE_PERIODE, true)
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            'Plannings/PlanningStatistics/GetDatePeriode',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userToken}`
            },
            params: {
              startDate:startDate,
              endDate:endDate
            }
          }
        )
        .then(res => {
          commit(PlanningStatisticsMutations.SET_DATE_PERIODE, res.data)
          commit(PlanningStatisticsMutations.SET_IS_LOADING_DATE_PERIODE, false)
          resolve(res.data)
          return res.data
        })
        .catch(err => {
          console.error(err)
          commit(PlanningStatisticsMutations.SET_IS_LOADING_DATE_PERIODE, false)
        })
    })
  },
}
