import { IPeriodicityState } from "./states"
import { Module } from "vuex"
import { PeriodicityRequestDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/RootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:IPeriodicityState = {
    periodicitiesList         : Array<PeriodicityRequestDto>(),
    periodicitiesListArchived : Array<PeriodicityRequestDto>(),
    periodicity              : <PeriodicityRequestDto>{},
    isLoadingPeriodicitiesList     : false,
    isLoadingPeriodicitiesListArchived     : false,
    isLoadingPeriodicity     : false,
    isCreatingPeriodicity    : false,
    isUpdatingPeriodicity    : false,
    isDeletingPeriodicity    : false,
    isArchivingPeriodicity    : false,
    requestStatePeriodicity: <IRequestState>{},
    isOpenSidebarPeriodicity : false,
}

export const PeriodicityModule: Module<IPeriodicityState, RootState> = {
    state, 
    getters,
    mutations,
    actions
} 