import { LeaseTypeRequestDto } from '@/../Api'
import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '../../RootState'
import { LeaseTypeMutations } from './mutations'
import { ILeaseTypeState } from './states'

export const actions: ActionTree<ILeaseTypeState, RootState> = {
  /**
   * It fetches the list of leaseTypes from the API.
   * @param  - **commit**: The commit function is used to send mutations to the store.
   */
  async fetchLeaseTypeList ({ commit, rootGetters }): Promise<void> {
   return new Promise<void>(async (resolve, reject) => {
    commit(LeaseTypeMutations.SET_IS_LOADING_LEASE_TYPE_LIST, true)
    let url = `${process.env.VUE_APP_API_ORISIS}Billing/LeaseType/GetLeaseTypes`
    await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          workspaceId: rootGetters.workspaceSelected.id
        }
      })
      .then(res => {
        commit(LeaseTypeMutations.SET_IS_LOADING_LEASE_TYPE_LIST, false)
        commit(LeaseTypeMutations.SET_LEASE_TYPE_LIST, res.data)
        commit(LeaseTypeMutations.SET_REQUEST_STATE_LEASE_TYPE, res)
        resolve(res.data)
      })
      .catch(err => {
        console.error(err)
        commit(LeaseTypeMutations.SET_IS_LOADING_LEASE_TYPE_LIST, false)
        commit(LeaseTypeMutations.SET_REQUEST_STATE_LEASE_TYPE, err)
      })
   })
  },
  // async fetchLeaseTypeListArchived ({ commit, rootGetters }): Promise<void> {
  //  return new Promise<void>(async (resolve, reject) => {
  //   commit(LeaseTypeMutations.SET_IS_LOADING_LEASE_TYPE_LIST_ARCHIVED, true)
  //   let url = `${process.env.VUE_APP_API_ORISIS}Billing/LeaseType/GetLeaseTypeArchivedByWorkspaceId`
  //   await axios
  //     .get(url, {
  //       headers: {
  //         Authorization: `Bearer ${rootGetters.userToken}`
  //       },
  //       params: {
  //         workspaceId: rootGetters.workspaceSelected.id
  //       }
  //     })
  //     .then(res => {
  //       commit(LeaseTypeMutations.SET_IS_LOADING_LEASE_TYPE_LIST_ARCHIVED, false)
  //       commit(LeaseTypeMutations.SET_LEASE_TYPE_LIST_ARCHIVED, res.data)
  //       commit(LeaseTypeMutations.SET_REQUEST_STATE_LEASE_TYPE, res)
  //       resolve(res.data)
  //     })
  //     .catch(err => {
  //       console.error(err)
  //       commit(LeaseTypeMutations.SET_IS_LOADING_LEASE_TYPE_LIST_ARCHIVED, false)
  //       commit(LeaseTypeMutations.SET_REQUEST_STATE_LEASE_TYPE, err)
  //     })
  //  })
  // },

  /* Fetching the leaseType data from the API. */
  async fetchLeaseType ({ commit, rootGetters }, leaseTypeId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(LeaseTypeMutations.SET_IS_LOADING_LEASE_TYPE, true)
    axios
      .get(`${process.env.VUE_APP_API_ORISIS}Billing/LeaseType/GetLeaseTypeById`, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          leaseTypeId: leaseTypeId
        }
      })
      .then(res => {
        commit(LeaseTypeMutations.SET_LEASE_TYPE, res.data)
        commit(LeaseTypeMutations.SET_REQUEST_STATE_LEASE_TYPE, res)
        commit(LeaseTypeMutations.SET_IS_LOADING_LEASE_TYPE, false)
        resolve(res.data)
      })
      .catch(err => {
        commit(LeaseTypeMutations.SET_REQUEST_STATE_LEASE_TYPE, err)
        commit(LeaseTypeMutations.SET_IS_LOADING_LEASE_TYPE, false)
      })
    })
  },

  // async fetchLeaseTypeArchived (
  //   { commit, rootGetters },
  //   leaseTypeId: string
  // ): Promise<void> {
  //   return new Promise<void>((resolve, reject) => {
  //     commit(LeaseTypeMutations.SET_IS_LOADING_LEASE_TYPE, true)
  //   axios
  //     .get(`${process.env.VUE_APP_API_ORISIS}Billing/LeaseType/GetLeaseTypeArchivedById`, {
  //       headers: {
  //         Authorization: `Bearer ${rootGetters.userToken}`
  //       },
  //       params: {
  //         leaseTypeId: leaseTypeId
  //       }
  //     })
  //     .then(res => {
  //       commit(LeaseTypeMutations.SET_LEASE_TYPE, res.data)
  //       commit(LeaseTypeMutations.SET_REQUEST_STATE_LEASE_TYPE, res)
  //       commit(LeaseTypeMutations.SET_IS_LOADING_LEASE_TYPE, false)
  //       resolve(res.data)
  //     })
  //     .catch(err => {
  //       commit(LeaseTypeMutations.SET_REQUEST_STATE_LEASE_TYPE, err)
  //       commit(LeaseTypeMutations.SET_IS_LOADING_LEASE_TYPE, false)
  //     })
  //   })
  // },

  /**
   * It creates a new leaseType.
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {LeaseTypeRequestDto} leaseType - LeaseTypeRequestDto
   */
  async createLeaseType ({ commit, rootGetters }, leaseType: LeaseTypeRequestDto): Promise<void> {
    commit(LeaseTypeMutations.SET_IS_CREATING_LEASE_TYPE, true)
    await axios
      .post(
        `${process.env.VUE_APP_API_ORISIS}Billing/LeaseType/CreateLeaseType`,
        {...leaseType, workspaceId: rootGetters.workspaceSelected.id},
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {

        rootGetters.leaseTypeList.push(res.data.data)
        commit(
          LeaseTypeMutations.SET_LEASE_TYPE_LIST,
          rootGetters.leaseTypeList
        )
        commit(LeaseTypeMutations.SET_REQUEST_STATE_LEASE_TYPE, res)
        commit(LeaseTypeMutations.SET_IS_CREATING_LEASE_TYPE, false)
      })
      .catch(err => {
        commit(LeaseTypeMutations.SET_REQUEST_STATE_LEASE_TYPE, err)
        commit(LeaseTypeMutations.SET_IS_CREATING_LEASE_TYPE, false)
      })
  },

  /**
   * Update an leaseType
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {LeaseTypeRequestDto} leaseType - LeaseTypeRequestDto
   */
  async updateLeaseType ({ commit, rootGetters }, leaseType: LeaseTypeRequestDto) {
    commit(LeaseTypeMutations.SET_IS_UPDATING_LEASE_TYPE, true)
    await axios
      .put(
        `${process.env.VUE_APP_API_ORISIS}Billing/LeaseType/UpdateLeaseType`,
        {...leaseType, workspaceId: rootGetters.workspaceSelected.id},
        
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          }
        }
      )
      .then(res => {
        rootGetters.leaseTypeList.splice(
          rootGetters.leaseTypeList.findIndex(elem => elem.id == leaseType.id),
          1,
          leaseType
        )
        commit(
          LeaseTypeMutations.SET_LEASE_TYPE_LIST,
          rootGetters.leaseTypeList
        )
        commit(LeaseTypeMutations.SET_REQUEST_STATE_LEASE_TYPE, res)
        commit(LeaseTypeMutations.SET_IS_UPDATING_LEASE_TYPE, false)
      })
      .catch(err => {
        commit(LeaseTypeMutations.SET_REQUEST_STATE_LEASE_TYPE, err)
        commit(LeaseTypeMutations.SET_IS_UPDATING_LEASE_TYPE, false)
      })
  },

  /**
   * It deletes an leaseType from the database.
   * @param  - `commit` - the commit function of the Vuex store.
   * @param {string} leaseTypeId - string
   */
  async deleteLeaseType ({ commit, rootGetters }, leaseTypeId: string) {
    commit(LeaseTypeMutations.SET_IS_DELETING_LEASE_TYPE, true)
    await axios
      .delete(`${process.env.VUE_APP_API_ORISIS}Billing/LeaseType/Delete`, {
        headers: {
          Authorization: `Bearer ${rootGetters.userToken}`
        },
        params: {
          leaseTypeId: leaseTypeId
        }
      })
      .then(res => {
        rootGetters.leaseTypeList.splice(
          rootGetters.leaseTypeList.findIndex(elem => elem.id == leaseTypeId),
          1
        )
        commit(
          LeaseTypeMutations.SET_LEASE_TYPE_LIST,
          rootGetters.leaseTypeList
        )
        commit(LeaseTypeMutations.SET_REQUEST_STATE_LEASE_TYPE, res)
        commit(LeaseTypeMutations.SET_IS_DELETING_LEASE_TYPE, false)
      })
      .catch(err => {
        commit(LeaseTypeMutations.SET_REQUEST_STATE_LEASE_TYPE, err)
        commit(LeaseTypeMutations.SET_IS_DELETING_LEASE_TYPE, false)
      })
  },

  /**
   * Delete a leaseType from the database
   * @param  - - `commit`: the commit function of the Vuex store.
   * @param {string} leaseTypeId - The id of the leaseType to delete.
   */
  async archiveLeaseType (
    { commit, rootGetters },
    leaseTypeId: string
  ): Promise<void> {
    commit(LeaseTypeMutations.SET_IS_ARCHIVING_LEASE_TYPE, true)
    axios
      .delete(
        process.env.VUE_APP_API_ORISIS + 'Billing/LeaseType/Archive',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userToken}`
          },
          data:[leaseTypeId]
        }
      )
      .then(res => {
        rootGetters.leaseTypeListArchived.unshift(rootGetters.leaseTypeList.find(elem => elem.id == leaseTypeId ))
        rootGetters.leaseTypeList.splice(
          rootGetters.leaseTypeList.findIndex(
            elem => elem.id == leaseTypeId
          ),
          1
        )
        commit(
          LeaseTypeMutations.SET_LEASE_TYPE_LIST,
          rootGetters.leaseTypeList
        )
        commit(LeaseTypeMutations.SET_REQUEST_STATE_LEASE_TYPE, res)
        commit(LeaseTypeMutations.SET_IS_ARCHIVING_LEASE_TYPE, false)
      })
      .catch(err => {
        console.error(err)
        commit(LeaseTypeMutations.SET_REQUEST_STATE_LEASE_TYPE, err)
        commit(LeaseTypeMutations.SET_IS_ARCHIVING_LEASE_TYPE, false)
      })
   },

  // async restoreLeaseType (
  //   { commit, rootGetters },
  //   leaseTypeId: string
  // ): Promise<void> {
  //   commit(LeaseTypeMutations.SET_IS_UPDATING_LEASE_TYPE, true)
  //   axios
  //     .put(
  //       process.env.VUE_APP_API_ORISIS + 'Billing/LeaseType/Restore',
  //       [leaseTypeId],
  //       {
  //         headers: {
  //           Authorization: `Bearer ${rootGetters.userToken}`
  //         }
  //       }
  //     )
  //     .then(res => {
  //       rootGetters.leaseTypeList.unshift(rootGetters.leaseTypeListArchived.find(elem => elem.id == leaseTypeId ))
  //       rootGetters.leaseTypeListArchived.splice(
  //         rootGetters.leaseTypeListArchived.findIndex(
  //           elem => elem.id == leaseTypeId
  //         ),
  //         1
  //       )
  //       commit(LeaseTypeMutations.SET_REQUEST_STATE_LEASE_TYPE, res)
  //       commit(LeaseTypeMutations.SET_IS_UPDATING_LEASE_TYPE, false)
  //     })
  //     .catch(err => {
  //       console.error(err)
  //       commit(LeaseTypeMutations.SET_REQUEST_STATE_LEASE_TYPE, err)
  //       commit(LeaseTypeMutations.SET_IS_UPDATING_LEASE_TYPE, false)
  //     })
  //  }
}
