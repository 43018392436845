import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { GetterTree } from 'vuex'
import RootState from '../../RootState'
import { ICommonState } from './states'


export const getters: GetterTree<ICommonState, RootState> = {
  publicHolidayList: (state): Object => state.publicHolidayList,

  // COMMON
  isLoadingPublicHolidayList   : (state): boolean => state.isLoadingPublicHolidayList,
  requestStatePublicHoliday: (state): IRequestState => state.requestStatePublicHolidayList,
}



