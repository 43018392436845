import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { IPlanningLine } from "@/types/api-orisis/interfaces/Planning/ILinePosition";
import RootState from "@/store/modules/RootState"
import { IPlanningLinesState } from "./states";
import { Module } from "vuex"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"

export const state : IPlanningLinesState = {
    planningLinesCollaboratorList: [],
    planningLinesAffairList     : [],
    planningLinesFreeLineList     : [],
    planningLinesList       : [],

    isLoadingPlanningLinesList   : false,
    isLoadingPlanningLinesCollaboratorList   : false,
    isLoadingPlanningLinesAffairList   : false,
    isLoadingPlanningLinesFreeLineList   : false,

    planningLine            : {},
    isLoadingPlanningLine   : false,
    isCreatingPlanningLine  : false,
    isUpdatingPlanningLine  : false,
    isDeletingPlanningLine  : false,
    isArchivingPlanningLine  : false,
    requestStatePlanningLine: <IRequestState>{},
}

export const PlanningLineModule: Module<IPlanningLinesState, RootState> = {
  state, 
  getters,
  mutations,
  actions
}
