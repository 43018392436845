import { ILeaseTypeState } from "./states"
import { Module } from "vuex"
import { LeaseTypeRequestDto } from '@/../Api'
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/RootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:ILeaseTypeState = {
    leaseTypeList         : Array<LeaseTypeRequestDto>(),
    leaseTypeListArchived : Array<LeaseTypeRequestDto>(),
    leaseType              : <LeaseTypeRequestDto>{},
    isLoadingLeaseTypeList     : false,
    isLoadingLeaseTypeListArchived     : false,
    isLoadingLeaseType     : false,
    isCreatingLeaseType    : false,
    isUpdatingLeaseType    : false,
    isDeletingLeaseType    : false,
    isArchivingLeaseType    : false,
    requestStateLeaseType: <IRequestState>{},
    isOpenSidebarLeaseType : false,
}

export const LeaseTypeModule: Module<ILeaseTypeState, RootState> = {
    state, 
    getters,
    mutations,
    actions
} 