import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { IPlanningView } from "@/types/api-orisis/interfaces/Planning/IPlanningViews";
import { IPlanningLine } from "@/types/api-orisis/interfaces/Planning/ILinePosition";
import RootState from "@/store/modules/RootState"
import { IPlanningViewState } from "./states";
import { Module } from "vuex"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"

export const state: IPlanningViewState = {
    planningViewsList       : Array<IPlanningView>(),
    planningViewsListArchived       : Array<IPlanningView>(),
    planningView            : <IPlanningView>{},
    isLoadingPlanningViewsList   : false,
    isLoadingPlanningView   : false,
    isCreatingPlanningView  : false,
    isUpdatingPlanningView  : false,
    isDeletingPlanningView  : false,
    isArchivingPlanningView  : false,
    requestStatePlanningView: <IRequestState>{},
}

export const PlanningViewModule: Module<IPlanningViewState, RootState> = {
  state, 
  getters,
  mutations,
  actions
}
